import React, { useState } from 'react';

import * as yup from 'yup';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// import { mask } from 'remask';

import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
} from '@material-ui/core';

// import OAuthSection from 'components/OAuthSection';
import { ReactComponent as EyeClosed } from 'assets/icons/eye-close.svg';
import { ReactComponent as EyeOpened } from 'assets/icons/open-eye.svg';

import { InitialBackgroundWrapper } from 'components/InitialBackgroundWrapper';

import { useCommonStyles } from 'styles/common';
import { useAuthentication } from 'hooks/useAuthentication';
import Colors from 'styles/colors';
import { useSignUpStyles } from './styles';
import { FormInputs, ToggleVisibilityAdornment } from './types';

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().required(),
  // cpf: yup.string().required().min(14),
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], 'As senhas devem coincidir'),
});

export function SignUp() {
  const history = useHistory();
  const classes = useSignUpStyles();
  const commonStyles = useCommonStyles();

  const { signUp, loadingSignUp } = useAuthentication();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [allowTerms, setAllowTerms] = useState(false);

  const handleGoToLogin = () => {
    history.push('/login');
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  function onSubmit({ name, email, password }: FormInputs) {
    signUp({ name, email, password });
  }

  const handleClickShowPassword = () => {
    setShowPassword(previousShowPassword => !previousShowPassword);
  };

  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm(previousShowPassword => !previousShowPassword);
  };

  const toggleAllowTerms = () => setAllowTerms(prevAllow => !prevAllow);

  // TODO: GENERIC COMPONENT TO IT
  const EndAdornmentToggleShowPassword = ({
    setVisibility,
    visible,
  }: ToggleVisibilityAdornment) => (
    <InputAdornment position="end">
      <IconButton onClick={() => setVisibility()} edge="end">
        {visible ? (
          <EyeOpened className={commonStyles.eyeIcon} />
        ) : (
          <EyeClosed className={commonStyles.eyeIcon} />
        )}
      </IconButton>
    </InputAdornment>
  );

  return (
    <>
      <Helmet>
        <title>Leeg | Cadastro</title>
      </Helmet>

      <InitialBackgroundWrapper>
        <Paper elevation={4} className={classes.paperContainer}>
          <Typography className={classes.title}>Criar conta</Typography>
          <Typography className={classes.backToLogin}>
            Já possui conta?
            <Typography
              color="primary"
              className={classes.backToLoginButton}
              onClick={handleGoToLogin}
            >
              Login
            </Typography>
          </Typography>

          <form
            className={commonStyles.authForm}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container direction="column">
              <InputLabel className={classes.label} htmlFor="name">
                Nome completo
              </InputLabel>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    id="name"
                    error={Boolean(errors.name)}
                    className={classes.input}
                    type="text"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                  />
                )}
              />

              <InputLabel className={classes.label} htmlFor="email">
                Email
              </InputLabel>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    id="email"
                    error={Boolean(errors.email)}
                    className={classes.input}
                    type="email"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                  />
                )}
              />

              <InputLabel className={classes.label} htmlFor="password">
                Senha
              </InputLabel>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    id="password"
                    error={Boolean(errors.password)}
                    type={showPassword ? 'text' : 'password'}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    endAdornment={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <EndAdornmentToggleShowPassword
                        setVisibility={handleClickShowPassword}
                        visible={showPassword}
                      />
                    }
                  />
                )}
              />

              <InputLabel className={classes.label} htmlFor="confirmPassword">
                Confirmar senha
              </InputLabel>
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    id="confirmPassword"
                    error={Boolean(errors.confirmPassword)}
                    type={showPasswordConfirm ? 'text' : 'password'}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    endAdornment={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <EndAdornmentToggleShowPassword
                        setVisibility={handleClickShowPasswordConfirm}
                        visible={showPasswordConfirm}
                      />
                    }
                  />
                )}
              />

              <Grid
                container
                direction="row"
                alignItems="baseline"
                className={classes.termsGrid}
              >
                <Grid item>
                  <Checkbox
                    checked={allowTerms}
                    className={classes.termsCheckbox}
                    color="primary"
                    onChange={toggleAllowTerms}
                  />
                </Grid>
                <Grid
                  item
                  className={classes.termsGridText}
                  onClick={toggleAllowTerms}
                >
                  <Typography variant="body1" className={classes.termsText}>
                    Ao criar uma conta, você concorda com os Termos de Serviço e
                    condições e Política de Privacidade
                  </Typography>
                </Grid>
              </Grid>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!allowTerms || loadingSignUp}
                className={classes.loginButton}
              >
                {loadingSignUp ? (
                  <CircularProgress size={15} style={{ color: Colors.WHITE }} />
                ) : (
                  'Cadastrar'
                )}
              </Button>
              {/* <OAuthSection /> */}
            </Grid>
          </form>
        </Paper>
      </InitialBackgroundWrapper>
    </>
  );
}
