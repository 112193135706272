import { styled, Box, Typography } from '@material-ui/core';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

export const Container = styled(Box)(() => ({
  display: 'flex',
  backgroundColor: Colors.WHITE,
  marginTop: 20,
  borderRadius: commonConstants.borders.radius.md,
  flexDirection: 'column',
}));

export const Header = styled(Box)(() => ({
  display: 'flex',
  height: 'fit-content',
  justifyContent: 'space-between',
  // borderBottom: '0.4px solid rgba(156, 156, 156, 0.4);',
  padding: '30px 30px 20px',
}));

export const HeaderText = styled(Typography)(() => ({
  fontFamily: commonConstants.font.family.title,
  fontWeight: commonConstants.font.weight.semiBold,
  fontSize: commonConstants.font.size.xxl,
}));

export const Content = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  '& :last-child': {
    borderBottom: 'none',
  },
}));

export const Row = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  padding: '20px 0',
  borderBottom: '1px solid rgba(156, 156, 156, 0.4);',
}));

export const RowText = styled(Typography)(() => ({
  fontFamily: commonConstants.font.family.body,
  fontWeight: commonConstants.font.weight.regular,
  fontSize: commonConstants.font.size.xxl,
}));
