import { createAsyncThunk } from '@reduxjs/toolkit';
import * as documentsServices from 'services/api-leeg/modules/documents';
import { editorActions } from '.';

export const updateDocument = createAsyncThunk(
  'editor/updateDocument',
  async (documentToUpdate: { id: string, body: string }, thunk) => {
    thunk.dispatch(editorActions.setIsUpdating(true));
    try {
      await documentsServices
      .updateDocument(documentToUpdate.id, documentToUpdate.body);
    } finally {
      thunk.dispatch(editorActions.setIsUpdating(false));
    }
  },
);
