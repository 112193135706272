/* eslint-disable import/no-duplicates */
import { format, formatDistance, addMonths } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

export function formatDetailDate(dateToParse: string | Date) {
  const date = new Date(dateToParse);

  const differenceHours = formatDistance(new Date(), new Date(date), {
    locale: ptBR,
  });
  return differenceHours;
}

export function getDueMonthly(date: string | Date) {
  const newDate = addMonths(new Date(date), 1);

   return format(newDate.valueOf() + newDate.getTimezoneOffset() * 60 * 1000, "dd 'de' MMMM'", {
    locale: ptBR,
  });
}

export function formatDate(date: string | Date) {
  const newDate = new Date(date);

   return format(newDate.valueOf() + newDate.getTimezoneOffset() * 60 * 1000, "dd'/'MM'/'yyyy", {
    locale: ptBR,
  });
}

export function formatDateTime(date: string | Date) {
  return format(new Date(date), " dd'/'MM'/'yyyy 'às' HH':'mm", {
    locale: ptBR,
  });
}
