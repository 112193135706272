import { Box, Divider, IconButton, Tooltip } from '@material-ui/core';
import { ModalConfirmAction } from 'components/ModalConfirmAction';
import React, { useEffect, useState } from 'react';
import {
  RiDeleteBinLine,
  RiEdit2Line,
  RiInformationLine,
  RiUploadLine,
} from 'react-icons/ri';
import { toast } from 'react-toastify';
import { deletePlan, getPlans, getSafe2PayPlans } from 'services/api-leeg/modules/plans';
import { Plan, Safe2PayPlan } from 'services/api-leeg/modules/plans/types';
import Colors from 'styles/colors';
import { ModalEditPlan } from './ModalEditPlan';
import { ModalPublishPlan } from './ModalPublishPlan';
import { CategoriesSkelleton } from './Skelleton';

import { Container, Header, Content, Row, HeaderText, RowText } from './styles';

export const Plans = () => {
  // States
  const [loading, setLoading] = useState(false);
  const [safe2PayPlans, setSafe2PayPlans] = useState<Array<Safe2PayPlan>>([]);
  const [plans, setPlans] = useState<Array<Plan>>([]);
  const [modalPublishPlanIsOpen, setModalPublishPlanIsOpen] = useState(false);
  const [modalEditPlanIsOpen, setModalEditPlanIsOpen] = useState(false);
  const [selectedSafe2PlayPlan, setSelectedSafe2PayPlan] = useState({} as Safe2PayPlan);
  const [selectedPlan, setSelectedPlan] = useState({} as Plan);

  const [disablePlanModalIsOpen, setDisablePlanModalIsOpen] = useState(false);

  // Callbacks
  const getAllPlans = async () => {
    setLoading(true);
    const safe2PayPlansResponse = await getSafe2PayPlans();
    const plansResponse = await getPlans();
    setSafe2PayPlans([
      {
        Id: 0,
        Name: 'Leeg Grátis',
        Description: 'A conta Leeg Grátis garante a você acesso a:',
        Amount: 0,
        SubscriptionLimit: 0,
        IsEnabled: true,
        ExpirationDate: '',
        CreatedDate: '',
        SubscriptionTotal: 0,
        PlanFrequence: {
          Code: '',
          Name: '',
        },
      },
      ...safe2PayPlansResponse,
    ]);
    setPlans([
      {
        _id: '',
        idPlanSafe2Pay: 0,
        name: 'Leeg Grátis',
        amount: 0,
        usersLimit: 1,
        benefits: [
          'Baixe até 5 modelos de documentos em PDF',
          'Acesso a templates de documentos',
          'Acesso a fotos e elementosvisuais gráficos (ícones, infográficos, fluxogramas e ilustrações)',
          '5GB de armazenamento em núvem',
        ],
        planFrequence: 'Mensal',
        documentQuantityLimit: 2,
      },
      ...plansResponse,
    ]);
    setLoading(false);
  };

  const handleOpenPublishPlanModal = (plan: Safe2PayPlan) => {
    setSelectedSafe2PayPlan(plan);
    setModalPublishPlanIsOpen(true);
  };

  const handleClosePublishPlanModal = () => setModalPublishPlanIsOpen(false);

  const handleOpenEditPlanModal = (plan: Plan) => {
    setSelectedPlan(plan);
    setModalEditPlanIsOpen(true);
  };

  const handleCloseEditPlanModal = () => {
    setModalEditPlanIsOpen(false);
  };

  const handleOpenDisablePlanModal = (plan: Plan) => {
    setSelectedPlan(plan);
    setDisablePlanModalIsOpen(true);
  };

  const handleDisablePlan = async () => {
    try {
      await deletePlan(selectedPlan._id);
      toast.success('Plano removido com sucesso.');
      getAllPlans();
    } catch (error) {
      toast.success('Erro ao remover plano.');
    }
  };

  // Effects
  useEffect(() => {
    getAllPlans();
  }, []);

  const MemoModalEditPlan = React.memo(ModalEditPlan);

  return (
    <Container>
      <Header>
        <HeaderText>Nome</HeaderText>
        <HeaderText>Ações</HeaderText>
      </Header>
      <Divider />
      {loading && safe2PayPlans.length === 0 && <CategoriesSkelleton />}
      <Content>
        {safe2PayPlans?.map(plan => {
          const alreadyPublished = plans.find(
            leegPlan => leegPlan.idPlanSafe2Pay === plan.Id,
          );
          return (
            <Row key={plan.Id}>
              <RowText>{plan.Name}</RowText>
              <Box display="grid" gridAutoFlow="column" gridColumnGap="20px">
                <Tooltip
                  title={
                    !alreadyPublished
                      ? 'Plano não publicado'
                      : 'Plano já publicado'
                  }
                >
                  <IconButton color="inherit" size="small">
                    <RiInformationLine
                      size={24}
                      color={
                        !alreadyPublished ? Colors.ORANGE : Colors.LIGHT_BLUE
                      }
                    />
                  </IconButton>
                </Tooltip>
                {!alreadyPublished ? (
                  <Tooltip
                    title="Publicar plano"
                  >

                    <IconButton
                      color="inherit"
                      size="small"
                      disabled={plan.Name === 'Leeg Grátis'}
                      onClick={() => handleOpenPublishPlanModal(plan)}
                    >
                      <RiUploadLine size={24} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <IconButton
                    color="inherit"
                    size="small"
                    disabled={plan.Name === 'Leeg Grátis'}
                    onClick={() => handleOpenEditPlanModal(alreadyPublished as Plan)}
                  >
                    <RiEdit2Line size={24} />
                  </IconButton>
                )}

                <Tooltip
                  title="Desativar plano"
                >
                  <IconButton
                    color="inherit"
                    size="small"
                    disabled={plan.Name === 'Leeg Grátis' || !alreadyPublished}
                    onClick={() => handleOpenDisablePlanModal(alreadyPublished as Plan)}
                  >
                    <RiDeleteBinLine size={24} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Row>
          );
        })}
      </Content>
      <ModalPublishPlan
        open={modalPublishPlanIsOpen}
        onClose={handleClosePublishPlanModal}
        selectedPlan={selectedSafe2PlayPlan}
        fetchPlans={getAllPlans}
      />
      <MemoModalEditPlan
        open={modalEditPlanIsOpen}
        onClose={handleCloseEditPlanModal}
        selectedPlan={selectedPlan}
        fetchPlans={getAllPlans}
      />
      <ModalConfirmAction
        title="Remover plano"
        description={`Você deseja remover o plano ${selectedPlan.name} ?`}
        open={disablePlanModalIsOpen}
        handleClose={() => setDisablePlanModalIsOpen(false)}
        handleOk={handleDisablePlan}
      />
    </Container>
  );
};
