import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { RiCloseCircleLine } from 'react-icons/ri';
import Colors from 'styles/colors';
import Skeleton from 'react-loading-skeleton';
import { FilterButton, useModalTemplatesStyles } from '../styles';

import 'react-loading-skeleton/dist/skeleton.css';

export const FiltersSkelleton = () => {
  const templateClasses = useModalTemplatesStyles();

  return (
    <Box className={templateClasses.filtersContainer}>
      <Box className={templateClasses.filtersContent}>
        <FilterButton
          active
          endIcon={
            <RiCloseCircleLine size={20} style={{ color: Colors.WHITE }} />
          }
        >
          <Typography className={templateClasses.buttonText}>Todos</Typography>
        </FilterButton>
        {[1, 2, 3].map(filter => (
          <Skeleton
            width="130px"
            style={{ padding: '15px 20px', marginRight: 20, borderRadius: 50 }}
            key={filter}
          />
        ))}
      </Box>
    </Box>
  );
};
