import { configureStore } from '@reduxjs/toolkit';

import { documentsReducer as documents } from './slices/documents';
import { documentCategoriesReducer as documentCategories } from './slices/documentCategories';
import { actualContentReducer as actualContent } from './slices/actualContent';
import { actualDocumentReducer as actualDocument } from './slices/actualDocument';
import { foldersReducer as folders } from './slices/folders';
import { tabsReducer as tabs } from './slices/tabs';
import { editorReducer as editor } from './slices/editor';
import { swapFolderIdReducer as swapFolderId } from './slices/swapFolderId';
import { profileReducer as profile } from './slices/profile';

import { adminUsersReducer as adminUsers } from './adminSlices/adminUsers';
import { actualAdminContentReducer as adminActualContent } from './adminSlices/adminActualContent';
import { adminDocumentsTemplatesReducer as adminDocumentsTemplates } from './adminSlices/adminDocumentsTemplates';
import { adminDocumentCategoriesReducer as adminDocumentCategories } from './adminSlices/adminDocumentCategories';
import { adminDeletedDocumentsTemplatesReducer as adminDeletedDocumentsTemplates } from './adminSlices/adminDeletedDocumentsTemplates';

export const store = configureStore({
  reducer: {
    documents,
    documentCategories,
    actualContent,
    actualDocument,
    profile,
    folders,
    editor,
    tabs,
    swapFolderId,
    adminActualContent,
    adminDocumentCategories,
    adminDocumentsTemplates,
    adminDeletedDocumentsTemplates,
    adminUsers,
  },
  enhancers: window?.reactotron ? [window?.reactotron.createEnhancer()] : [],
  devTools: true,
});

export type StoreState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
