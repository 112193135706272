import React, { useEffect, useState } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Typography,
  Box,
  Button,
  IconButton,
  OutlinedInput,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import { RiCloseLine } from 'react-icons/ri';
import { useModalStyles } from 'styles/shared/Modal';
import { useAppDispatch } from 'hooks/store/useAppDispatch';

import {
  createAdminUser as thunkCreateAdminUser,
  updateAdminUser as thunkUpdateAdminUser,
  } from 'store/adminSlices/adminUsers/sideEffects';

import { ReactComponent as EyeClosed } from 'assets/icons/eye-close.svg';
import { ReactComponent as EyeOpened } from 'assets/icons/open-eye.svg';
import { useCommonStyles } from 'styles/common';
import { ModalUserAdminProps } from './types';

export const ModalUserAdmin = ({
  type,
  open,
  handleClose,
  selectedUserAdmin,
}: ModalUserAdminProps) => {
  // Style hooks
  const classes = useModalStyles();
  const commonStyles = useCommonStyles();

  // Redux
  const dispatch = useAppDispatch();

  // States
  const [name, setName] = useState(selectedUserAdmin?.name || '');
  const [email, setEmail] = useState(selectedUserAdmin?.email || '');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Callbacks
  const handleCreateUserAdmin = async () => {
    try {
        setLoading(true);
        await dispatch(thunkCreateAdminUser({
          email,
          name,
          password,
        }));
      } finally {
        setLoading(false);
        handleClose();
      }
  };

  const handleUpdateUserAdmin = async () => {
    if (name === selectedUserAdmin?.name && email === selectedUserAdmin?.email) {
      handleClose();
      return;
    }

    try {
      setLoading(true);
      dispatch(thunkUpdateAdminUser({
          id: selectedUserAdmin?._id,
          name,
          email,
      }));
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const handleOk = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loading) return;

    if (type === 'create') {
      await handleCreateUserAdmin();
    } else {
      await handleUpdateUserAdmin();
    }
  };

  const endAdornmentToggleShowPassword = () => (
    <InputAdornment position="end">
      <IconButton onClick={() => setShowPassword(prevShowPassword => !prevShowPassword)} edge="end">
        {showPassword ? (
          <EyeOpened className={commonStyles.eyeIcon} />
        ) : (
          <EyeClosed className={commonStyles.eyeIcon} />
        )}
      </IconButton>
    </InputAdornment>
  );

  useEffect(() => {
   setName(selectedUserAdmin?.name || '');
   setEmail(selectedUserAdmin?.email || '');
  }, [selectedUserAdmin]);

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Paper className={type === 'create' ? classes.paperUser : classes.paperUserUpdate}>
          <IconButton
            className={classes.closeButton}
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <RiCloseLine size={16} />
          </IconButton>
          <Typography className={classes.title}>
            {type === 'rename'
              ? 'Atualizar '
              : 'Novo usuário admin'}
          </Typography>

          <form onSubmit={handleOk}>
            <Typography className={classes.label}>
              Nome
            </Typography>
            <OutlinedInput
              autoFocus
              defaultValue={name}
              className={classes.input}
              id="name"
              type="text"
              onChange={({ target }) => setName(target.value)}
            />
            <Typography className={classes.label}>
              Email
            </Typography>
            <OutlinedInput
              value={email}
              className={classes.input}
              id="email"
              type="email"
              onChange={({ target }) => setEmail(target.value)}
            />
            {type === 'create' && (
            <>
              <Typography className={classes.label}>
                Senha do primeiro acesso
              </Typography>
              <OutlinedInput
                className={classes.input}
                id="password"
                onChange={({ target }) => setPassword(target.value)}
                type={showPassword ? 'text' : 'password'}
                endAdornment={endAdornmentToggleShowPassword()}
              />
            </>
)}

            <Box className={classes.actions}>
              <Button className={classes.buttons} onClick={handleClose}>
                Cancelar
              </Button>
              <Button
                className={classes.buttons}
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {type === 'rename' ? 'Salvar' : 'Criar'}
                {loading && (
                  <CircularProgress
                    size={14}
                    className={classes.loadingProgress}
                  />
                )}
              </Button>
            </Box>
          </form>
        </Paper>
      </Fade>
    </Modal>
  );
};
