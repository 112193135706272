import React, { useState } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Typography,
  Box,
  Button,
  IconButton,
  OutlinedInput,
  CircularProgress,
} from '@material-ui/core';
import { RiCloseLine } from 'react-icons/ri';
import { useModalStyles } from 'styles/shared/Modal';
import { useAppDispatch } from 'hooks/store/useAppDispatch';

import { createAdminDocumentCategory as thunkCreateAdminDocumentCategory,
   renameAdminDocumentCategory as thunkRenameAdminDocumentCategory } from 'store/adminSlices/adminDocumentCategories/sideEffects';
import { ModalCategoryProps } from './types';

export const ModalCategory = ({
  type,
  open,
  handleClose,
  selectedCategory,
}: ModalCategoryProps) => {
  // Style hooks
  const classes = useModalStyles();

  // Redux
  const dispatch = useAppDispatch();

  // States
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  // Callbacks
  const handleCreateCategory = async () => {
    if (!name) {
      handleClose();
      return;
    }
    try {
        setLoading(true);
        await dispatch(thunkCreateAdminDocumentCategory(name));
      } finally {
        setLoading(false);
        handleClose();
      }
  };

  const handleRenameCategory = async () => {
    if (!name) {
      handleClose();
      return;
    }

    try {
      setLoading(true);
      dispatch(thunkRenameAdminDocumentCategory({
        id: selectedCategory?._id as string,
        name,
      }));
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const handleOk = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loading) return;

    if (type === 'create') {
      await handleCreateCategory();
    } else {
      await handleRenameCategory();
    }
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <IconButton
            className={classes.closeButton}
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <RiCloseLine size={16} />
          </IconButton>
          <Typography className={classes.title}>
            {type === 'rename'
              ? 'Renomear '
              : 'Nova categoria'}
          </Typography>

          <Typography className={classes.label}>
            Nome da categoria
          </Typography>
          <form onSubmit={handleOk}>
            <OutlinedInput
              autoFocus
              defaultValue={selectedCategory?.name ?? ''}
              className={classes.input}
              id="name"
              type="text"
              placeholder="Sem nome"
              onChange={({ target }) => setName(target.value)}
            />

            <Box className={classes.actions}>
              <Button className={classes.buttons} onClick={handleClose}>
                Cancelar
              </Button>
              <Button
                className={classes.buttons}
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {type === 'rename' ? 'Salvar' : 'Criar'}
                {loading && (
                  <CircularProgress
                    size={14}
                    className={classes.loadingProgress}
                  />
                )}
              </Button>
            </Box>
          </form>
        </Paper>
      </Fade>
    </Modal>
  );
};
