import React, { useEffect, useState } from 'react';
import {
  Box,
  ButtonBase,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { RiAddCircleFill } from 'react-icons/ri';
import Colors from 'styles/colors';
import { createDocument } from 'services/api-leeg/modules/documents';
import { toast } from 'react-toastify';
import { ModalTemplate } from 'components/ModalTemplates';
import { useAppSelector } from 'hooks/store/useAppSelector';
import { documentsActions } from 'store/slices/documents';
import { useAppDispatch } from 'hooks/store/useAppDispatch';
import GridItem from 'components/ModalTemplates/Grid/components/GridItem';
import { Document } from 'services/api-leeg/modules/documents/types';
import { ModalPreviewDocument } from 'components/ModalPreviewDocument';
import { getFirstThreeTemplates as thunkGetFirstThreeTemplates } from 'store/slices/documentCategories/sideEffects';
import { ModalFolders } from 'components/ModalFolders';
import { ModalFieldNew } from 'components/ModalFieldNew';
import { useTabs } from 'hooks/useTabs';
import { Folder } from 'services/api-leeg/modules/folders/types';
import { useAuthentication } from 'hooks/useAuthentication';
import { RenderActionProps } from './types';
import { useSidebarStyles } from './styles';

export function Sidebar() {
  // Style hooks
  const classes = useSidebarStyles();

  // Hooks
  const tabs = useTabs();

  // Context
  const { user } = useAuthentication();

  // Redux
  const dispatch = useAppDispatch();
  const { data: folders } = useAppSelector(state => state.folders);
  const swapFolderId = useAppSelector(state => state.swapFolderId);
  const { data: documents } = useAppSelector(state => state.documents);
  const { firstThreeTemplates } = useAppSelector(
    state => state.documentCategories,
  );

  // States
  const [modalFoldersOpen, setModalFoldersOpen] = useState(false);
  const [loadingCreateDocument, setLoadingCreateDocument] = useState(false);
  const [modalTemplateOpen, setModalTemplateOpen] = useState(false);
  const [templateToPreview, setTemplateToPreview] = useState<Document>();
  const [modalPreviewIsOpen, setModalPreviewIsOpen] = useState(false);
  const [modalFieldOpen, setModalFieldOpen] = useState(false);

  const handleCreateDocument = async (name: string) => {
    try {
      setLoadingCreateDocument(true);
      const document = await createDocument(swapFolderId, { name });

      dispatch(
        documentsActions.updateDocuments([...documents, document]),
        );
        setLoadingCreateDocument(false);
    } catch (error: any) {
      setLoadingCreateDocument(false);

      toast.error(error.response.data.error.message as string, { onClick: () => tabs.goTo('plans', 'Planos') });
    }
  };
  const openPreview = (template: Document) => {
    setTemplateToPreview(template);
    setModalPreviewIsOpen(true);
  };
  const closePreview = () => {
    setModalPreviewIsOpen(false);
  };

  const handleOpenModalTemplate = () => setModalTemplateOpen(true);
  const handleCloseModalTemplate = () => setModalTemplateOpen(false);

  const handleOpenModalFolders = () => setModalFoldersOpen(true);
  const handleCloseModalFolders = () => setModalFoldersOpen(false);

  const RenderAction = ({
    icon,
    text,
    color,
    isLoading,
    onClick,
  }: RenderActionProps) => (
    <ButtonBase
      disabled={isLoading}
      onClick={onClick}
      className={classes.buttonBase}
    >
      <Paper elevation={0} className={classes.actions}>
        {isLoading ? <CircularProgress size={28} style={{ color }} /> : icon}
        <Typography style={{ color }} className={classes.buttonText}>
          {text}
        </Typography>
      </Paper>
    </ButtonBase>
  );

  useEffect(() => {
    dispatch(thunkGetFirstThreeTemplates());
  }, [dispatch]);

  return (
    <Box className={classes.container}>
      <Typography className={classes.headerDocuments}>Criar</Typography>

      {/* <RenderAction
        onClick={() => console.log('UPLOAD DOCUMENT')}
        color={Colors.ORANGE}
        icon={<RiAddCircleFill size={28} color={Colors.ORANGE} />}
        text="Upload de documento"
      /> */}
      <RenderAction
        onClick={handleOpenModalFolders}
        color={Colors.LIGHT_BLUE}
        icon={<RiAddCircleFill size={28} color={Colors.LIGHT_BLUE} />}
        text="Novo documento"
        isLoading={loadingCreateDocument}
      />

      <Box
        display="flex"
        paddingTop="20px"
        paddingBottom="30px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography className={classes.headerDocuments}>Modelos</Typography>
        <ButtonBase onClick={handleOpenModalTemplate}>
          <Typography className={classes.headerDocumentsAction}>
            Ver todos
          </Typography>
        </ButtonBase>
      </Box>
      <Grid
        container
        className={classes.containerTemplate}
      >
        {firstThreeTemplates.map(template => (
          <GridItem
            oneCollumn
            openPreview={openPreview}
            key={template._id}
            template={template}
          />
        ))}
      </Grid>

      <ModalTemplate
        open={modalTemplateOpen}
        handleClose={handleCloseModalTemplate}
      />

      <ModalPreviewDocument
        document={templateToPreview as Document}
        open={modalPreviewIsOpen}
        handleClose={closePreview}
      />

      <ModalFolders
        open={modalFoldersOpen}
        handleClose={handleCloseModalFolders}
        folders={[{ ...user?.rootFolder as Folder, name: 'Meus documentos' }, ...folders]}
        handleSave={() => setModalFieldOpen(true)}
      />

      <ModalFieldNew
        label="Novo documento"
        okButtonText="Ok"
        onClose={() => setModalFieldOpen(false)}
        onOk={handleCreateDocument}
        title="Nome do documento"
        open={modalFieldOpen}
      />
    </Box>
  );
}
