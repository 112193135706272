/* eslint-disable react/jsx-props-no-spreading */
import { Box, CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { mask } from 'remask';
import { useAuthentication } from 'hooks/useAuthentication';
import axios from 'axios';
import { toast } from 'react-toastify';
import { UserDataFormProps, UserDataFormValues } from './types';
import { Container, Content, Label, Input, Actions, ActionsContainer } from './styles';

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().required(),
  cpf: yup.string(),
  phone: yup.string(),
  street: yup.string(),
  district: yup.string(),
  stateInitials: yup.string(),
  cityName: yup.string(),
  number: yup.string(),
  zipCode: yup.string(),
});

export const UserDataForm = ({ user }: UserDataFormProps) => {
  const { updateUserProfile } = useAuthentication();

  const [loading, setLoading] = useState(false);

  const userFormFields = {
    name: user?.name ?? '',
    email: user?.email ?? '',
    phone: user?.phone ?? '',
    cpf: user?.cpf ?? '',
    address: user?.address ?? null,
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values: UserDataFormValues) => {
    setLoading(true);
    try {
    const parsedZipCode = values.zipCode.replace('-', '');

      const { data } = await axios.get(`https://viacep.com.br/ws/${parsedZipCode}/json/`);
      await updateUserProfile({ ...values, ibge: data.ibge });
    } catch (error) {
      toast.error('CEP inválido.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Content>
          <Box>
            <Label>Nome completo *</Label>
            <Controller
              name="name"
              control={control}
              defaultValue={userFormFields.name ?? ''}
              render={({ field }) => (
                <Input
                  id="name"
                  placeholder="Sem nome"
                  error={Boolean(errors.name)}
                  {...field}
                />
                )}
            />
          </Box>
          <Box>
            <Label>Email *</Label>
            <Controller
              name="email"
              control={control}
              defaultValue={userFormFields.email ?? ''}
              render={({ field }) => (
                <Input
                  id="email"
                  placeholder="Sem email"
                  error={Boolean(errors.email)}
                  {...field}
                />
                )}
            />
          </Box>
          <Box>
            <Label>CPF *</Label>
            <Controller
              name="cpf"
              control={control}
              defaultValue={userFormFields.cpf ?? ''}
              render={({ field }) => (
                <Input
                  id="cpf"
                  placeholder="Sem CPF"
                  error={Boolean(errors.cpf)}
                  {...field}
                  onChange={
                    ({ target }) =>
                      field.onChange(mask(target.value, ['99999999999', ' 99999999999999']))
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                />
                )}
            />
          </Box>
          <Box>
            <Label>Telefone</Label>
            <Controller
              name="phone"
              control={control}
              defaultValue={userFormFields.phone ?? ''}
              render={({ field }) => (
                <Input
                  id="phone"
                  placeholder="Sem telefone"
                  error={Boolean(errors.phone)}
                  {...field}
                  onChange={
                    ({ target }) =>
                      field.onChange(mask(target.value, ['+99 (99) 9999-9999', '+99 (99) 99999-9999']))
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                />
                )}
            />
          </Box>
          <Box>
            <Label>Endereço</Label>
            <Controller
              name="street"
              control={control}
              defaultValue={userFormFields.address?.street ?? ''}
              render={({ field }) => (
                <Input
                  id="street"
                  placeholder="Sem endereço"
                  error={Boolean(errors.street)}
                  {...field}
                />
              )}
            />
          </Box>
          <Box>
            <Label>Número</Label>
            <Controller
              name="number"
              control={control}
              defaultValue={userFormFields.address?.number ?? ''}
              render={({ field }) => (
                <Input
                  id="number"
                  placeholder="Sem número"
                  error={Boolean(errors.number)}
                  {...field}
                />
              )}
            />
          </Box>
          <Box>
            <Label>Bairro</Label>
            <Controller
              name="district"
              control={control}
              defaultValue={userFormFields.address?.district ?? ''}
              render={({ field }) => (
                <Input
                  id="district"
                  placeholder="Sem bairro"
                  error={Boolean(errors.district)}
                  {...field}
                />
                )}
            />
          </Box>
          <Box>
            <Label>Cidade</Label>
            <Controller
              name="cityName"
              control={control}
              defaultValue={userFormFields.address?.cityName ?? ''}
              render={({ field }) => (
                <Input
                  id="cityName"
                  placeholder="Sem cidade"
                  error={Boolean(errors.cityName)}
                  {...field}
                />
                )}
            />
          </Box>
          <Box>
            <Label>Estado</Label>
            <Controller
              name="stateInitials"
              control={control}
              defaultValue={userFormFields.address?.stateInitials ?? ''}
              render={({ field }) => (
                <Input
                  id="stateInitials"
                  placeholder="UF"
                  error={Boolean(errors.stateInitials)}
                  {...field}
                  onChange={
                    ({ target }) =>
                      field.onChange(mask(target.value.toUpperCase(), 'AA'))
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                />
                )}

            />
          </Box>
          <Box>
            <Label>CEP</Label>
            <Controller
              name="zipCode"
              control={control}
              defaultValue={userFormFields.address?.zipCode ?? ''}
              render={({ field }) => (
                <Input
                  id="zipCode"
                  placeholder="Sem CEP"
                  error={Boolean(errors.zipCode)}
                  {...field}
                  onChange={
                    ({ target }) =>
                      field.onChange(mask(target.value, '99999-999'))
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                />
                )}
            />
          </Box>
        </Content>

        <ActionsContainer>
          <Actions
            disableElevation
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Salvar
            {loading && (
            <CircularProgress size={14} />
            )}
          </Actions>
        </ActionsContainer>
      </form>
    </Container>
);
};
