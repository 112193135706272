import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import * as documentCategoriesServices from 'services/api-leeg/modules/documentCategories';
import { StoreState } from 'store';
import { adminDocumentCategoriesActions } from '.';

// Async thunks
export const getAdminDocumentCategories = createAsyncThunk(
  'adminDocumentCategories/getAdminDocumentCategories',
  async (_, thunk) => {
    thunk.dispatch(adminDocumentCategoriesActions.updateLoading(true));
    try {
      const documentCategories = await documentCategoriesServices.getAllDocumentCategories();

      thunk.dispatch(
        adminDocumentCategoriesActions.updateAdminDocumentCategories(documentCategories),
      );
    } catch (error) {
      toast.error('Não foi possível carregar as categorias.');
    } finally {
      thunk.dispatch(adminDocumentCategoriesActions.updateLoading(false));
    }
  },
);

export const createAdminDocumentCategory = createAsyncThunk(
  'adminDocumentCategories/createAdminDocumentCategory',
  async (name: string, thunk) => {
    try {
      const documentCategory = await documentCategoriesServices.createDocumentCategory(name);

      const {
        adminDocumentCategories: { data: documentCategories },
      } = thunk.getState() as StoreState;

      thunk.dispatch(
        adminDocumentCategoriesActions.updateAdminDocumentCategories(
          [...documentCategories, documentCategory],
        ),
      );
    } catch (error) {
      toast.error('Não foi possível criar uma nova categoria.');
    }
  },
);

export const renameAdminDocumentCategory = createAsyncThunk(
  'adminDocumentCategories/renameAdminDocumentCategory',
  async (categoryToUpdate: { name: string, id: string }, thunk) => {
    try {
      const {
        adminDocumentCategories: { data: documentCategories },
      } = thunk.getState() as StoreState;

      await documentCategoriesServices.renameDocumentCategory(
        categoryToUpdate.name,
        categoryToUpdate.id,
      );

      const newCategories = documentCategories.map(category => {
        if (category._id === categoryToUpdate.id) {
          return { ...category, name: categoryToUpdate.name };
        }
        return category;
      });

      thunk.dispatch(adminDocumentCategoriesActions.updateAdminDocumentCategories(newCategories));

      toast.success('Categoria renomeada com sucesso.');
    } catch (error) {
      toast.error('Não foi possível renomear a categoria.');
    }
  },
);
