import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { GoChevronDown } from 'react-icons/go';
import Colors from 'styles/colors';
import { RiVipCrownLine } from 'react-icons/ri';
import { UserSectionProps } from './types';
import { UserAvatar, useUserSectionStyles } from './styles';
import { HeaderMenu } from '../Menu';

export function UserSection({
  anchorMenu,
  avatar,
  userNameFirstCharacter,
  isAdmin,
  handleCloseMenu,
  handleOpenMenu,
}: UserSectionProps) {
  const classes = useUserSectionStyles();

  return (
    <Grid item>
      <Button
        onClick={handleOpenMenu}
        startIcon={isAdmin &&
        <RiVipCrownLine className={classes.adminCrown} size={16} color={Colors.ORANGE} />}
        endIcon={<GoChevronDown size={16} color={Colors.WHITE} />}
      >
        <UserAvatar src={avatar}>
          {userNameFirstCharacter}
        </UserAvatar>
      </Button>
      <HeaderMenu anchor={anchorMenu} handleClose={handleCloseMenu} isAdmin={isAdmin} />
    </Grid>
  );
}
