import React, { useState } from 'react';

import { Box, IconButton, Typography } from '@material-ui/core';
import { RiLayoutGridLine, RiFileList2Line } from 'react-icons/ri';
import Colors from 'styles/colors';
import { useAppSelector } from 'hooks/store/useAppSelector';
import { ListContent } from './List';
import { GridContent } from './Grid';

import { useContentStyles } from './styles';
import { Visualizations } from './types';

export function Content() {
  // Styles hooks
  const classes = useContentStyles();

  /// Redux
  const actualContent = useAppSelector(state => state.actualContent);

  // States
  const [visualization, setVisualization] = useState<Visualizations>('grid');

  // Callbacks
  const handleToggleVisualizationGrid = () => setVisualization('grid');
  const handleToggleVisualizationList = () => setVisualization('list');

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography className={classes.title}>{actualContent.name}</Typography>
        <Box>
          <IconButton color="inherit" onClick={handleToggleVisualizationGrid}>
            <RiLayoutGridLine
              color={
                visualization === 'grid' ? Colors.GRAY_800 : Colors.GRAY_500
              }
              size={24}
            />
          </IconButton>
          <IconButton color="inherit" onClick={handleToggleVisualizationList}>
            <RiFileList2Line
              color={
                visualization === 'grid' ? Colors.GRAY_500 : Colors.GRAY_800
              }
              size={24}
            />
          </IconButton>
        </Box>
      </Box>
      {actualContent.id === 'trash' && (
        <Typography>
          Os documentos dentro desta pasta ficam disponíveis por até 30 dias,
          após, serão automaticamente deletados.
        </Typography>
      )}
      <Box className={classes.content}>
        {visualization === 'grid' ? <GridContent /> : <ListContent />}
      </Box>
    </Box>
  );
}
