import { Box, Button, CircularProgress } from '@material-ui/core';
import React from 'react';
import { useModalStyles } from 'styles/shared/Modal';
import { useModalTemplatesStyles } from './styles';
import { FooterProps } from './types';

// import {Container} from './styles'

export const Footer = ({
  handleClose,
  handleUseTemplate,
  selectedItem,
  loading,
}: FooterProps) => {
  const templateClasses = useModalTemplatesStyles();
  const classes = useModalStyles();

  return (
    <Box className={templateClasses.actionsContainer}>
      <Button
        onClick={handleClose}
        className={templateClasses.buttons}
        disableElevation
        variant="contained"
        color="inherit"
        type="submit"
      >
        Cancelar
      </Button>
      <Button
        onClick={handleUseTemplate}
        className={templateClasses.buttons}
        disableElevation
        variant="contained"
        color="primary"
        type="submit"
        disabled={!selectedItem || loading}
      >
        {selectedItem ? 'Usar modelo' : 'Selecione um modelo'}
        {loading && (
          <CircularProgress size={14} className={classes.loadingProgress} />
        )}
      </Button>
    </Box>
  );
};
