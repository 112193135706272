import clientApi from 'services/client';
import { DocumentCategory } from './types';

export const getAllDocumentCategories = async (): Promise<
  Array<DocumentCategory>
> => {
  const { data: documentCategories } = await clientApi.get<
    Array<DocumentCategory>
  >('/document-category?sort[createdAt]=-1');

  return documentCategories;
};

export const createDocumentCategory = async (name: string): Promise<
  DocumentCategory
> => {
  const { data: documentCategory } = await clientApi.post<
    DocumentCategory
  >('/document-category', { name });

  return documentCategory;
};

export const renameDocumentCategory = async (name: string, id: string): Promise<
  DocumentCategory
> => {
  const { data: documentCategory } = await clientApi.patch<
    DocumentCategory
  >(`/document-category/${id}`, { name });

  return documentCategory;
};
