import { makeStyles, createStyles, Button, Theme } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

type StyleProperties = {
  active?: boolean;
};

export const useNavBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3.5, 0),
      width: 390,
      display: 'inline-block',
      background: Colors.WHITE,
    },
    contentIcon: {
      marginRight: theme.spacing(2),
    },
  }),
);

export const ContentButton = styled(Button)<Theme, StyleProperties>(
  ({ active, theme }) => ({
    width: '100%',
    fontSize: commonConstants.font.size.lg,
    fontWeight: active
      ? commonConstants.font.weight.bold
      : commonConstants.font.weight.regular,
    padding: theme.spacing(1.3, 2.5),
    backgroundColor: active ? Colors.GRAY_100 : Colors.WHITE,
    justifyContent: 'flex-start',
  }),
);
