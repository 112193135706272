/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-props-no-multi-spaces */
import './polotno.css';

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { setTranslations } from 'polotno/config';
import { createStore } from 'polotno/model/store';
import { SidePanel } from 'polotno/side-panel';
import { Workspace } from 'polotno/canvas/workspace';

import { useAppSelector } from 'hooks/store/useAppSelector';

import { A4_IN_PIXELS } from 'constants/polotnoSheets';
import { useDebouncedCallback } from 'use-debounce/lib';

import { updateDocument as thunkUpdateDocument } from 'store/slices/editor/sideEffects';
import { getDocument as thunkGetDocument } from 'store/slices/actualDocument/sideEffects';
import { useAppDispatch } from 'hooks/store/useAppDispatch';
import { toast } from 'react-toastify';
import { Button } from '@blueprintjs/core';
import { Box, CircularProgress } from '@material-ui/core';
import translations from './translations';
import { sections } from './Sections';
import { EditorRouteParams } from './types';
import { loadFile } from './file';

// Polotno
const store = createStore({
  key: process.env.REACT_APP_POLOTNO_KEY as string,
  showCredit: false,
});

setTranslations(translations);

store.setSize(A4_IN_PIXELS.width, A4_IN_PIXELS.height);
store.setUnit({
  unit: 'px',
  dpi: 650,
});
store.setElementsPixelRatio(650);

const useHeight = () => {
  const [height, setHeight] = React.useState(window.innerHeight);
  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setHeight(window.innerHeight);
    });
  }, []);
  return height;
};

const exportPosition = {
  pdf: { position: 'absolute', top: 60, right: 20 },
  image: { position: 'absolute', top: 100, right: 20 },
};

const updatingDocumentButtonPosition = {
  position: 'absolute',
  top: 60,
  left: 20,
  width: 130,
  marginTop: 10,
};

const polotnoFixedHeight = { width: '100vw', height: 'calc(100vh - 43px)' };

export const PolotnoEditor = () => {
  // TODO: REFACTOR HANDLEDROP
  const handleDrop = (ev: {
    preventDefault: () => void;
    dataTransfer: { files: string | any[]; items: string | any[] };
  }) => {
    ev.preventDefault();

    if (ev.dataTransfer.files.length !== ev.dataTransfer.items.length) {
      return;
    }

    for (let i = 0; i < ev.dataTransfer.files.length; i += 1) {
      loadFile(ev.dataTransfer.files[i], store);
    }
  };

  // React router
  const { documentId } = useParams<EditorRouteParams>();

  // Hooks
  const history = useHistory();

  // State
  const [loadingExportToPDF, setLoadingExportToPDF] = useState(false);
  const [loadingExportToImage, setLoadingExportToImage] = useState(false);

  // Redux
  const dispatch = useAppDispatch();
  const { document, isLoading } = useAppSelector(state => state.actualDocument);
  const { isUpdating } = useAppSelector(state => state.editor);

  // Callbacks
  const debouncedUpdateDocument = useDebouncedCallback(async () => {
    dispatch(
      thunkUpdateDocument({
        id: document?._id as string,
        body: JSON.stringify(store.toJSON()),
      }),
    );
  }, 1000);

  store.on('change', () => {
    if (document?.body !== undefined) {
      debouncedUpdateDocument();
    }
  });

  const handleGetDocument = async () => {
    const {
      meta: { requestStatus },
      payload,
    }: any = await dispatch(thunkGetDocument(documentId));
    if (!payload.body) {
      store.addPage();
    }
    if (requestStatus !== 'fulfilled') {
      toast.error('Houve um problema ao tentar carregar o documento.');
      history.push('/dashboard');
    }
  };

  const handleExportToPDF = async () => {
    setLoadingExportToPDF(true);
    await store.saveAsPDF({ fileName: `${document?.name ?? 'Sem nome'}.pdf`, dpi: 72, pixelRatio: 4 });
    setLoadingExportToPDF(false);
  };

  const handleExportToImage = async () => {
    setLoadingExportToImage(true);
    await store.saveAsImage({
      fileName: `${document?.name ?? 'Sem nome'}.png`,
    });
    setLoadingExportToImage(false);
  };

  // Effects
  useEffect(() => {
    if (document?.body !== undefined) {
      const data = document?.body
        ? JSON.parse(document.body)
        : {
            width: A4_IN_PIXELS.width,
            height: A4_IN_PIXELS.height,
            fonts: [],
            pages: [],
          };
      store.loadJSON(data);
    }
  }, [document]);

  useEffect(() => {
    handleGetDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const height = useHeight();

  if (isLoading) {
    return (
      <Box
        display="flex"
        flex={1}
        alignItems="center"
        justifyContent="center"
        width="100vw"
        height={height}
      >
        <CircularProgress />
      </Box>
    );
  }

  const customDropAreaLocation = {
    width: '100vw',
    height: `${height}px`,
    flexDirection: 'column',
  };

  return (
    <div
      style={customDropAreaLocation as React.CSSProperties}
      onDrop={handleDrop as any}
    >
      <PolotnoContainer style={polotnoFixedHeight}>
        <SidePanelWrap>
          {/* @ts-ignore */}
          <SidePanel store={store} sections={sections} />
        </SidePanelWrap>
        <WorkspaceWrap>
          <Toolbar store={store} />
          <Workspace store={store} />
          <Button
            style={exportPosition.pdf as React.CSSProperties}
            icon="download"
            onClick={handleExportToPDF}
            loading={loadingExportToPDF}
          >
            Exportar PDF
          </Button>
          <Button
            style={exportPosition.image as React.CSSProperties}
            icon="mobile-phone"
            onClick={handleExportToImage}
            loading={loadingExportToImage}
          >
            Exportar PNG
          </Button>
          {isUpdating && (
            <Button
              style={updatingDocumentButtonPosition as React.CSSProperties}
              loading
            />
          )}
          <ZoomButtons store={store} />
        </WorkspaceWrap>
      </PolotnoContainer>
    </div>
  );
};
