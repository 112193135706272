import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

export const useGridStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      padding: theme.spacing(2, 0),
    },
    sectionHeader: {
      fontSize: commonConstants.font.size.lg,
      fontWeight: commonConstants.font.weight.semiBold,
      color: Colors.GRAY_500,
      paddingBottom: 10,
    },
  }),
);
