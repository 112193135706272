import React, { useState } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Typography,
  Box,
  Button,
  IconButton,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import { RiCloseLine } from 'react-icons/ri';
import { useModalStyles } from 'styles/shared/Modal';

import { Controller, useForm } from 'react-hook-form';
import { createPlan } from 'services/api-leeg/modules/plans';
import { toast } from 'react-toastify';
import { Label, Input } from './styles';
import { ModalPublishPlanProps } from './types';

export const ModalPublishPlan = ({
  open,
  onClose,
  selectedPlan,
  fetchPlans,
}: ModalPublishPlanProps) => {
  // Style hooks
  const classes = useModalStyles();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>();

  // States
  const [loading, setLoading] = useState(false);
  const [benefits, setBenefits] = useState(['']);

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      await createPlan({
        amount: selectedPlan.Amount,
        benefits: [
          values?.benefit0,
          values?.benefit1,
          values?.benefit2,
          values?.benefit3,
        ].filter(Boolean),
        planFrequence: 'Mensal',
        idPlanSafe2Pay: selectedPlan.Id,
        name: selectedPlan.Name,
        documentQuantityLimit: values.documentQuantityLimit,
        usersLimit: values.userQuantity,
      });
      toast.success('Plano publicado com sucesso.');
      fetchPlans();
      onClose();
    } catch (error) {
      toast.error('Erro ao publicar plano.');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateBenefit = () => {
    if (benefits.length < 4) {
      setBenefits(previousBenefits => [...previousBenefits, '']);
    }
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Paper className={classes.paperPlanEdit}>
          <IconButton
            className={classes.closeButton}
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <RiCloseLine size={16} />
          </IconButton>
          <Typography className={classes.title}>Adicionar plano</Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container xs={12} spacing={1} style={{ marginTop: 30 }}>
              <Grid item xs={12}>
                <Label>Nome do plano</Label>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={selectedPlan.Name}
                  render={({ field }) => (
                    <Input
                      id="name"
                      error={Boolean(errors.name)}
                      disabled
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Label>Valor do plano</Label>
                <Controller
                  name="value"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="value"
                      error={Boolean(errors.value)}
                      defaultValue={`R$ ${selectedPlan?.Amount.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      disabled
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Label>Quantidade de documentos permitidos</Label>
                <Controller
                  name="documentQuantityLimit"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="documentQuantityLimit"
                      error={Boolean(errors.documentQuantityLimit)}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Label>Quantidade de usuários</Label>
                <Controller
                  name="userQuantity"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="userQuantity"
                      error={Boolean(errors.userQuantity)}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Label>Beneficios</Label>
                {benefits.map((benefit, index) => (
                  <Controller
                    name={`benefit${index}`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        style={{ marginTop: 5 }}
                        id={`benefit${index}`}
                        defaultValue={benefit}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                      />
                    )}
                  />
                ))}
              </Grid>
              {benefits.length < 4 && (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    disableElevation
                    size="small"
                    color="primary"
                    onClick={handleCreateBenefit}
                    className={classes.buttons}
                  >
                    Adicionar beneficio
                  </Button>
                </Grid>
              )}
            </Grid>
            {/* <ModalDivider />
            <Box display="flex" width="100%" justifyContent="space-between" paddingTop={1}>
              <TotalLabel>Total da assinatura</TotalLabel>
              <TotalAmount>
                R$
                {' '}
              </TotalAmount>
            </Box> */}
            <Box className={classes.actions}>
              <Button className={classes.buttons} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                className={classes.buttons}
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                Salvar
                {loading && (
                  <CircularProgress
                    size={14}
                    className={classes.loadingProgress}
                  />
                )}
              </Button>
            </Box>
          </form>
        </Paper>
      </Fade>
    </Modal>
  );
};
