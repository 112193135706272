import { Grid, Theme } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import Colors from 'styles/colors';

import { commonConstants } from 'styles/common';

type StyledFolderBox = { selected: boolean };

export const FolderBox = styled(Grid)<Theme, StyledFolderBox>(
  ({ theme, selected }) => ({
    borderRadius: commonConstants.borders.radius.sm,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: Colors.GRAY_100,
    border: selected ? `2px solid ${Colors.LIGHT_BLUE}` : 'none',
    margin: theme.spacing(3, 1, 1),
    paddingTop: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  }),
);

export const FolderText = styled(Grid)<Theme>(({ theme }) => ({
  fontSize: commonConstants.font.size.xl,
  textAlign: 'center',
  padding: theme.spacing(1, 0),
}));
