import { createAsyncThunk } from '@reduxjs/toolkit';
import * as documentsService from 'services/api-leeg/modules/documents';
import { toast } from 'react-toastify';
import { StoreState } from 'store';
import { tabsActions } from 'store/slices/tabs';
import { adminDocumentsTemplatesActions } from '.';
import { adminDeletedDocumentsTemplatesActions } from '../adminDeletedDocumentsTemplates';

// Async thunks
export const getAllTemplates = createAsyncThunk(
  'adminDocumentsTemplates/getAllTemplates',
  async (_, thunk) => {
    thunk.dispatch(adminDocumentsTemplatesActions.updateLoading(true));
    try {
      const templates = await documentsService.getAllTemplatesDocuments();

      thunk.dispatch(adminDocumentsTemplatesActions.updateTemplates(templates));

      // TODO: CREATE A COUNT TO THIS SERVICE
      // thunk.dispatch(adminDocumentsTemplatesActions.updateCount(count));
    } catch (error) {
      toast.error('Não foi possível carregar os documentos');
    } finally {
      thunk.dispatch(adminDocumentsTemplatesActions.updateLoading(false));
    }
  },
);

export const createTemplate = createAsyncThunk(
  'adminDocumentsTemplates/createTemplate',
  async (templateToCreate: { parent: string, name: string; category: string }, thunk) => {
    if (!templateToCreate.name || !templateToCreate.category) return;
    try {
      const {
        adminDocumentsTemplates: { data: templates },
      } = thunk.getState() as StoreState;

      const newTemplate = await documentsService.createDocument(
        templateToCreate.parent,
        { name: templateToCreate.name, isTemplate: true, category: templateToCreate.category },
      );

      thunk.dispatch(adminDocumentsTemplatesActions.updateTemplates([...templates, newTemplate]));

      toast.success('Modelo criado com sucesso.');
    } catch (error) {
      toast.error('Não foi possível criar o modelo.');
    }
  },
);

export const renameTemplate = createAsyncThunk(
  'adminDocumentsTemplates/renameTemplate',
  async (templateToUpdate: { id: string; name: string }, thunk) => {
    try {
      const {
        adminDocumentsTemplates: { data: templates },
        tabs: { data: tabs },
      } = thunk.getState() as StoreState;

      await documentsService.renameDocument(
        templateToUpdate.id,
        templateToUpdate.name,
      );

      const newTemplates = templates.map(template => {
        if (template._id === templateToUpdate.id) {
          return { ...template, name: templateToUpdate.name };
        }
        return template;
      });

      thunk.dispatch(adminDocumentsTemplatesActions.updateTemplates(newTemplates));

      const newTabs = tabs.map(tab => {
        if (tab.id === templateToUpdate.id) {
          return { ...tab, name: templateToUpdate.name };
        }
        return tab;
      });
      thunk.dispatch(tabsActions.updateTabs(newTabs));
      toast.success('Modelo renomeado com sucesso.');
    } catch (error) {
      toast.error('Não foi possível renomear o modelo.');
    }
  },
);

export const deleteTemplate = createAsyncThunk(
  'adminDocumentsTemplates/deleteTemplate',
  async (id: string, thunk) => {
    try {
      const {
        adminDocumentsTemplates: { data: templates },
        tabs: { data: tabs },
      } = thunk.getState() as StoreState;

      await documentsService.deleteDocument(id);

      const newTabs = tabs.filter(previousTabs => previousTabs.id !== id);
      thunk.dispatch(tabsActions.updateTabs(newTabs));

      const newTemplates = templates.filter(
        previousTemplate => previousTemplate._id !== id,
      );

      thunk.dispatch(adminDocumentsTemplatesActions.updateTemplates(newTemplates));

      toast.success('Modelo deletado com sucesso.');
    } catch (error) {
      toast.error('Não foi possível deletar o modelo');
    }
  },
);

export const recoverTemplate = createAsyncThunk(
  'adminDocumentsTemplates/recoverTemplate',
  async (id: string, thunk) => {
    try {
      const {
        adminDeletedDocumentsTemplates: { data: deletedTemplates },
      } = thunk.getState() as StoreState;

      await documentsService.recoverDocument(id);
      const newDeletedTemplates = deletedTemplates.filter(
        deletedTemplate => deletedTemplate._id !== id,
      );

      thunk.dispatch(
        adminDeletedDocumentsTemplatesActions.updateDeletedTemplates(newDeletedTemplates),
      );
      toast.success('Modelo recuperado com sucesso.');
    } catch (error) {
      toast.error('Não foi possível recuperar o modelo');
    }
  },
);
