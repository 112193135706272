import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

export const useContentStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '84vh',
      backgroundColor: Colors.GRAY_100,
      padding: theme.spacing(1, 3),
      overflowY: 'scroll',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      color: Colors.GRAY_800,
      fontSize: commonConstants.font.size.xxxl,
      fontWeight: commonConstants.font.weight.bold,
      fontFamily: commonConstants.font.family.title,
    },
    content: {
      marginTop: theme.spacing(3),
      maxHeight: '90%',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '0.4em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: `inset 0 0 6px ${Colors.WHITE}`,
        webkitBoxShadow: `inset 0 0 6px ${Colors.WHITE}`,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        borderRadius: 2,
        outline: `1px solid ${Colors.WHITE}`,
      },
    },
}));
