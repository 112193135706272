import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Document } from 'services/api-leeg/modules/documents/types';
import { ActualDocumentStates } from './types';

const initialState: ActualDocumentStates = {
  document: undefined,
  isLoading: false,
};

const actualDocumentSlice = createSlice({
  name: 'actualDocument',
  initialState,
  reducers: {
    clearState: () => initialState,
    changeActualDocument: (state, action: PayloadAction<Document>) => {
      state.document = action.payload;
      return state;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      return state;
    },
  },
});

export const actualDocumentReducer = actualDocumentSlice.reducer;

export const actualDocumentActions = actualDocumentSlice.actions;
