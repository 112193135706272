import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = '';

const swapFolderIdSlice = createSlice({
  name: 'swapFolderId',
  initialState,
  reducers: {
    clearState: () => initialState,
    changeSwapFolderId: (state, action: PayloadAction<string>) => {
      state = action.payload;
      return state;
    },
  },
});

export const swapFolderIdReducer = swapFolderIdSlice.reducer;

export const swapFolderIdActions = swapFolderIdSlice.actions;
