import { Grid, Theme, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { commonConstants } from 'styles/common';

export const Container = styled(Grid)<Theme>(() => ({
  maxWidth: 230,
}));

export const TabText = styled(Typography)<Theme>(() => ({
  maxWidth: 120,
  fontSize: commonConstants.font.size.lg,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));
