import { Box, ButtonBase, Theme } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

type StyledContainer = { open: boolean };
type StyledItemTextResultSection = { description?: boolean };

export const Container = styled(Box)<Theme, StyledContainer>(
  ({ open, theme }) => ({
    width: '100vw',
    position: 'absolute',
    bottom: open ? '-270px' : 0,
    height: open ? '250px' : 0,
    opacity: open ? '1' : 0,
    borderTop: open ? `1px solid ${Colors.GRAY_600}` : 'none',
    transition: 'opacity 0.5s',
    overflowY: 'scroll',
    paddingBottom: theme.spacing(2),
  }),
);

export const Content = styled(Box)<Theme, StyledContainer>(
  ({ theme, open }) => ({
    display: open ? 'block' : 'none',
    backgroundColor: Colors.LIGHT_GRAY,
    width: '100%',
    padding: theme.spacing(2, 0, 2),
  }),
);

export const ResultSection = styled(Box)<Theme>(({ theme }) => ({
  display: 'flex',
  width: '95%',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  marginLeft: theme.spacing(2),
  paddingTop: 10,
}));

export const HeaderResultSection = styled(Box)<Theme>(({ theme }) => ({
  color: Colors.GRAY_500,
  padding: theme.spacing(0.5, 2),
}));

export const HeaderTextResultSection = styled(Box)<Theme>(() => ({
  fontWeight: commonConstants.font.weight.bold,
  fontSize: commonConstants.font.size.xl,
}));

export const ItemResultSection = styled(ButtonBase)<Theme>(({ theme }) => ({
  padding: theme.spacing(1, 2),
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: commonConstants.borders.radius.md,
  transition: 'all 0.3s',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '#E0E5E9',
  },
}));

export const ItemTextResultSection = styled(Box)<
  Theme,
  StyledItemTextResultSection
>(({ description }) => ({
  color: description ? Colors.GRAY_600 : Colors.GRAY_50,
  fontSize: commonConstants.font.size.xl,
}));
