/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminActualContent } from './types';

const initialState: AdminActualContent = {
  id: 'recent-templates',
  name: 'Modelos recentes',
};

const adminActualContentSlice = createSlice({
  name: 'adminActualContent',
  initialState,
  reducers: {
    clearState: () => initialState,
    changeActualContent: (
      state,
      action: PayloadAction<AdminActualContent>,
    ) => {
      state = action.payload;
      return state;
    },
  },
});

export const actualAdminContentReducer = adminActualContentSlice.reducer;

export const actualAdminContentActions = adminActualContentSlice.actions;
