export default {
  toolbar: {
    opacity: 'Opacidade',
    effects: 'Efeitos',
    blur: 'Borrar',
    brightness: 'Brilho',
    sepia: 'Sepia',
    grayscale: 'Cinza',
    textStroke: 'Linha',
    shadow: 'Sombra',
    border: 'Borda',
    cornerRadius: 'Raio',
    position: 'Posição',
    layering: 'Camadas',
    toForward: 'Última',
    up: 'Cima',
    down: 'Baixo',
    toBottom: 'Primeira',
    alignLeft: 'Alinhar à esquerda',
    alignCenter: 'Alinhar ao centro',
    alignRight: 'Alinhar à direita',
    alignTop: 'Alinhar à cima',
    alignMiddle: 'Alinhar ao meio',
    alignBottom: 'Alinhar à baixo',
    flip: 'Virar',
    flipHorizontally: 'Virar na horizontal',
    flipVertically: 'Virar na vertical',
    fitToBackground: 'Encaixar no fundo',
    removeBackground: 'Remover fundo',
    cancelRemoveBackground: 'Cancelar',
    confirmRemoveBackground: 'Confirmar',
    crop: 'Cortar',
    cropDone: 'Feito',
    cropCancel: 'Cancelar',
    removeClip: '',
    removeMask: '',
    transparency: 'Transparencia',
    lockedDescription: 'Destravar',
    unlockedDescription: 'Travar',
    removeElements: 'Remover',
    duplicateElements: 'Duplicar',
    download: 'Exportar',
    saveAsImage: 'Salvar como imagem',
    saveAsPDF: 'Salvar como PDF',
    selectable: '',
    alwaysOnTop: 'Sempre no topo',
    showInExport: 'Mostrar',
  },
  workspace: {
    noPages: 'Documento sem páginas',
    addPage: 'Adicionar uma nova página',
    removePage: 'Remover página',
    duplicatePage: 'Duplicar página',
    moveUp: 'Mover para cima',
    moveDown: 'Mover para baixo',
  },
  scale: {
    reset: 'Restaurar',
  },
  sidePanel: {
    templates: 'Modelos',
    searchTemplatesWithSameSize: 'Procurar templates com mesmo tamanho',
    searchPlaceholder: 'Procurar',
    otherFormats: 'Outros formatos',
    noResults: 'Sem resultados',
    text: 'Texto',
    uploadFont: 'Enviar fonte de texto',
    myFonts: 'Minhas fontes',
    photos: 'Fotos',
    elements: 'Ícones',
    upload: 'Upload',
    uploadImage: 'Upload',
    uploadTip: 'Faça o upload das suas próprias imagens',
    background: 'Fundo',
    resize: 'Ajustar',
    useMagicResize: 'Usar ajuste ajuste mágico',
    magicResizeDescription:
      'O ajuste mágico vai ajustar o tamanho dos elementos automaticamente.',
  },
};
