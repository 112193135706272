import React from 'react';

import * as yup from 'yup';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import {
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
} from '@material-ui/core';

import { InitialBackgroundWrapper } from 'components/InitialBackgroundWrapper';

import { useCommonStyles } from 'styles/common';
import { useAuthentication } from 'hooks/useAuthentication';
import Colors from 'styles/colors';
import { useForgotPasswordStyles } from './styles';

const schema = yup.object().shape({
  email: yup.string().required(),
});

interface FormInputs {
  email: string;
}

export function ForgotPassword() {
  const history = useHistory();
  const classes = useForgotPasswordStyles();
  const commonStyles = useCommonStyles();

  const { forgotPassword, loadingForgotPassword } = useAuthentication();

  const handleGoToLogin = () => {
    history.push('/login');
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  function onSubmit({ email }: FormInputs) {
    forgotPassword(email);
  }

  return (
    <>
      <Helmet>
        <title>Leeg | Esqueci minha senha</title>
      </Helmet>

      <InitialBackgroundWrapper>
        <Paper elevation={4} className={classes.paperContainer}>
          <Typography className={classes.title}>Recuperar Senha</Typography>
          <Typography className={classes.backToLogin}>
            Já possui conta?
            <Typography
              color="primary"
              className={classes.backToLoginButton}
              onClick={handleGoToLogin}
            >
              Login
            </Typography>
          </Typography>

          <form
            className={commonStyles.authForm}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container direction="column">
              <InputLabel className={classes.label} htmlFor="email">
                Email
              </InputLabel>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    id="email"
                    error={Boolean(errors.email)}
                    className={classes.input}
                    type="email"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                  />
                )}
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loadingForgotPassword}
                className={classes.recoveryButton}
              >
                {loadingForgotPassword ? (
                  <CircularProgress size={15} style={{ color: Colors.WHITE }} />
                ) : (
                  'Recuperar senha'
                )}
              </Button>
            </Grid>
          </form>
        </Paper>
      </InitialBackgroundWrapper>
    </>
  );
}
