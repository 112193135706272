import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useAppSelector } from 'hooks/store/useAppSelector';
import { useAuthentication } from 'hooks/useAuthentication';
import { Header } from '../../../components/Header';
import { Sidebar } from './components/Sidebar';
import { Content } from './components/Content';
import { Container } from './styles';

export function Profile() {
  const { activeTab } = useAppSelector(state => state.profile);

  const { getUser } = useAuthentication();

  useEffect(() => {
    getUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Leeg | Perfil</title>
      </Helmet>
      <Header />
      <Container>
        <Sidebar />
        <Content tab={activeTab} />
      </Container>
    </>
  );
}
