import { useAppDispatch } from 'hooks/store/useAppDispatch';
import { useAppSelector } from 'hooks/store/useAppSelector';
import React, { useEffect } from 'react';
import { getAllDeletedTemplates as thunkGetAllDeletedTemplates } from 'store/adminSlices/adminDeletedDocumentsTemplates/sideEffects';
import { getAllTemplates as thunkGetAllTemplates } from 'store/adminSlices/adminDocumentsTemplates/sideEffects';
import { GridContent } from './Grid';
import { ListContent } from './List';
import { TemplatesProps } from './types';

// import {Container} from './styles'

export const Templates = ({ grid, trash = false }: TemplatesProps) => {
  // Redux
  const dispatch = useAppDispatch();
  const { data: templates, isLoading } = useAppSelector(state => state.adminDocumentsTemplates);

  const { data: deletedTemplates } = useAppSelector(
    state => state.adminDeletedDocumentsTemplates,
  );

  // Effects
  useEffect(() => {
    if (trash) {
      dispatch(thunkGetAllDeletedTemplates());
    } else {
      dispatch(thunkGetAllTemplates());
    }
  }, [dispatch, trash]);

  return grid ?
    <GridContent templates={trash ? deletedTemplates : templates} isLoading={isLoading} /> :
    <ListContent templates={trash ? deletedTemplates : templates} />;
};
