import React from 'react';

import { useMiniPreviewContentStyles } from './styles';
import { PreviewContentProps } from './types';

export const MiniPreviewContent = ({ html }: PreviewContentProps) => {
  const classes = useMiniPreviewContentStyles();

  return (
    <div
      className={classes.previewSheet}
      style={{
        backgroundImage: `url(data:image/png;base64,${html})`,
        backgroundSize: 'cover',
      }}
    />
  );
};
