import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

export const useEmptyStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '75vh',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    illustration: {
      alignSelf: 'center',
      marginBottom: theme.spacing(5),
    },
    title: {
      alignSelf: 'center',
      color: Colors.GRAY_500,
      fontWeight: commonConstants.font.weight.bold,
      fontSize: commonConstants.font.size.xxxl,
      marginBottom: theme.spacing(2),
    },
    description: {
      textAlign: 'center',
      marginBottom: theme.spacing(1.5),
      width: '65%',
      color: Colors.GRAY_500,
      fontSize: commonConstants.font.size.xxl,
    },
}));
