import React, { useState } from 'react';

import { Box, IconButton, Typography } from '@material-ui/core';
import { RiLayoutGridLine, RiFileList2Line } from 'react-icons/ri';
import Colors from 'styles/colors';
import { useAppSelector } from 'hooks/store/useAppSelector';
import { AdminContentStates } from 'store/adminSlices/adminActualContent/types';

import { useContentStyles } from './styles';
import { Visualizations } from './types';
import { Categories } from './Categories';
import { Templates } from './Templates';
import { AdminUsers } from './AdminUsers';
import { Plans } from './Plans';

const ContentSwitcher = (
  { actualContent, grid }: { actualContent: AdminContentStates, grid: boolean },
  ) => {
  switch (actualContent) {
    case 'recent-templates': return <Templates grid={grid} />;
    case 'deleted-templates': return <Templates grid={grid} trash />;
    case 'admin-users': return <AdminUsers />;
    case 'plans': return <Plans />;
    case 'categories': return <Categories />;

    default: return null;
  }
};

export function Content() {
  // Styles hooks
  const classes = useContentStyles();

  /// Redux
  const adminActualContent = useAppSelector(state => state.adminActualContent);

  // States
  const [visualization, setVisualization] = useState<Visualizations>('grid');

  // Callbacks
  const handleToggleVisualizationGrid = () => setVisualization('grid');
  const handleToggleVisualizationList = () => setVisualization('list');

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography className={classes.title}>{adminActualContent.name}</Typography>
        {(adminActualContent.id === 'deleted-templates' || adminActualContent.id === 'recent-templates') && (
        <Box>
          <IconButton color="inherit" onClick={handleToggleVisualizationGrid}>
            <RiLayoutGridLine
              color={
                visualization === 'grid' ? Colors.GRAY_800 : Colors.GRAY_500
              }
              size={24}
            />
          </IconButton>
          <IconButton color="inherit" onClick={handleToggleVisualizationList}>
            <RiFileList2Line
              color={
                visualization === 'grid' ? Colors.GRAY_500 : Colors.GRAY_800
              }
              size={24}
            />
          </IconButton>
        </Box>
)}
      </Box>
      <ContentSwitcher actualContent={adminActualContent.id} grid={visualization === 'grid'} />
    </Box>
  );
}
