import { Box, Grid } from '@material-ui/core';
import { useAuthentication } from 'hooks/useAuthentication';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { formatDate, getDueMonthly } from 'utils/parseDate';
import { useAppDispatch } from 'hooks/store/useAppDispatch';

import { tabsActions } from 'store/slices/tabs';
import { Tabs } from 'store/slices/tabs/types';
import { Container, SectionTitle, SectionSubtitle, ItemName, ItemDescription, ItemButton } from '../styles';

export const Plan = () => {
  const { user } = useAuthentication();

  const dispatch = useAppDispatch();

  const history = useHistory();

  const handleGoToPlans = () => {
    const storagedTabs = localStorage.getItem('leeg:tabs');

    if (storagedTabs) {
      const tabs: Array<Tabs> = JSON.parse(storagedTabs);
      const plansIsAlreadyOpen = tabs.find(tab => tab.id === 'plans');
      if (!plansIsAlreadyOpen) {
        dispatch(tabsActions.updateTabs([{ id: 'plans', name: 'Planos' }, ...JSON.parse(storagedTabs)]));
        dispatch(tabsActions.updateActiveTab('plans'));
      } else {
        dispatch(tabsActions.updateActiveTab('plans'));
      }
    } else {
        dispatch(tabsActions.updateTabs([{ id: 'plans', name: 'Planos' }]));
        dispatch(tabsActions.updateActiveTab('plans'));
    }
    history.push('/plans');
  };

  return (
    <Container>
      <SectionTitle>Minha conta</SectionTitle>
      <SectionSubtitle>Meu plano</SectionSubtitle>

      <Box marginTop={5}>
        <Grid container spacing={3}>
          {user?.signature?.Id ? (
            <>
              <Grid item xs={6}>
                <ItemName>Plano</ItemName>
                <ItemDescription>{user?.signature?.Plan.Name}</ItemDescription>
              </Grid>
              <Grid item xs={6}>
                <ItemName>Forma de pagamento</ItemName>
                <ItemDescription>{user?.signature?.PaymentMethod.Name}</ItemDescription>
              </Grid>
              <Grid item xs={6}>
                <ItemName>Data de vencimento</ItemName>
                <ItemDescription>{getDueMonthly(user?.signature?.CreatedDate)}</ItemDescription>
              </Grid>
              <Grid item xs={6}>
                <ItemName>Membro desde</ItemName>
                <ItemDescription>{formatDate(user?.signature?.CreatedDate)}</ItemDescription>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={6}>
                <ItemName>Plano</ItemName>
                <ItemDescription>Leeg Grátis</ItemDescription>
              </Grid>
              <Grid item xs={6}>
                <ItemName>Membro desde</ItemName>
                <ItemDescription>{formatDate(user?.createdAt as string)}</ItemDescription>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <ItemButton onClick={handleGoToPlans}>Alterar plano</ItemButton>
          </Grid>
        </Grid>
      </Box>
    </Container>
);
};
