import React, { useState } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Typography,
  IconButton,
  Button,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { RiCloseLine } from 'react-icons/ri';
import { useModalStyles } from 'styles/shared/Modal';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { tabsActions } from 'store/slices/tabs';
import { useAppDispatch } from 'hooks/store/useAppDispatch';
import { createDocument } from 'services/api-leeg/modules/documents';
import { documentsActions } from 'store/slices/documents';
import { useAppSelector } from 'hooks/store/useAppSelector';
import { getDocument } from 'store/slices/actualDocument/sideEffects';
import { useTabs } from 'hooks/useTabs';
import { Folder } from 'services/api-leeg/modules/folders/types';
import { useAuthentication } from 'hooks/useAuthentication';
import { PreviewContent } from './Preview';
import { ModalPreviewDocumentProps } from './types';
import { ModalFolders } from '../ModalFolders';

export function ModalPreviewDocument({
  open,
  handleClose,
  document,
}: ModalPreviewDocumentProps) {
  // Styles
  const classes = useModalStyles();

  // Hooks
  const tab = useTabs();

  // Contexts
  const { user } = useAuthentication();

  // States
  const [loading, setLoading] = useState(false);
  const [modalFoldersOpen, setModalFoldersOpen] = useState(false);

  // Redux
  const dispatch = useAppDispatch();
  const { data: folders } = useAppSelector(state => state.folders);
  const { data: tabs } = useAppSelector(state => state.tabs);
  const swapFolderId = useAppSelector(state => state.swapFolderId);
  const { data: documents } = useAppSelector(state => state.documents);

  // Callbacks
  const handleCloseModalFolders = () => setModalFoldersOpen(false);

  const handleUseTemplate = async () => {
    setLoading(true);
    try {
      const createdDocument = await createDocument(swapFolderId, document, true);
      dispatch(
        documentsActions.updateDocuments([...documents, createdDocument]),
      );

      handleClose();

      await dispatch(getDocument(createdDocument._id));

      dispatch(
        tabsActions.updateTabs([
          ...tabs,
          { id: createdDocument._id, name: createdDocument.name },
        ]),
      );

      dispatch(tabsActions.updateActiveTab(createdDocument._id));
      toast.success('Documento criado com sucesso.');
    } catch (error: any) {
      toast.error(error.response.data.error.message as string, { onClick: () => tab.goTo('plans', 'Planos') });
    } finally {
      setLoading(false);
    }
  };

  if (!open) return null;

  return (
    <>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <Paper className={clsx(classes.paper, classes.templates)}>
            <IconButton
              className={classes.closeButton}
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <RiCloseLine size={16} />
            </IconButton>
            <Box className={classes.titleContainer}>
              <Typography className={classes.title}>{document.name}</Typography>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                className={classes.templateButton}
                onClick={() => setModalFoldersOpen(true)}
                disabled={loading}
              >
                Usar modelo
                {loading && (
                  <CircularProgress
                    size={14}
                    className={classes.loadingProgress}
                  />
                )}
              </Button>
            </Box>
            <PreviewContent html={document?.body as string} />
          </Paper>
        </Fade>
      </Modal>
      <ModalFolders
        open={modalFoldersOpen}
        handleClose={handleCloseModalFolders}
        folders={[{ ...user?.rootFolder as Folder, name: 'Meus documentos' }, ...folders]}
        handleSave={handleUseTemplate}
      />
    </>
  );
}
