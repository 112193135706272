import React from 'react';

import { useAppDispatch } from 'hooks/store/useAppDispatch';
import { useAppSelector } from 'hooks/store/useAppSelector';
import { RiEdit2Line, RiShieldLine, RiFileListLine } from 'react-icons/ri';
import { profileActions } from 'store/slices/profile';
import { ProfileStates } from 'store/slices/profile/types';

import { Container, ContentButton } from './styles';

export const Sidebar = () => {
  // Redux
  const dispatch = useAppDispatch();
  const { activeTab } = useAppSelector(state => state.profile);

  // Callback
  const handleChangeActiveTab = (tab: ProfileStates['activeTab']) => {
    dispatch(profileActions.updateActiveTab(tab));
  };

  return (
    <Container boxShadow={2}>
      <ContentButton
        active={activeTab === 'edit'}
        startIcon={<RiEdit2Line size={24} />}
        onClick={() => handleChangeActiveTab('edit')}
      >
        Editar perfil
      </ContentButton>
      <ContentButton
        active={activeTab === 'plan'}
        startIcon={<RiFileListLine size={24} />}
        onClick={() => handleChangeActiveTab('plan')}
      >
        Meu plano
      </ContentButton>
      <ContentButton
        active={activeTab === 'security'}
        startIcon={<RiShieldLine size={24} />}
        onClick={() => handleChangeActiveTab('security')}
      >
        Senha e segurança
      </ContentButton>
    </Container>
  );
};
