import { CircularProgress } from '@material-ui/core';
import { useAuthentication } from 'hooks/useAuthentication';
import React, { useRef, useState } from 'react';
import { RiCameraLine } from 'react-icons/ri';
import Colors from 'styles/colors';
import { UserDataForm } from '../component/UserDataForm';

import { Container, SectionTitle, SectionSubtitle, UserImageContainer, UserAvatar, UserAvatarEditButton } from '../styles';

export const Edit = () => {
  // Refs
  const inputFile = useRef<HTMLInputElement>(null);

  // State
  const [loading, setLoading] = useState(false);

  // Context
  const { user, updateUserProfileAvatar } = useAuthentication();
  const userNameFirstCharacter = user?.name.charAt(0) ?? '';

  // Callbacks
  const onUploadButtonClick = () => {
    inputFile.current?.click();
  };

  const onChangeMedia = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const image: File | undefined = event.target.files?.[0];
    if (!image) return;
      setLoading(true);
      await updateUserProfileAvatar(image);
      setLoading(false);
  };

  return (
    <Container>
      <SectionTitle>Minha conta</SectionTitle>
      <SectionSubtitle>Editar perfil</SectionSubtitle>
      <UserImageContainer>
        <UserAvatar src={user?.urlAvatar}>
          {
            loading ?
              <CircularProgress size={18} />
            :
            userNameFirstCharacter
          }
        </UserAvatar>
        <UserAvatarEditButton onClick={onUploadButtonClick}>
          <RiCameraLine color={Colors.WHITE} size={17} />
        </UserAvatarEditButton>
      </UserImageContainer>
      <input
        id="file"
        type="file"
        accept="image/*"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={onChangeMedia}
      />
      <UserDataForm user={user} />
    </Container>
);
};
