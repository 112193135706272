import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { ReactComponent as DeskIllustration } from 'assets/illustrations/folder.svg';
import { ReactComponent as TrashIllustration } from 'assets/illustrations/emptyTrash.svg';
import { useAppSelector } from 'hooks/store/useAppSelector';
import { useEmptyStyles } from './styles';

export function EmptyDocument() {
  const classes = useEmptyStyles();
  /**
   * Redux
   */
  const adminActualContent = useAppSelector(state => state.adminActualContent);

  return (
    <Box className={classes.container}>
      {adminActualContent.id === 'deleted-templates' ? (
        <>
          <TrashIllustration className={classes.illustration} />
          <Typography className={classes.title}>
            Sua lista de modelos deletados está vazia.
          </Typography>
          <Typography className={classes.description}>
            Ao deletar um arquivo, ele virá para cá. Mas não se preocupe, ele
            ficará nesta seção por pelo menos 30 dias. Então caso tenha deletado
            errado, só vir nesta seção e restaurar o arquivo.
          </Typography>
        </>
      ) : (
        <>
          <DeskIllustration className={classes.illustration} />
          <Typography className={classes.title}>
            Você ainda não possui nenhum modelo.
          </Typography>
          <Typography className={classes.description}>
            Para criar, é só apertar no botão “Novo modelo”.
          </Typography>
        </>
      )}
    </Box>
  );
}
