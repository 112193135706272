/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActualDashboardContent } from './types';

const initialState: ActualDashboardContent = {
  id: 'recent-documents',
  name: 'Documentos recentes',
  folderId: null,
};

const actualContentSlice = createSlice({
  name: 'actualContent',
  initialState,
  reducers: {
    clearState: () => initialState,
    changeToDeletedContent: state => {
      state.id = 'trash';
      state.folderId = null;
      state.name = 'Documentos deletados';

      return state;
    },
    changeActualContent: (
      state,
      action: PayloadAction<ActualDashboardContent>,
    ) => {
      state = action.payload;
      return state;
    },
  },
});

export const actualContentReducer = actualContentSlice.reducer;

export const actualContentActions = actualContentSlice.actions;
