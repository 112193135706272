import { getLocalStorageValueProps, setLocalStorageValueProps } from './types';

export function getLocalStorageValue({ key, defaultValue = null }: getLocalStorageValueProps) {
  if (!key) return defaultValue;

  const stringValue = localStorage.getItem(key);

  if (stringValue) {
    const parsedValue = JSON.parse(stringValue);
    return parsedValue;
  }

  return defaultValue;
}

export function setLocalStorageValue({ key, value }: setLocalStorageValueProps) {
  if (!key) return;
  localStorage.setItem(key, JSON.stringify(value));
}
