import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import { styled } from '@material-ui/styles';
import { commonConstants } from 'styles/common';

export const useMenuHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuIcon: {
      marginRight: theme.spacing(2),
    },
}));

export const Container = styled(Menu)<Theme>(() => ({
  top: '28px !important',
  borderRadius: commonConstants.borders.radius.md,
}));

export const MenuDivider = styled(Divider)<Theme>(({ theme }) => ({
    margin: theme.spacing(0.5, 1.5),
}));
