import React from 'react';
import { Menu } from '@material-ui/core';

import { MenuItem, useMenuStyles } from 'styles/shared/Menu';
import { FolderFilters } from '../types';

interface MenuFiltersProps {
  anchor: null | HTMLElement;
  handleClose: () => void;
  changeFolderFilter: (filter: FolderFilters) => void;
}

export function MenuFilters({
  anchor,
  handleClose,
  changeFolderFilter,
}: MenuFiltersProps) {
  const classes = useMenuStyles();

  const anchorOrigin = { horizontal: -65, vertical: 0 };

  return (
    <Menu
      anchorEl={anchor}
      keepMounted
      open={Boolean(anchor)}
      onClose={handleClose}
      className={classes.container}
      elevation={2}
      anchorOrigin={anchorOrigin}
    >
      <MenuItem onClick={() => changeFolderFilter('alphabetic')}>
        A - Z
      </MenuItem>
      <MenuItem onClick={() => changeFolderFilter('recent')}>
        Mais recentes
      </MenuItem>
    </Menu>
  );
}
