import { createAsyncThunk } from '@reduxjs/toolkit';
import * as documentsService from 'services/api-leeg/modules/documents';
import { toast } from 'react-toastify';
import { StoreState } from 'store';
import { ActualDashboardContent } from '../actualContent/types';
import { getDocumentsSwitcher } from './utils/getDocumentsSwitcher';
import { tabsActions } from '../tabs';
import { documentsActions } from '.';

// Async thunks
export const getActualContentDocuments = createAsyncThunk(
  'documents/getActualContentDocuments',
  async (actualContent: ActualDashboardContent, thunk) => {
    thunk.dispatch(documentsActions.updateLoading(true));
    try {
      const { results, count } = await getDocumentsSwitcher(actualContent);

      thunk.dispatch(documentsActions.updateDocuments(results));
      thunk.dispatch(documentsActions.updateCount(count));
    } catch (error) {
      toast.error('Não foi possível carregar os documentos');
    } finally {
      thunk.dispatch(documentsActions.updateLoading(false));
    }
  },
);

export const renameDocument = createAsyncThunk(
  'documents/renameDocument',
  async (documentToUpdate: { id: string; name: string }, thunk) => {
    try {
      const {
        documents: { data: documents },
        tabs: { data: tabs },
      } = thunk.getState() as StoreState;

      await documentsService.renameDocument(
        documentToUpdate.id,
        documentToUpdate.name,
      );
      const newDocuments = documents.map(document => {
        if (document._id === documentToUpdate.id) {
          return { ...document, name: documentToUpdate.name };
        }
        return document;
      });
      thunk.dispatch(documentsActions.updateDocuments(newDocuments));

      const newTabs = tabs.map(tab => {
        if (tab.id === documentToUpdate.id) {
          return { ...tab, name: documentToUpdate.name };
        }
        return tab;
      });
      thunk.dispatch(tabsActions.updateTabs(newTabs));
      toast.success('Documento renomeado com sucesso.');
    } catch (error) {
      toast.error('Não foi possível renomear o documento');
    }
  },
);

export const deleteDocument = createAsyncThunk(
  'documents/deleteDocument',
  async (id: string, thunk) => {
    try {
      const {
        documents: { data: documents },
        tabs: { data: tabs },
      } = thunk.getState() as StoreState;

      await documentsService.deleteDocument(id);

      const newTabs = tabs.filter(previousTabs => previousTabs.id !== id);
      thunk.dispatch(tabsActions.updateTabs(newTabs));

      const newDocuments = documents.filter(
        previousDocument => previousDocument._id !== id,
      );

      thunk.dispatch(documentsActions.updateDocuments(newDocuments));

      toast.success('Documento deletado com sucesso.');
    } catch (error) {
      toast.error('Não foi possível deletar o documento');
    }
  },
);

export const recoverDocument = createAsyncThunk(
  'documents/recoverDocument',
  async (id: string, thunk) => {
    try {
      const {
        documents: { data: documents },
      } = thunk.getState() as StoreState;

      await documentsService.recoverDocument(id);
      const newDocuments = documents.filter(
        previousDocument => previousDocument._id !== id,
      );

      thunk.dispatch(documentsActions.updateDocuments(newDocuments));
      toast.success('Documento recuperado com sucesso.');
    } catch (error) {
      toast.error('Não foi possível recuperar o documento');
    }
  },
);
