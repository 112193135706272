import clientApi from '../../../client';
import { Document, DocumentsResponse, SearchResponse } from './types';

export const getTemplateDocument = async (id: string): Promise<Document> => {
  const { data: documents } = await clientApi.get<Array<Document>>(
    `/documents/templates?filter[_id]=${id}`,
  );

  return documents[0];
};

export const getAllTemplatesDocuments = async (
  categoryFilters?: string,
): Promise<Array<Document>> => {
  const { data: documents } = await clientApi.get<Array<Document>>(
    `/documents/templates?filter[isDeleted]=false&sort[createdAt]=-1${
      categoryFilters ? `&${categoryFilters}` : ''
    }`,
  );
  return documents;
};

export const getAllDeletedTemplatesDocuments = async (): Promise<Array<Document>> => {
  const { data: documents } = await clientApi.get<Array<Document>>(
    '/documents/templates?filter[isDeleted]=true&sort[updatedAt]=-1',
  );
  return documents;
};

export const getThreeFirstTemplatesDocuments = async (): Promise<
  Array<Document>
> => {
  const { data: documents } = await clientApi.get<Array<Document>>(
    '/documents/templates?filter[isDeleted]=false&limit=3&sort[createdAt]=-1',
  );
  return documents;
};

export const getAllRecentDocuments = async (): Promise<DocumentsResponse> => {
  const { data: documents } = await clientApi.get<DocumentsResponse>(
    '/documents?filter[isDeleted]=false&fields[body]=0&filter[isTemplate]=false',
  );

  return documents;
};

export const getAllRemovedDocuments = async (): Promise<DocumentsResponse> => {
  const { data: documents } = await clientApi.get<DocumentsResponse>(
    '/documents?filter[isDeleted]=true&filter[isTemplate]=false',
  );

  return documents;
};

export const getFolderDocuments = async (
  parent: string,
): Promise<DocumentsResponse> => {
  const { data: documents } = await clientApi.get<DocumentsResponse>(
    `/documents?filter[parent]=${parent}&filter[isDeleted]=false&filter[isTemplate]=false`,
  );

  return documents;
};

export const createDocument = async (
  parent: string,
  documentDTO?: Partial<Document>,
  fromTemplate = false,
): Promise<Document> => {
  let swapDocumentDTO: Partial<Document>;

  if (fromTemplate) {
    swapDocumentDTO = {
      name: `[MODELO] ${documentDTO?.name}`,
      parent,
      body: documentDTO?.body,
      isAvailable: true,
      isTemplate: false,
    };
  } else {
    swapDocumentDTO = {
      name: documentDTO?.name ?? 'Sem nome',
      parent,
      body: '',
      isAvailable: true,
      isTemplate: documentDTO?.isTemplate ?? false,
      category: documentDTO?.category ?? undefined,
    };
  }

  const { data: document } = await clientApi.post<Document>(
    '/documents',
    swapDocumentDTO,
  );

  return document;
};

export const updateDocument = async (
  id: string,
  body: string,
): Promise<Document> => {
  const { data: document } = await clientApi.patch<Document>(
    `/documents/${id}`,
    { body },
  );

  return document;
};

export const updateDocumentPlans = async (
  id: string,
  allowedPlansIds: Array<string>,
): Promise<Document> => {
  const { data: document } = await clientApi.patch<Document>(
    `/documents/${id}`,
    { allowedPlansIds },
  );

  return document;
};

export const renameDocument = async (id: string, name: string) => {
  await clientApi.patch(`/documents/${id}`, { name });
};

export const deleteDocument = async (id: string) => {
  await clientApi.patch(`/documents/${id}`, { isDeleted: true });
};

export const recoverDocument = async (id: string) => {
  await clientApi.patch(`/documents/${id}`, { isDeleted: false });
};

export const getDocument = async (id: string): Promise<Document> => {
  const { data: document } = await clientApi.get(`/documents/${id}`);

  return document;
};

export const searchDocumentsAndTemplates = async (
  text: string,
): Promise<SearchResponse> => {
  const { data: document } = await clientApi.get(
    `/documents/search?searchString=${text}`,
  );

  return document;
};
