import {
  getAllRecentDocuments,
  getAllRemovedDocuments,
  getFolderDocuments,
} from 'services/api-leeg/modules/documents';
import { DocumentsResponse } from 'services/api-leeg/modules/documents/types';
import { ActualDashboardContent } from 'store/slices/actualContent/types';

export const getDocumentsSwitcher = async (
  actualDashboardContent: ActualDashboardContent,
): Promise<DocumentsResponse> => {
  if (
    actualDashboardContent.id === 'folder' &&
    !actualDashboardContent.folderId
  ) {
    throw new Error('Folder parent not found.');
  }

  switch (actualDashboardContent.id) {
    case 'recent-documents':
      return getAllRecentDocuments();
    case 'trash':
      return getAllRemovedDocuments();
    case 'folder':
      return getFolderDocuments(actualDashboardContent.folderId as string);

    default:
      throw new Error('Documents getter exception.');
  }
};
