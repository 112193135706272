import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import { RiDeleteBinLine, RiEdit2Line } from 'react-icons/ri';
import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';
import { Content, Row } from './styles';

export const CategoriesSkelleton = () => (
  <Content>
    {
    [2, 1, 3].map(category => (
      <Row key={category}>
        <Skeleton width={category * 100} />
        <Box>
          <IconButton
            color="inherit"
            size="small"
            disabled
          >
            <RiEdit2Line size={24} />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            disabled
          >
            <RiDeleteBinLine size={24} />
          </IconButton>
        </Box>
      </Row>
    ))
}
  </Content>
);
