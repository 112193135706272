import React from 'react';
import { ReactComponent as OpennedFolder } from 'assets/illustrations/openned-folder.svg';
import { useAppSelector } from 'hooks/store/useAppSelector';
import { useAppDispatch } from 'hooks/store/useAppDispatch';
import { swapFolderIdActions } from 'store/slices/swapFolderId';
import { FolderItemProps } from './types';
import { FolderBox, FolderText } from './styles';

export const FolderItem = ({ folder }: FolderItemProps) => {
  // Redux
  const swapFolderId = useAppSelector(state => state.swapFolderId);
  const dispatch = useAppDispatch();

  // Callbacks
  const handleSelectFolder = () => {
    dispatch(swapFolderIdActions.changeSwapFolderId(folder._id));
  };

  return (
    <FolderBox
      item
      xs={2}
      selected={swapFolderId === folder._id}
      onClick={handleSelectFolder}
    >
      <OpennedFolder />
      <FolderText>{folder.name}</FolderText>
    </FolderBox>
  );
};
