import React from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';

import { MiniPreviewContent } from 'components/MiniPreviewContent';
import { ContainerTemplate, useGridItemStyles } from './styles';
import { GridItemProps } from './types';

export default function GridItem({
  template,
  selected = false,
  onClick,
  openPreview,
  oneCollumn = false,
}: GridItemProps) {
  const classes = useGridItemStyles();

  const handleOpenPreview = () => {
    openPreview(template);
  };

  return (
    <Grid item xs={oneCollumn ? 12 : 3} style={{ marginBottom: 20 }}>
      <ContainerTemplate selected={selected} onClick={onClick}>
        <Box className={classes.previewContainer}>
          <MiniPreviewContent html={template.preview} />
        </Box>
        <Box className={classes.templateFooter}>
          <Typography className={classes.templateName}>
            {template.name}
          </Typography>
          <Button
            className={classes.previewButton}
            onClick={event => {
              event.stopPropagation();
              handleOpenPreview();
            }}
          >
            Visualizar
          </Button>
        </Box>
      </ContainerTemplate>
    </Grid>
  );
}
