import { AuthenticationContext } from 'context/Authentication';
import { useContext } from 'react';

export function useAuthentication() {
  const context = useContext(AuthenticationContext);

  if (!context) {
    throw new Error(
      'useAuthentication must be used within AuthenticationProvider',
    );
  }

  return context;
}
