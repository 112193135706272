import React from 'react';
import {
  RiUserLine,
  RiMoneyDollarCircleLine,
  RiSettings5Line,
  RiLogoutBoxRLine,
} from 'react-icons/ri';

import Colors from 'styles/colors';
import { useAuthentication } from 'hooks/useAuthentication';
import { MenuItem } from 'styles/shared/Menu';

import { useAppDispatch } from 'hooks/store/useAppDispatch';

import { documentsActions } from 'store/slices/documents';
import { foldersActions } from 'store/slices/folders';
import { useTabs } from 'hooks/useTabs';
import { Container, MenuDivider, useMenuHeaderStyles } from './styles';
import { HeaderMenuProps } from './types';

export function HeaderMenu({ anchor, handleClose, isAdmin }: HeaderMenuProps) {
  const anchorOrigin = { horizontal: isAdmin ? -70 : -60, vertical: 0 };

  // Styles
  const classes = useMenuHeaderStyles();

  // Context
  const { signOut } = useAuthentication();

  // Hooks
  const tabs = useTabs();

  // Redux
  const dispatch = useAppDispatch();

  // Callbacks

  const handleSignOut = () => {
    dispatch(documentsActions.clearState());
    dispatch(foldersActions.clearState());
    signOut();
  };

  return (
    <Container
      anchorEl={anchor}
      keepMounted
      open={Boolean(anchor)}
      onClose={handleClose}
      elevation={2}
      anchorOrigin={anchorOrigin}
    >
      <MenuItem onClick={() => tabs.goTo('profile', 'Perfil')}>
        <RiUserLine
          size={20}
          color={Colors.BLACK}
          className={classes.menuIcon}
        />
        Perfil
      </MenuItem>
      <MenuItem onClick={() => tabs.goTo('plans', 'Planos')}>
        <RiMoneyDollarCircleLine
          size={20}
          color={Colors.BLACK}
          className={classes.menuIcon}
        />
        Planos
      </MenuItem>
      {isAdmin && (
        <>
          <MenuDivider />
          <MenuItem onClick={() => tabs.goTo('admin', 'Administrador')}>
            <RiSettings5Line
              size={20}
              color={Colors.BLACK}
              className={classes.menuIcon}
            />
            Administrador
          </MenuItem>
        </>
      )}
      <MenuDivider />
      <MenuItem onClick={handleSignOut}>
        <RiLogoutBoxRLine
          size={18}
          color={Colors.BLACK}
          className={classes.menuIcon}
        />
        Sair
      </MenuItem>
    </Container>
  );
}
