import React, { useState } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Typography,
  Box,
  Button,
  IconButton,
  OutlinedInput,
  CircularProgress,
  Select,
  MenuItem,
} from '@material-ui/core';
import { RiCloseLine } from 'react-icons/ri';
import { useModalStyles } from 'styles/shared/Modal';
import { useAppSelector } from 'hooks/store/useAppSelector';
import { ModalCreateTemplateProps } from './types';

export const ModalCreateTemplate = ({
  open,
  onClose,
  onOk,
}: ModalCreateTemplateProps) => {
  // Style hooks
  const classes = useModalStyles();

  // Redux
  const { data: categories } = useAppSelector(state => state.adminDocumentCategories);

  // States
  const [name, setName] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [loading, setLoading] = useState(false);

  const handleOk = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loading) return;

    try {
      setLoading(true);
      await onOk(name, selectedCategoryId || categories[0]?._id);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Paper className={classes.paperTemplate}>
          <IconButton
            className={classes.closeButton}
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <RiCloseLine size={16} />
          </IconButton>
          <Typography className={classes.title}>
            Modelo
          </Typography>

          <form onSubmit={handleOk}>
            <Typography className={classes.label}>
              Nome do modelo
            </Typography>
            <OutlinedInput
              autoFocus
              className={classes.input}
              id="name"
              type="text"
              placeholder="Sem nome"
              onChange={({ target }) => setName(target.value)}
            />
            <Typography className={classes.label}>
              Categoria do modelo
            </Typography>
            <Select
              variant="outlined"
              defaultValue={categories[0]?._id}
              className={classes.input}
              onChange={({ target }) => setSelectedCategoryId(target.value as string)}
            >
              {categories.map(category => (
                <MenuItem
                  className={classes.input}
                  key={category._id}
                  value={category._id}
                >
                  {category.name}
                </MenuItem>
                ))}
            </Select>
            <Box className={classes.actions}>
              <Button className={classes.buttons} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                className={classes.buttons}
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading || !name}
              >
                Criar
                {loading && (
                  <CircularProgress
                    size={14}
                    className={classes.loadingProgress}
                  />
                )}
              </Button>
            </Box>
          </form>
        </Paper>
      </Fade>
    </Modal>
  );
};
