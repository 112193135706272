import React, { useMemo } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { parseDocumentSections } from 'utils/parseDocumentSections';
import { useAppSelector } from 'hooks/store/useAppSelector';
import { useGridStyles } from './styles';
import GridItem from './components/GridItem';
import { EmptyDocument } from '../EmptyDocument';
import GridItemSkelleton from './components/GridItem/Skelleton';

export function GridContent() {
  // Styles hooks
  const classes = useGridStyles();

  // Redux
  const { data: documents, isLoading } = useAppSelector(
    state => state.documents,
  );

  // CallBacks
  const sortedDocuments = useMemo(
    () => parseDocumentSections(documents),
    [documents],
  );

  if (!isLoading && documents.length === 0) return <EmptyDocument />;

  return (
    <>
      {isLoading ? (
        <Box className={classes.section}>
          <Skeleton style={{ marginBottom: 20 }} width="100px" />
          <Grid container xs={12} sm={12} md={12} lg={12} xl={9}>
            {[1, 2, 3, 4, 5, 6].map(() => (
              <GridItemSkelleton />
            ))}
          </Grid>
        </Box>
      ) : (
        <Box>
          {sortedDocuments.createdToday.length > 0 && (
            <Box className={classes.section}>
              <Typography className={classes.sectionHeader}>Hoje</Typography>
              <Grid container xs={12} sm={12} md={12} lg={12} xl={9}>
                {sortedDocuments.createdToday.map(document => (
                  <GridItem key={document._id} document={document} />
                ))}
              </Grid>
            </Box>
          )}
          {sortedDocuments.thisWeek.length > 0 && (
            <Box className={classes.section}>
              <Typography className={classes.sectionHeader}>
                Essa semana
              </Typography>
              <Grid container xs={12} sm={12} md={12} lg={12} xl={9}>
                {sortedDocuments.thisWeek.map(document => (
                  <GridItem key={document._id} document={document} />
                ))}
              </Grid>
            </Box>
          )}
          {sortedDocuments.others.length > 0 && (
            <Box className={classes.section}>
              <Typography className={classes.sectionHeader}>
                Mais antigos
              </Typography>
              <Grid container xs={12} sm={12} md={12} lg={12} xl={9}>
                {sortedDocuments.others.map(document => (
                  <GridItem key={document._id} document={document} />
                ))}
              </Grid>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
