import React, { useState } from 'react';
import {
  Box,
  ButtonBase,
  Paper,
  Typography,
} from '@material-ui/core';
import { RiAddCircleFill } from 'react-icons/ri';
import Colors from 'styles/colors';
import { useAppSelector } from 'hooks/store/useAppSelector';
import { useAppDispatch } from 'hooks/store/useAppDispatch';
import { useAuthentication } from 'hooks/useAuthentication';
import { createTemplate } from 'store/adminSlices/adminDocumentsTemplates/sideEffects';
import { RenderActionProps } from './types';
import { useSidebarStyles } from './styles';
import { ModalCategory } from '../ModalCategory';
import { ModalCreateTemplate } from '../ModalCreateTemplate';
import { ModalUserAdmin } from '../ModalUserAdmin';

export function Sidebar() {
  // Style hooks
  const classes = useSidebarStyles();

  // Context
  const { user } = useAuthentication();

  // Redux
  const dispatch = useAppDispatch();
  const adminActualContent = useAppSelector(state => state.adminActualContent);

  // States
  const [modalCreateUserAdminIsOpen, setModalCreateUserAdminIsOpen] = useState(false);
  const [modalCreateCategoryIsOpen, setModalCreateCategoryIsOpen] = useState(false);
  const [modalCreateTemplateIsOpen, setModalCreateTemplateIsOpen] = useState(false);

  const handleAction = async () => {
    switch (adminActualContent.id) {
      case 'recent-templates':
        return setModalCreateTemplateIsOpen(true);
      case 'admin-users':
        return setModalCreateUserAdminIsOpen(true);
      case 'plans':
        return console.log('RECENT TEMPLATES');
      case 'categories':
        return setModalCreateCategoryIsOpen(true);
      default:
        return console.log('RECENT TEMPLATES');
    }
  };

  const RenderAction = ({
    icon,
    color,
    onClick,
  }: RenderActionProps) => {
    let text;

    switch (adminActualContent.id) {
      case 'recent-templates':
        text = 'Novo modelo';
        break;
      case 'admin-users':
        text = 'Novo usuário admin';
        break;
      case 'plans':
        return null;
      case 'categories':
        text = 'Nova categoria';
        break;
      default:
        text = 'Novo modelo';
        break;
    }

    return (
      <ButtonBase
        onClick={onClick}
        className={classes.buttonBase}
      >
        <Paper elevation={0} className={classes.actions}>
          {icon}
          <Typography style={{ color }} className={classes.buttonText}>
            {text}
          </Typography>
        </Paper>
      </ButtonBase>
  );
  };

  const handleCreateTemplate = async (name: string, category: string) => {
      dispatch(createTemplate({
        parent: user?.rootFolder._id as string,
        category,
        name,
      }));
  };

  return (
    <Box className={classes.container}>
      <Typography className={classes.headerDocuments}>Ações</Typography>
      <RenderAction
        onClick={handleAction}
        color={Colors.LIGHT_BLUE}
        icon={<RiAddCircleFill size={28} color={Colors.LIGHT_BLUE} />}
      />

      <ModalCreateTemplate
        onClose={() => setModalCreateTemplateIsOpen(false)}
        open={modalCreateTemplateIsOpen}
        onOk={handleCreateTemplate}
      />

      <ModalCategory
        open={modalCreateCategoryIsOpen}
        handleClose={() => setModalCreateCategoryIsOpen(false)}
        type="create"
      />

      <ModalUserAdmin
        open={modalCreateUserAdminIsOpen}
        handleClose={() => setModalCreateUserAdminIsOpen(false)}
        type="create"
      />
    </Box>
  );
}
