import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import * as documentCategoriesServices from 'services/api-leeg/modules/documentCategories';
import { DocumentCategory } from 'services/api-leeg/modules/documentCategories/types';
import * as documentServices from 'services/api-leeg/modules/documents';
import { documentCategoriesActions } from '.';

// Async thunks
export const getDocumentCategories = createAsyncThunk(
  'documentCategories/getDocumentCategories',
  async (_, thunk) => {
    thunk.dispatch(documentCategoriesActions.updateLoading(true));
    try {
      const documentCategories = await documentCategoriesServices.getAllDocumentCategories();

      thunk.dispatch(documentCategoriesActions.updateDocumentCategories(documentCategories));
    } catch (error) {
      toast.error('Não foi possível carregar as categorias de documentos');
    } finally {
      thunk.dispatch(documentCategoriesActions.updateLoading(false));
    }
  },
);

export const getDocumentByCategory = createAsyncThunk(
  'documentCategories/getDocumentByCategory',
  async (documentCategories: Array<Partial<DocumentCategory>>, thunk) => {
    thunk.dispatch(documentCategoriesActions.updateLoadingTemplates(true));
    try {
      const documentCategoriesIds = documentCategories.map(documentCategory =>
        `filter[category]=${documentCategory?._id}`,
      );

      const stringfiedDocumentCategories = documentCategoriesIds.join('&');

      const documents = await documentServices.getAllTemplatesDocuments(
        stringfiedDocumentCategories,
      );

      thunk.dispatch(documentCategoriesActions.updateTemplates(documents));
    } catch (error) {
      toast.error('Não foi possível carregar os modelos de documentos.');
    } finally {
      thunk.dispatch(documentCategoriesActions.updateLoadingTemplates(false));
    }
  },
);

export const getFirstThreeTemplates = createAsyncThunk(
  'documentCategories/getDocumentCategories',
  async (_, thunk) => {
    thunk.dispatch(documentCategoriesActions.updateLoadingTemplates(true));
    try {
      const documents = await documentServices.getThreeFirstTemplatesDocuments();

      thunk.dispatch(documentCategoriesActions.updateFirstTheeTemplates(documents));
    } catch (error) {
      toast.error('Não foi possível carregar os modelos de documentos.');
    } finally {
      thunk.dispatch(documentCategoriesActions.updateLoadingTemplates(false));
    }
  },
);
