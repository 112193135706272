import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { RiCloseLine, RiFile2Line, RiSettings5Line, RiUserLine } from 'react-icons/ri';

import Colors from 'styles/colors';

import { TabButton } from '../styles';

import { TabProps } from './types';
import { Container, TabText } from './styles';

const RenderTabStartIcon = ({ id }: { id: string }) => {
  const icons: { [key: string]: any } = {
    profile: <RiUserLine size={16} />,
    admin: <RiSettings5Line size={16} />,
  };

  return icons[id] || <RiFile2Line size={16} />;
};

export const Tab = ({ tab, activeTab, handleSelectTab, handleCloseTab }: TabProps) => (
  <Container item key={tab.id}>
    <TabButton
      active={tab.id === activeTab}
      startIcon={<RenderTabStartIcon id={tab.id} />}
      onClick={() => handleSelectTab(tab.id)}
    >
      <Tooltip title={tab.name}>
        <TabText>{tab.name}</TabText>
      </Tooltip>
      <IconButton onClick={event => handleCloseTab(event, tab.id)}>
        <RiCloseLine color={Colors.WHITE} size={15} />
      </IconButton>
    </TabButton>
  </Container>
  );
