import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserAdminData } from 'services/api-leeg/modules/adminUsers/types';
import { AdminUsersStates } from './types';

// Initial states
const initialState: AdminUsersStates = {
  data: [],
  count: 0,
  isLoading: false,
};

// Slicer
const adminUsersSlice = createSlice({
  name: 'adminUsersSlice',
  initialState,
  reducers: {
    clearState: () => initialState,
    updateUsers: (state, action: PayloadAction<Array<UserAdminData>>) => {
      state.data = action.payload;
      return state;
    },
    updateCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
      return state;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      return state;
    },
  },
});

// Reduces & Actions
export const adminUsersReducer = adminUsersSlice.reducer;
export const adminUsersActions = adminUsersSlice.actions;
