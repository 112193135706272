import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Folder } from 'services/api-leeg/modules/folders/types';
import { FolderStates } from './types';

// Initial States
const initialState: FolderStates = {
  data: [],
  count: 0,
  isLoading: false,
};

// Slicer
const foldersSlice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    clearState: () => initialState,
    updateFolders: (state, action: PayloadAction<Array<Folder>>) => {
      state.data = action.payload;
      return state;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      return state;
    },
    updateCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
      return state;
    },
  },
});

// Reduces & Actions
export const foldersReducer = foldersSlice.reducer;
export const foldersActions = foldersSlice.actions;
