import React, { useState } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Typography,
  Box,
  Button,
  IconButton,
  OutlinedInput,
  CircularProgress,
} from '@material-ui/core';
import { RiCloseLine } from 'react-icons/ri';
import { useModalStyles } from 'styles/shared/Modal';

import { ModalFieldProps } from './types';

export const ModalFieldNew = ({
  title,
  label,
  okButtonText = 'Salvar',
  open,
  onClose,
  onOk,
  selectedItem,
  password,
}: ModalFieldProps) => {
  // Style hooks
  const classes = useModalStyles();

  // States
  const [name, setName] = useState(selectedItem?.name ?? '');
  const [loading, setLoading] = useState(false);

  const handleOk = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loading) return;

    try {
      setLoading(true);
      await onOk(name);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <IconButton
            className={classes.closeButton}
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <RiCloseLine size={16} />
          </IconButton>
          <Typography className={classes.title}>
            {title}
          </Typography>

          <Typography className={classes.label}>
            {label}
          </Typography>
          <form onSubmit={handleOk}>
            <OutlinedInput
              autoFocus
              defaultValue={selectedItem?.name ? selectedItem?.name : ''}
              className={classes.input}
              id="name"
              type={password ? 'password' : 'text'}
              onChange={({ target }) => setName(target.value)}
            />

            <Box className={classes.actions}>
              <Button className={classes.buttons} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                className={classes.buttons}
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {okButtonText}
                {loading && (
                  <CircularProgress
                    size={14}
                    className={classes.loadingProgress}
                  />
                )}
              </Button>
            </Box>
          </form>
        </Paper>
      </Fade>
    </Modal>
  );
};
