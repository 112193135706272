import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentCategory } from 'services/api-leeg/modules/documentCategories/types';
import { Document } from 'services/api-leeg/modules/documents/types';
import { DocumentsStates } from './types';

// Initial states
const initialState: DocumentsStates = {
  data: [],
  templates: [],
  firstThreeTemplates: [],
  isLoading: false,
  isLoadingTemplates: false,
};

// Slicer
const documentCategoriesSlice = createSlice({
  name: 'documentCategories',
  initialState,
  reducers: {
    clearState: () => initialState,
    updateDocumentCategories: (state, action: PayloadAction<Array<DocumentCategory>>) => {
      state.data = action.payload;
      return state;
    },
    updateTemplates: (state, action: PayloadAction<Array<Document>>) => {
      state.templates = action.payload;
      return state;
    },
    updateFirstTheeTemplates: (state, action: PayloadAction<Array<Document>>) => {
      state.firstThreeTemplates = action.payload;
      return state;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      return state;
    },
    updateLoadingTemplates: (state, action: PayloadAction<boolean>) => {
      state.isLoadingTemplates = action.payload;
      return state;
    },
  },
});

// Reduces & Actions
export const documentCategoriesReducer = documentCategoriesSlice.reducer;
export const documentCategoriesActions = documentCategoriesSlice.actions;
