import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditorStates } from './types';

const initialState: EditorStates = {
  isUpdating: false,
  changed: false,
};

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    clearState: () => initialState,
    setIsUpdating: (state, action: PayloadAction<boolean>) => {
      state.isUpdating = action.payload;
      return state;
    },
    setChanged: (state, action: PayloadAction<boolean>) => {
      state.changed = action.payload;
      return state;
    },
  },
});

export const editorReducer = editorSlice.reducer;

export const editorActions = editorSlice.actions;
