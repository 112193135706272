import React, { useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import { ModalFieldNew } from 'components/ModalFieldNew';
import { useAuthentication } from 'hooks/useAuthentication';
import { toast } from 'react-toastify';
import { forgotPassword, recoverPassword } from 'services/api-leeg/modules/auth';

import { Container, SectionTitle, SectionSubtitle, ItemName, ItemDescription, ItemButton } from '../styles';

export const Security = () => {
  // State
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  // Context
  const { user } = useAuthentication();

  const handleChangePassword = async (password: string) => {
    try {
      const token = await forgotPassword(user?.email as string);
      await recoverPassword(token, password);
      toast.success('Senha alterada com sucesso.');
    } catch (error) {
      toast.success('Erro ao tentar alterar senha.');
    }
  };

  return (
    <Container>
      <SectionTitle>Minha conta</SectionTitle>
      <SectionSubtitle>Senha e segurança</SectionSubtitle>
      <Box marginTop={5}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <ItemName>Alterar senha</ItemName>
            <ItemDescription>Para alterar sua senha, clique no link abaixo</ItemDescription>
            <ItemButton onClick={() => setChangePasswordModalOpen(true)}>Alterar senha</ItemButton>
          </Grid>
          {/* <Grid item xs={6}>
            <ItemName>Excluir conta</ItemName>
            <ItemDescription>Para excluir sua conta, clique no link abaixo</ItemDescription>
            <ItemButton onClick={() => alert('Excluir')}>Excluir conta</ItemButton>
          </Grid> */}
        </Grid>
      </Box>
      <ModalFieldNew
        label="Nova senha"
        open={changePasswordModalOpen}
        onOk={handleChangePassword}
        onClose={() => setChangePasswordModalOpen(false)}
        title="Alterar senha"
        okButtonText="Alterar"
        password
      />
    </Container>
  );
};
