import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProfileStates } from './types';

const initialState: ProfileStates = {
  activeTab: 'edit',
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    clearState: () => initialState,
    updateActiveTab: (state, action: PayloadAction<ProfileStates['activeTab']>) => {
      state.activeTab = action.payload;
      return state;
    },
  },
});

export const profileReducer = profileSlice.reducer;

export const profileActions = profileSlice.actions;
