import { makeStyles, createStyles } from '@material-ui/core';
import Colors from 'styles/colors';

export const useMasonryStyles = makeStyles(() =>
  createStyles({
    imageContainer: {
      position: 'relative',
      '& #trash': {
        display: 'none',
      },
      '&:hover #trash': {
        display: 'block',
      },
    },
    image: {
      maxWidth: '100%',
      '&:hover': {
        cursor: 'pointer',
        transform: 'scale(1.06)',
      },
    },
    deleteButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      backgroundColor: Colors.WHITE,
      padding: 2,
    },
}));
