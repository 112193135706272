import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { commonConstants } from 'styles/common';

export const useSignUpSectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { paddingTop: '1rem' },
    text: {
      fontSize: commonConstants.font.size.md,
    },
    button: {
      fontSize: commonConstants.font.size.md,
      fontWeight: commonConstants.font.weight.bold,
      color: 'primary',
      paddingLeft: theme.spacing(0.3),
      transition: theme.transitions.create(['opacity'], {
        duration: 200,
      }),
      '&:hover': {
        opacity: 0.7,
        cursor: 'pointer',
      },
   },
}));
