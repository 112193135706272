import { Box, Theme, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { commonConstants } from 'styles/common';

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'calc(100vh - 43px)',
}));

export const TitleContainer = styled(Box)<Theme>(({ theme }) => ({
  width: '70%',
  padding: theme.spacing(3.5),
}));

export const Title = styled(Typography)(() => ({
  fontSize: commonConstants.font.size.xxl,
  fontWeight: commonConstants.font.weight.bold,
}));

export const Description = styled(Typography)<Theme>(({ theme }) => ({
  fontSize: commonConstants.font.size.xl,
  paddingTop: theme.spacing(1.5),
}));

export const SubDescription = styled(Typography)<Theme>(({ theme }) => ({
  fontSize: commonConstants.font.size.lg,
  paddingTop: theme.spacing(2.5),
}));

export const Content = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  width: '70%',
  justifyContent: 'space-around',
}));
