import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useHistory } from 'react-router';
import { useSignUpSectionStyles } from './styles';

export default function SignUpSection() {
  const history = useHistory();
  const classes = useSignUpSectionStyles();

  const handleGoToSignUp = () => {
    history.push('/signup');
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      className={classes.container}
    >
      <Typography className={classes.text}>Ainda não possui conta?</Typography>
      <Typography
        color="primary"
        className={classes.button}
        onClick={handleGoToSignUp}
      >
        Cadastre-se aqui
      </Typography>
    </Grid>
  );
}
