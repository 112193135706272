import React from 'react';
import { Grid, Card, Box, IconButton } from '@material-ui/core';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { RiMore2Fill } from 'react-icons/ri';
import { MiniPreviewContentSkelleton } from 'components/MiniPreviewContent/Skelleton';
import { useGridItemStyles } from './styles';

export default function GridItemSkelleton() {
  const classes = useGridItemStyles();

  return (
    <Grid item xs={3} sm={3} style={{ marginBottom: 20 }}>
      <Card elevation={0} className={classes.container}>
        <Box className={classes.previewContainer}>
          <MiniPreviewContentSkelleton />
        </Box>
        <Box className={classes.footer}>
          <Box className={classes.infoContainer}>
            <Skeleton className={classes.name} height="10px" width="70%" />
          </Box>
          <IconButton className={classes.icon} disabled color="inherit">
            <RiMore2Fill size={16} />
          </IconButton>
        </Box>
      </Card>
    </Grid>
  );
}
