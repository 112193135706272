import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { useGridStyles } from './styles';
import GridItemSkelleton from './components/GridItem/Skelleton';

export function GridContentSkelleton() {
  const classes = useGridStyles();

  return (
    <Box className={classes.gridContainer}>
      <Box className={classes.section}>
        <Grid container xs={12} spacing={3}>
          {[1, 2, 3, 4].map(template => (
            <GridItemSkelleton key={template} />
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
