import React from 'react';

import { Edit } from './Edit';
import { Plan } from './Plan';
import { Security } from './Security';

export const Content = ({ tab }: { tab: string }) => {
  switch (tab) {
    case 'edit': return <Edit />;
    case 'plan': return <Plan />;
    case 'security': return <Security />;
    default: return <Edit />;
  }
};
