import React from 'react';
import { Grid, Box } from '@material-ui/core';

import { MiniPreviewContentSkelleton } from 'components/MiniPreviewContent/Skelleton';
import { ContainerTemplate, useGridItemStyles } from './styles';

import 'react-loading-skeleton/dist/skeleton.css';

export default function GridItemSkelleton() {
  const classes = useGridItemStyles();

  return (
    <Grid item xs={3} style={{ marginBottom: 20 }}>
      <ContainerTemplate selected={false}>
        <Box className={classes.previewContainer}>
          <MiniPreviewContentSkelleton />
        </Box>
        <Box className={classes.templateFooter} />
      </ContainerTemplate>
    </Grid>
  );
}
