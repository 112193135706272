import { Box, Paper, Theme, Typography, Divider, Button } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

export const Container = styled(Paper)<Theme, { selected: boolean }>(({ theme, selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '92%',
  maxHeight: 560,
  width: 280,
  borderRadius: commonConstants.borders.radius.lg,
  padding: theme.spacing(1.5, 2),
  border: selected ? `2px solid ${Colors.LIGHT_BLUE}` : 'none',
  '&:hover': {
    cursor: 'pointer',
  },
}));

export const Title = styled(Typography)(() => ({
  fontSize: commonConstants.font.size.xxxl,
  fontWeight: commonConstants.font.weight.bold,
}));

export const ValueBox = styled(Box)<Theme>(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1.5, 0),
  alignItems: 'center',
}));

export const Value = styled(Typography)(() => ({
  fontSize: commonConstants.font.size.xxxl,
  fontWeight: commonConstants.font.weight.bold,
}));

export const MonthText = styled(Typography)(() => ({
  fontSize: commonConstants.font.size.xxl,
  fontWeight: commonConstants.font.weight.bold,
  color: Colors.GRAY_400,
}));

export const Description = styled(Typography)(() => ({
  fontSize: commonConstants.font.size.lg,
  color: Colors.GRAY_400,
}));

export const ItemBox = styled(Box)<Theme>(({ theme }) => ({
  display: 'flex',
  paddingTop: theme.spacing(2),
  justifyContent: 'space-between',
}));

export const ItemText = styled(Typography)(() => ({
  fontSize: commonConstants.font.size.lg,
  fontWeight: commonConstants.font.weight.bold,
  width: '90%',
}));

export const ItemsDivider = styled(Divider)<Theme>(({ theme }) => ({
  margin: theme.spacing(4, 0, 2),
}));

export const HandlePlanButton = styled(Button)<Theme>(() => ({
  fontWeight: commonConstants.font.weight.bold,
  fontSize: commonConstants.font.size.lg,
}));
