import React, { useCallback, useEffect, useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';

import { RiArrowDownSLine, RiArrowUpSLine, RiMore2Fill } from 'react-icons/ri';

import { Anchor, ModalSelectedItem } from 'types/shared';
import { formatDetailDate } from 'utils/parseDate';
import { Document } from 'services/api-leeg/modules/documents/types';
import { useAppSelector } from 'hooks/store/useAppSelector';
import { useContentListStyles } from './styles';
import { MenuDocument } from '../Grid/components/GridItem/MenuDocument';
import { EmptyDocument } from '../EmptyDocument';

export function ListContent() {
  // Styles hooks
  const classes = useContentListStyles();

  /// Redux
  const { data: documents } = useAppSelector(state => state.documents);

  // States
  const [sortedDocuments, setSortedDocuments] =
    useState<Array<Document>>(documents);

  const [dateToggle, setDateToggle] = useState(false);
  const [sizeToggle, setSizeToggle] = useState(false);

  const [anchorMenuDocument, setAnchorMenuDocument] = useState<Anchor>(null);
  const [selectedItem, setSelectedItem] = useState<ModalSelectedItem>(
    {} as ModalSelectedItem,
  );
  const [selectedDocument, setSelectedDocument] = useState({} as Document);

  // Callbacks
  const handleOpenMenuDocument = (
    event: React.MouseEvent<HTMLButtonElement>,
    document: Document,
  ) => {
    setSelectedItem({
      id: document._id,
      name: document.name,
      isDocument: true,
    });
    setSelectedDocument(document);
    setAnchorMenuDocument(event.currentTarget);
  };

  const formatStringBytes = useCallback((text: string, decimals = 2) => {
    const bytesEncoded = new TextEncoder().encode(text).length;
    if (bytesEncoded === 0) return '0 Bytes';

    const initalKValue = 1024;
    const quantityOfDecimals = decimals < 0 ? 0 : decimals;
    const sizesPosFix = ['Bytes', 'KB', 'MB', 'GB'];

    const posFixIndex = Math.floor(
      Math.log(bytesEncoded) / Math.log(initalKValue),
    );

    return `${parseFloat(
      (bytesEncoded / initalKValue ** posFixIndex).toFixed(quantityOfDecimals),
    )} ${sizesPosFix[posFixIndex]}`;
  }, []);

  const sortByDateOfUpdate = () => {
    setDateToggle(prevToggle => !prevToggle);

    setSortedDocuments(prev => [
      ...prev.slice().sort((a, b) => {
        if (!dateToggle) {
          return b.updatedAt.localeCompare(a.updatedAt);
        }
        return a.updatedAt.localeCompare(b.updatedAt);
      }),
    ]);
  };

  const sortByDocumentSize = () => {
    setSizeToggle(prevToggle => !prevToggle);

    const sorted = documents.slice().sort((a, b) => {
      const aSize = new TextEncoder().encode(a.body).length;
      const bSize = new TextEncoder().encode(b.body).length;
      if (sizeToggle) {
        return aSize > bSize ? 1 : -1;
      }
      return aSize < bSize ? 1 : -1;
    });
    setSortedDocuments(sorted);
  };

  const handleCloseMenuDocument = () => setAnchorMenuDocument(null);

  useEffect(() => {
    setSortedDocuments(documents);
  }, [documents]);

  if (documents.length === 0) return <EmptyDocument />;

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerCell}>
              Nome do documento
            </TableCell>
            <TableCell className={classes.headerCell}>
              Modificado há
              <IconButton color="inherit" onClick={sortByDateOfUpdate}>
                {dateToggle ? <RiArrowDownSLine /> : <RiArrowUpSLine />}
              </IconButton>
            </TableCell>
            <TableCell className={classes.headerCell}>
              Tamanho
              <IconButton color="inherit" onClick={sortByDocumentSize}>
                {sizeToggle ? <RiArrowDownSLine /> : <RiArrowUpSLine />}
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {sortedDocuments.map(document => (
            <TableRow key={document._id}>
              <TableCell className={classes.bodyCell}>
                {document.name}
              </TableCell>
              <TableCell className={classes.bodyCell}>
                {formatDetailDate(document.updatedAt)}
              </TableCell>
              <TableCell className={classes.bodyCell}>
                {`${formatStringBytes(document?.body ?? '')}`}
              </TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={event => handleOpenMenuDocument(event, document)}
                  color="inherit"
                  size="small"
                >
                  <RiMore2Fill />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <MenuDocument
            anchor={anchorMenuDocument}
            handleClose={handleCloseMenuDocument}
            selectedItem={selectedItem}
            document={selectedDocument}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
