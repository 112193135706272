import { UserDto } from 'types/dtos/user.dto';
import { clearObject } from 'utils/clearObject';
import clientApi from '../../../client';
import { UserData, UserProfileData } from './types';

export const getUserProfile = async (): Promise<UserProfileData> => {
  const { data: userProfile } = await clientApi.get('/users/profile');

  return userProfile;
};

export const updateUser = async (userData: UserData): Promise<void> => {
  const addressFieldsToUpdate = clearObject(userData.address);
  const generalFieldsToUpdate = clearObject({ ...userData, address: null });

  await clientApi.patch('/users', {
    address: addressFieldsToUpdate,
    ...generalFieldsToUpdate,
  });
};

export const updateUserAvatar = async (image: Blob): Promise<string> => {
  const formData = new FormData();

  formData.append('image', image);

  const { data: user } = await clientApi.post<UserDto>('users/avatar', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return user?.urlAvatar;
};
