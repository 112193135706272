import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Admin } from 'pages/SignedIn/Admin';
import { Editor } from 'pages/SignedIn/Editor';
import { Home } from 'pages/SignedIn/Home';
import { Profile } from 'pages/SignedIn/Profile';
import { Plans } from 'pages/SignedIn/Plans';

export const AdminRoutes = () => (
  <Switch>
    <Route path="/admin" component={Admin} />
    <Route path="/dashboard" exact component={Home} />
    <Route path="/profile" component={Profile} />
    <Route path="/editor/:documentId" component={Editor} />
    <Route path="/plans" component={Plans} />
    <Route path="*">
      <Redirect to="/dashboard" />
    </Route>
  </Switch>
);
