import React, { createContext, useCallback, useEffect, useState } from 'react';

import * as authenticationService from 'services/api-leeg/modules/auth';
import clientApi from 'services/client';

import { toast } from 'react-toastify';

import { getLocalStorageValue } from 'helpers/LocalStorage';

import {
  storageKeyAccessToken,
  storageKeyUser,
  UserDto,
} from 'types/dtos/user.dto';
import {
  SignInCredentials,
  SignUpCredentials,
} from 'services/api-leeg/modules/auth/types';
import { storeUserData } from 'utils/storeUserData';
import { useHistory } from 'react-router';
import { getUserProfile, updateUser, updateUserAvatar } from 'services/api-leeg/modules/user';
import { UserData } from 'services/api-leeg/modules/user/types';
import {
  AuthenticationContextProps,
  AuthenticationProviderProps,
  UserDataFormValues,
} from './types';

export const AuthenticationContext = createContext<AuthenticationContextProps>(
  {} as AuthenticationContextProps,
);

export function AuthenticationProvider({
  children,
}: AuthenticationProviderProps) {
  // Hooks
  const history = useHistory();

  // States
  const [user, setUser] =
    useState<Omit<UserDto, 'accessToken' | 'refreshToken'>>();
  const [loadingSignIn, setLoadingSignIn] = useState(false);
  const [loadingSignUp, setLoadingSignUp] = useState(false);
  const [loadingForgotPassword, setLoadingForgotPassword] = useState(false);
  const [rehydrateLoading, setRehydrateLoading] = useState(true);

  const signIn = async (credentials: SignInCredentials) => {
    try {
      if (loadingSignIn) return;
      setLoadingSignIn(true);
      const responseUser = await authenticationService.login(credentials);
      setUser(responseUser);
      clientApi.defaults.headers.Authorization = `Bearer ${responseUser.accessToken}`;

      storeUserData(responseUser);
    } catch (error) {
      toast.error('Não foi possível efetuar o login.');
      throw Error('Unable to login');
    } finally {
      setLoadingSignIn(false);
    }
  };

  const signUp = async (credentials: SignUpCredentials) => {
    try {
      if (loadingSignUp) return;
      setLoadingSignUp(true);

      const responseUser = await authenticationService.signUp(credentials);

      setUser(responseUser);
      clientApi.defaults.headers.Authorization = `Bearer ${responseUser.accessToken}`;

      storeUserData(responseUser);
    } catch (error: any) {
      toast.error(`Não foi possivel efetuar o cadastro.${error.response.status === 409 && ' Usuário já cadastrado.'}`);
      throw Error('Unable to sign up');
    } finally {
      setLoadingSignUp(false);
    }
  };

  const signOut = async () => {
    clientApi.defaults.headers.Authorization = 'Bearer ';
    localStorage.clear();
    setUser(undefined);
  };

  const forgotPassword = async (email: string) => {
    try {
      setLoadingForgotPassword(true);
      const response = await authenticationService.forgotPassword(email);

      if (response) {
        toast.success('Verifique o seu e-mail para definir uma nova senha.');
      }
    } catch (error) {
      toast.error('O e-mail não está cadastrado.');
    } finally {
      setLoadingForgotPassword(false);
    }
  };

  const recoverPassword = async (token: string, password: string) => {
    try {
      setLoadingForgotPassword(true);
      await authenticationService.recoverPassword(token, password);

      history.push('/login');
      toast.success('Senha atualizada com sucesso');
    } catch (error) {
      toast.error('Não foi possível atualizar sua senha.');
    } finally {
      setLoadingForgotPassword(false);
    }
  };

  const getUser = async () => {
    try {
      const { signature } = await getUserProfile();

      setUser(previousUser => ({ ...previousUser as UserDto, signature }));
    } catch (error) {
      toast.error('Não foi possível buscar informações do usuário.');
    }
  };

  const updateUserProfile = async (values: UserDataFormValues) => {
    try {
      const userToUpdate: UserData = {
        name: values?.name ?? '',
        email: values?.email ?? '',
        phone: values?.phone ?? '',
        cpf: values?.cpf ?? '',
        address: {
          cityName: values?.cityName ?? '',
          district: values?.district ?? '',
          street: values?.street ?? '',
          stateInitials: values?.stateInitials ?? '',
          zipCode: values?.zipCode ?? '',
          countryName: 'Brazil',
          number: values?.number ?? '',
          ibge: values.ibge ?? '',
        },
      };
      const storagedUser = localStorage.getItem('leeg::user');

      const parsedStoragedUser: UserDto = JSON.parse(storagedUser as string);

      await updateUser(userToUpdate);

      localStorage.setItem('leeg::user', JSON.stringify({
        ...parsedStoragedUser,
        ...userToUpdate,
      }));

      setUser({ ...parsedStoragedUser, ...userToUpdate });

      toast.success('Perfil atualizado com sucesso.');
    } catch (error) {
      toast.error('Não foi possível atualizar seus dados.');
    }
  };

  const updateUserProfileAvatar = async (image: Blob) => {
    try {
      const urlAvatar = await updateUserAvatar(image);

      const storagedUser = localStorage.getItem('leeg::user');
      const parsedStoragedUser: UserDto = JSON.parse(storagedUser as string);

      localStorage.setItem('leeg::user', JSON.stringify({
        ...parsedStoragedUser,
        urlAvatar,
      }));

      setUser({
        ...parsedStoragedUser,
        urlAvatar,
      });

      toast.success('Foto de perfil atualizada com sucesso.');
    } catch (error) {
      toast.error('Não foi possível atualizar sua foto de perfil.');
    }
  };

  const rehydrate = useCallback(async () => {
    const rehydratedToken: string = getLocalStorageValue({
      key: storageKeyAccessToken,
    });

    if (rehydratedToken) {
      clientApi.defaults.headers.Authorization = `Bearer ${rehydratedToken}`;

      const rehydratedUser: Omit<UserDto, 'accessToken' | 'refreshToken'> =
        await getLocalStorageValue({
          key: storageKeyUser,
        });

      setUser(rehydratedUser);
    } else {
      signOut();
    }

    setRehydrateLoading(false);
  }, []);

  useEffect(() => {
    rehydrate();
  }, [rehydrate]);

  const values: AuthenticationContextProps = {
    isSigned: !!user,
    user,
    signIn,
    signUp,
    signOut,
    loadingSignIn,
    loadingSignUp,
    forgotPassword,
    loadingForgotPassword,
    recoverPassword,
    rehydrateLoading,
    updateUserProfile,
    updateUserProfileAvatar,
    getUser,
  };

  return (
    <AuthenticationContext.Provider value={values}>
      {!rehydrateLoading && children}
    </AuthenticationContext.Provider>
  );
}
