import {
  TextSection,
  ElementsSection,
  BackgroundSection,
  SizeSection,
  PhotosSection,
  } from 'polotno/side-panel';

import { CustomImages } from './CustomImages';

export const sections = [
  TextSection,
  PhotosSection,
  ElementsSection,
  CustomImages,
  BackgroundSection,
  SizeSection,
];
