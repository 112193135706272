/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Ids, Tabs, TabsStates } from './types';

const initialState: TabsStates = {
  activeTab: 'home',
  data: [],
};

const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    clearState: () => initialState,
    updateTabs: (state, action: PayloadAction<Array<Tabs>>) => {
      localStorage.setItem('leeg:tabs', JSON.stringify(action.payload));
      state.data = action.payload;
      return state;
    },
    updateActiveTab: (state, action: PayloadAction<Ids>) => {
      localStorage.setItem('leeg:activeTab', action.payload);
      state.activeTab = action.payload;
      return state;
    },
  },
});

export const tabsReducer = tabsSlice.reducer;

export const tabsActions = tabsSlice.actions;
