import { makeStyles, createStyles, Theme, Avatar } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { commonConstants } from 'styles/common';

export const useUserSectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    adminCrown: {
      marginRight: theme.spacing(1),
    },
}));

export const UserAvatar = styled(Avatar)<Theme>(() => ({
  height: 30,
  width: 30,
  fontSize: commonConstants.font.size.lg,
}));
