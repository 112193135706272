import React, { useState } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Typography,
  Box,
  Button,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { RiCloseLine } from 'react-icons/ri';
import { useModalStyles } from 'styles/shared/Modal';

import { deleteSubscription } from 'services/api-leeg/modules/plans';
import { useAuthentication } from 'hooks/useAuthentication';
import { ModalCancelPlanProps } from './types';

export const ModalCancelPlan = ({
  open,
  handleClose,
}: ModalCancelPlanProps) => {
  const classes = useModalStyles();

  const { user, getUser } = useAuthentication();

  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    await deleteSubscription(user?._id as string);
    await getUser();
    handleClose();
    setLoading(false);
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <IconButton
            className={classes.closeButton}
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <RiCloseLine size={16} />
          </IconButton>
          <Typography className={classes.title}>
            Cancelar plano
          </Typography>
          <Typography className={classes.description}>
            Você tem certeza que deseja cancelar seu plano atual?
          </Typography>
          <Box className={classes.actions}>
            <Button
              className={classes.buttons}
              disableElevation
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={handleDelete}
            >
              Confirmar
              {loading && (
                <CircularProgress
                  size={14}
                  className={classes.loadingProgress}
                />
              )}
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
