import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentCategory } from 'services/api-leeg/modules/documentCategories/types';
import { AdminDocumentCategories } from './types';

// Initial states
const initialState: AdminDocumentCategories = {
  data: [],
  isLoading: false,
};

// Slicer
const adminDocumentCategoriesSlice = createSlice({
  name: 'adminDocumentCategories',
  initialState,
  reducers: {
    clearState: () => initialState,
    updateAdminDocumentCategories: (state, action: PayloadAction<Array<DocumentCategory>>) => {
      state.data = action.payload;

      return state;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      return state;
    },
  },
});

// Reduces & Actions
export const adminDocumentCategoriesReducer = adminDocumentCategoriesSlice.reducer;
export const adminDocumentCategoriesActions = adminDocumentCategoriesSlice.actions;
