import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

export const useEmptyStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: theme.spacing(3, 2),
    },
    illustration: {
      alignSelf: 'center',
    },
    title: {
      color: Colors.GRAY_500,
      fontWeight: commonConstants.font.weight.regular,
      fontSize: commonConstants.font.size.lg,
      textAlign: 'center',
      marginTop: theme.spacing(3),
    },
}));
