import React from 'react';
import { useAuthentication } from 'hooks/useAuthentication';

import SignedIn from './SignedIn';
import SignIn from './SignIn';

export default function Routes() {
  const { isSigned, rehydrateLoading, user } = useAuthentication();

  if (!rehydrateLoading) {
    if (isSigned && user) {
      return <SignedIn />;
    }

    if (!isSigned) {
      return <SignIn />;
    }
  }

  return null;
}
