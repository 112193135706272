import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

export const useForgotPasswordStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperContainer: {
      width: 250,
      padding: theme.spacing(3.5, 3),
      borderRadius: commonConstants.borders.radius.sm,
      zIndex: 2,
      position: 'relative',
    },
    label: {
      color: Colors.GRAY_600,
      padding: '0.5rem 0',
      fontSize: commonConstants.font.size.sm,
    },
    input: {
      marginBottom: theme.spacing(0.8),
    },
    title: {
      fontSize: '1rem',
      paddingTop: theme.spacing(2),
      fontWeight: commonConstants.font.weight.bold,
      fontFamily: commonConstants.font.family.title,
    },
    recoveryButton: {
      height: 35,
      fontWeight: commonConstants.font.weight.semiBold,
      marginTop: 50,
      fontSize: commonConstants.font.size.lg,
    },
    backToLogin: {
      fontSize: commonConstants.font.size.md,
      position: 'absolute',
      top: 20,
      right: 30,
      display: 'flex',
    },
    backToLoginButton: {
      fontSize: commonConstants.font.size.md,
      fontWeight: commonConstants.font.weight.bold,
      marginLeft: 4,
      transition: theme.transitions.create(['opacity'], {
        duration: 200,
      }),
      '&:hover': {
        opacity: 0.7,
        cursor: 'pointer',
      },
    },
  }),
);
