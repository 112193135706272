import React, { createContext, useEffect } from 'react';
import { useAuthentication } from 'hooks/useAuthentication';

import { useAppSelector } from 'hooks/store/useAppSelector';
import { useAppDispatch } from 'hooks/store/useAppDispatch';
import { getActualContentDocuments } from 'store/slices/documents/sideEffects';
import { getFolders as thunkGetFolders } from 'store/slices/folders/sideEffects';
import { DashboardProviderProps } from './types';

export const DashboardContext = createContext(null);

export function DashboardProvider({ children }: DashboardProviderProps) {
  // Contexts hooks
  const { user } = useAuthentication();

  // Redux
  const dispatch = useAppDispatch();
  const actualContent = useAppSelector(state => state.actualContent);

  // Effects
  useEffect(() => {
    dispatch(getActualContentDocuments(actualContent));
  }, [actualContent, dispatch]);

  useEffect(() => {
    if (user?.rootFolder) {
      dispatch(thunkGetFolders(user?.rootFolder._id as string));
    }
  }, [user, dispatch]);

  return (
    <DashboardContext.Provider value={null}>
      {children}
    </DashboardContext.Provider>
  );
}
