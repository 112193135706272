import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

export const useContentListStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerCell: {
      fontSize: commonConstants.font.size.xl,
      fontWeight: commonConstants.font.weight.bold,
      color: Colors.GRAY_800,
    },
    bodyCell: {
      fontSize: commonConstants.font.size.lg,
      width: '30% ',
      paddingRight: theme.spacing(2),
    },
  }),
);
