import { Box, Button, Theme } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

type StyleProperties = {
  active?: boolean;
};

export const Container = styled(Box)<Theme>(({ theme }) => ({
  height: '100%',
  width: 270,
  background: Colors.WHITE,
  paddingTop: theme.spacing(3.5),
}));

export const ContentButton = styled(Button)<Theme, StyleProperties>(
  ({ active, theme }) => ({
    width: '100%',
    textAlign: 'left',
    fontSize: commonConstants.font.size.lg,
    fontWeight: active
      ? commonConstants.font.weight.bold
      : commonConstants.font.weight.regular,
    padding: theme.spacing(1.2, 2),
    backgroundColor: active ? Colors.GRAY_100 : Colors.WHITE,
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
    '& .MuiButton-startIcon': {
      paddingRight: theme.spacing(1.6),
    },
  }),
);
