import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

// TODO: USE A PAPER DEFAULT AND CSX THE VARIATIONS
export const useModalStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      width: 409,
      height: 164,
      position: 'relative',
      backgroundColor: Colors.WHITE,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 3),
      borderRadius: commonConstants.borders.radius.lg,
    },
    paperTemplate: {
      width: 359,
      height: 264,
      position: 'relative',
      backgroundColor: Colors.WHITE,
      padding: '20px 30px 0',
    },
    paperUser: {
      width: 359,
      height: 324,
      position: 'relative',
      backgroundColor: Colors.WHITE,
      padding: '20px 30px 0',
    },
    paperPayments: {
      width: 659,
      height: 464,
      position: 'relative',
      backgroundColor: Colors.WHITE,
      padding: '20px 30px 0',
    },
    paperUserUpdate: {
      width: 359,
      height: 254,
      position: 'relative',
      backgroundColor: Colors.WHITE,
      padding: '20px 30px 0',
    },
    paperPlanEdit: {
      width: 359,
      height: 694,
      position: 'relative',
      backgroundColor: Colors.WHITE,
      padding: '20px 30px 0',
    },
    folders: {
      minWidth: '700px',
      height: '40%',
    },
    templates: {
      minWidth: '920px',
      height: '70%',
    },
    titleContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: 50,
    },
    templateButton: {
      fontFamily: commonConstants.font.family.body,
      fontWeight: commonConstants.font.weight.semiBold,
      fontSize: commonConstants.font.size.xl,
    },
    title: {
      fontFamily: commonConstants.font.family.title,
      fontWeight: commonConstants.font.weight.bold,
      color: Colors.GRAY_800,
    },
    description: {
      fontSize: commonConstants.font.size.lg,
      fontWeight: commonConstants.font.weight.regular,
      color: Colors.GRAY_500,
      marginTop: theme.spacing(1.5),
    },
    checkboxLabel: {
      fontSize: commonConstants.font.size.lg,
      fontWeight: commonConstants.font.weight.regular,
      color: Colors.GRAY_500,
    },
    actions: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(3),
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttons: {
      fontSize: commonConstants.font.size.lg,
      fontWeight: commonConstants.font.weight.semiBold,
      '& + button': {
        marginLeft: theme.spacing(3),
      },
    },
    bold: {
      fontSize: commonConstants.font.size.lg,
      fontWeight: commonConstants.font.weight.bold,
      color: Colors.GRAY_500,
      marginLeft: theme.spacing(0.5),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(3),
      top: theme.spacing(2),
    },
    label: {
      fontSize: commonConstants.font.size.lg,
      color: Colors.GRAY_500,
      padding: theme.spacing(1, 0, 0.5),
    },
    input: {
      width: '100%',
      fontSize: commonConstants.font.size.lg,
    },
    loadingProgress: { color: Colors.WHITE, marginLeft: 10 },
  }),
);
