import axios from 'axios';
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from 'helpers/LocalStorage';
import {
  storageKeyAccessToken,
  storageKeyRefreshToken,
} from 'types/dtos/user.dto';
import { PromiseQueue } from './types';

const clientApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

let isRefreshing = false;
let failedQueue: Array<PromiseQueue> = [];

const processQueue = (error: unknown, token = null) => {
  failedQueue.forEach(promise => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });

  failedQueue = [];
};

clientApi.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;

    const refreshToken = getLocalStorageValue({ key: storageKeyRefreshToken });
    if (!refreshToken) return undefined;

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          })
          .catch(err => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        clientApi
          .post('/auth/refresh', { refresh_token: refreshToken })
          .then(({ data }) => {
            setLocalStorageValue({
              key: storageKeyAccessToken,
              value: data.accessToken,
            });
            axios.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
            originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
            processQueue(null, data.accessToken);
            resolve(clientApi(originalRequest));
          })
          .catch(err => {
            if (err.response.status !== (200 || 201)) {
              localStorage.clear();
            }
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  },
);

export default clientApi;
