import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

export const useSignInStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperContainer: {
      width: 250,
      padding: theme.spacing(3.5, 3),
      borderRadius: commonConstants.borders.radius.sm,
      zIndex: 2,
    },
    label: {
      color: Colors.GRAY_600,
      padding: '0.5rem 0',
      fontSize: commonConstants.font.size.sm,
    },
    input: {
      marginBottom: '8px',
    },
    title: {
      fontSize: commonConstants.font.size.xl,
      fontWeight: commonConstants.font.weight.bold,
      fontFamily: commonConstants.font.family.title,
    },
    subtitle: {
      fontSize: commonConstants.font.size.md,
    },
    loginButton: {
      height: 35,
      fontWeight: commonConstants.font.weight.semiBold,
      marginTop: 16,
      fontSize: commonConstants.font.size.lg,
    },
    forgotPassword: {
      color: Colors.DARK_BLUE,
      fontSize: commonConstants.font.size.sm,
      fontWeight: commonConstants.font.weight.bold,
      paddingTop: 10,
      transition: theme.transitions.create(['opacity'], {
        duration: 200,
      }),
      '&:hover': {
        opacity: 0.7,
        cursor: 'pointer',
      },
    },
}));
