import React from 'react';

import { Workspace } from 'polotno/canvas/workspace';
import { createStore } from 'polotno/model/store';
import { PreviewContentProps } from './types';
import { Container } from './styles';

export const PreviewContent = ({ html }: PreviewContentProps) => {
  const store = createStore({
    key: process.env.REACT_APP_POLOTNO_KEY as string,
    showCredit: false,
  });

  store.loadJSON(JSON.parse(html));

  return (
    <Container>
      <Workspace
        store={store}
        backgroundColor="#fff"
        activePageBorderColor="#9999"
        pageBorderColor="#9999"
        pageControlsEnabled={false}
      />
    </Container>
  );
};
