/* eslint-disable no-continue */
import { SIX_DAYS } from 'constants/magicTime';
import { parseISO, isToday, isAfter, isBefore } from 'date-fns';
import { Document } from 'services/api-leeg/modules/documents/types';

export const parseDocumentSections = (documents: Array<Document>) => {
  const sixDaysBefore = new Date(Date.now() - SIX_DAYS);

  const createdToday: Array<Document> = [];
  const thisWeek: Array<Document> = [];
  const others: Array<Document> = [];

  for (let index = 0; index < documents.length; index += 1) {
    const document = documents[index];

    if (isToday(parseISO(document.createdAt))) {
      createdToday.push(document); continue;
    } else if (isAfter(parseISO(document.createdAt), sixDaysBefore)) {
      thisWeek.push(document); continue;
    } else if (isBefore(parseISO(document.createdAt), sixDaysBefore)) {
      others.push(document); continue;
    }
  }

  return {
    createdToday,
    thisWeek,
    others,
  };
};
