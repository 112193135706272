export const Colors = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  ORANGE: '#FF5B28',
  DARK_BLUE: '#202B61',
  LIGHT_BLUE: '#525FFB',
  LIGHT_GRAY: '#CED4DA',
  OPAQUE_GRAY: '#a19a9c66',
  GRAY_800: '#212529',
  GRAY_600: '#41474D',
  GRAY_500: '#6B7785',
  GRAY_400: '#868E96',
  GRAY_300: '#3C3C3B',
  GRAY_200: '#ADB5BD',
  GRAY_150: '#D2D2D2',
  GRAY_100: '#F2F2F2',
  GRAY_50: '#333333',
  BACKGROUND_BLUE_GRADIENT: 'linear-gradient(107.7deg, #525FFB -2.44%, #202B61 102.36%)',
};

export default Colors;
