import clientApi from '../../../client';
import { UserAdminData, UserAdminUpdateData, UserAdminCreateData } from './types';

export const createAdminUser = async (
  userData: UserAdminCreateData,
  ): Promise<UserAdminData> => {
  const { data: adminUser } = await clientApi.post<UserAdminData>('users/admins', userData);

  return adminUser;
};

export const updateAdminUser = async (
  userData: Partial<UserAdminUpdateData>,
  ): Promise<UserAdminData> => {
  const { data: adminUser } = await clientApi.patch<UserAdminData>('users/admins', userData);

  return adminUser;
};

export const getAllAdminUsers = async (): Promise<Array<UserAdminData>> => {
  const { data: adminUsers } = await clientApi.get<Array<UserAdminData>>('users/admins');

  return adminUsers;
};
