import { makeStyles, createStyles, Button, Theme } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

type StyleProperties = {
  active?: boolean;
};

export const useNavBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3.5, 0),
      width: 390,
      display: 'inline-block',
      background: Colors.WHITE,
    },
    divider: {
      margin: theme.spacing(2, 0),
      backgroundColor: Colors.OPAQUE_GRAY,
    },
    foldersHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(1, 1.5, 1, 2.5),
      marginBottom: theme.spacing(1),
    },
    folderItem: {
      padding: theme.spacing(0.5, 1),
      transition: theme.transitions.create(['background-color'], {
        duration: 400,
      }),
      '&:hover': {
        backgroundColor: Colors.GRAY_100,
      },
    },
    folderList: {
      maxHeight: '40vh',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    contentIcon: {
      marginRight: theme.spacing(2),
    },
    folderHeaderTitle: {
      fontSize: commonConstants.font.size.xl,
      fontWeight: commonConstants.font.weight.bold,
      fontFamily: commonConstants.font.family.title,
    },
    foldersFiltersButton: {
      fontSize: commonConstants.font.size.md,
      padding: 0,
    },
    foldersFooter: {
      width: '100%',
      fontSize: commonConstants.font.size.lg,
    },
  }),
);

export const ContentButton = styled(Button)<Theme, StyleProperties>(
  ({ active, theme }) => ({
    width: '100%',
    fontSize: commonConstants.font.size.lg,
    fontWeight: active
      ? commonConstants.font.weight.bold
      : commonConstants.font.weight.regular,
    padding: theme.spacing(1.3, 2.2),
    backgroundColor: active ? Colors.GRAY_100 : Colors.WHITE,
    justifyContent: 'flex-start',
  }),
);

export const FolderButton = styled(Button)<Theme, StyleProperties>(
  ({ active, theme }) => ({
      width: '80%',
      textAlign: 'left',
      marginLeft: theme.spacing(0.8),
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& .MuiTypography-root': {
        fontWeight: active ? 'bold' : 'normal',
      },
  }),
);
