import axios from 'axios';
import { UserDto } from 'types/dtos/user.dto';
import clientApi from '../../../client';
import { SignInCredentials, SignUpCredentials } from './types';

export const login = async ({
  email,
  password,
}: SignInCredentials): Promise<UserDto> => {
  const { data: user } = await clientApi.post<UserDto>(
    '/auth/login',
    {
      email,
      password,
    },
    { headers: null },
  );

  return user;
};

export const signUp = async ({
  name,
  email,
  password,
}: SignUpCredentials): Promise<UserDto> => {
    const { data } = await axios.post<UserDto>(`${process.env.REACT_APP_BASE_URL}/auth`, {
      name,
      email,
      password,
    });

    return data;
};

export const forgotPassword = async (email: string) => {
  const response = await clientApi.get(`/auth/forgot-password?email=${email}`);

  return response.data;
};

export const recoverPassword = async (token: string, password: string) => {
  const response = await clientApi.post(`/auth/forgot-password/new/${token}`, {
    password,
  });

  return response.data;
};
