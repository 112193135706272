import React from 'react';

import { DashboardProvider } from 'context/Dashboard';
import { useAuthentication } from 'hooks/useAuthentication';
import { AdminRoutes } from './admin';
import { ClientRoutes } from './client';

export default function SignedIn() {
  const { user } = useAuthentication();

  return (
    <DashboardProvider>
      {user?.roles.find(role => role === 'admin') ? <AdminRoutes /> : <ClientRoutes />}
    </DashboardProvider>
  );
}
