/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import {
  Grid,
  Card,
  Box,
  Typography,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import { RiMore2Fill } from 'react-icons/ri';
import { Anchor, ModalSelectedItem } from 'types/shared';
import { MiniPreviewContent } from 'components/MiniPreviewContent';
import { Document } from 'services/api-leeg/modules/documents/types';
import { useTabs } from 'hooks/useTabs';
import { useGridItemStyles } from './styles';
import { GridItemProps } from './types';
import { MenuDocument } from './MenuDocument';

export default function GridItem({ document }: GridItemProps) {
  // Styles
  const classes = useGridItemStyles();

  const tabs = useTabs();

  // States
  const [anchorMenuDocument, setAnchorMenuDocument] = useState<Anchor>(null);
  const [selectedItem, setSelectedItem] = useState<ModalSelectedItem>(
    {} as ModalSelectedItem,
  );
  const [selectedDocument, setSelectedDocument] = useState({} as Document);

  // Callbacks
  const handleOpenMenuDocument = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setSelectedItem({
      id: document._id,
      name: document.name,
      isDocument: true,
    });
    setSelectedDocument(document);
    setAnchorMenuDocument(event.currentTarget);
  };

  const handleVisualizeDocument = async () => {
    tabs.openDocument(document._id, document.name);
  };

  const handleCloseMenuDocument = () => setAnchorMenuDocument(null);

  return (
    <Grid
      item
      xs={3}
      sm={3}
      className={classes.item}
      onDoubleClick={handleVisualizeDocument}
    >
      <Card elevation={0} className={classes.container}>
        <Box className={classes.previewContainer}>
          <MiniPreviewContent html={document.preview} />
        </Box>
        <Box className={classes.footer}>
          <Box className={classes.infoContainer}>
            <Tooltip title={document.name}>
              <Typography className={classes.name}>{document.name}</Typography>
            </Tooltip>
          </Box>
          <IconButton
            className={classes.icon}
            color="inherit"
            onClick={handleOpenMenuDocument}
          >
            <RiMore2Fill size={16} />
          </IconButton>
        </Box>
      </Card>
      <MenuDocument
        anchor={anchorMenuDocument}
        handleClose={handleCloseMenuDocument}
        selectedItem={selectedItem}
        document={selectedDocument}
      />
    </Grid>
  );
}
