import { makeStyles, createStyles } from '@material-ui/core';

export const useMiniPreviewContentStyles = makeStyles(() =>
  createStyles({
    previewSheet: {
      display: 'inline-flex',
      backgroundColor: '#fff',
      width: 160,
      height: '85%',
      borderRadius: 3,
      overflow: 'hidden',
      '-webkit-touch-callout': 'none',
      '-webkit-user-select': 'none',
      '-khtml-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
      'user-select': 'none',
    },
    previewContent: {
        transform: 'scale(0.3) translate(-100px, -100px)',
        width: 100,
    },
    previewContentSkelleton: {
        transform: 'scale(0.8)',
        // width: 100,
    },
}));
