import React, { useState } from 'react';
import { Menu } from '@material-ui/core';

import { ModalDelete } from 'components/ModalDelete';
import { ModalField } from 'components/ModalField [DEPRECATED]';
import { MenuItem } from 'styles/shared/Menu';
import { useAppDispatch } from 'hooks/store/useAppDispatch';
import { recoverDocument as thunkRecoverDocument, deleteDocument as thunkDeleteDocument } from 'store/slices/documents/sideEffects';

import { deleteFolder as thunkDeleteFolder } from 'store/slices/folders/sideEffects';
import { useAppSelector } from 'hooks/store/useAppSelector';
import { tabsActions } from 'store/slices/tabs';
import { useHistory } from 'react-router-dom';
import { MenuFiltersProps } from './types';
import { useMenuDocumentStyles } from './styles';

export function MenuDocument({
  anchor,
  handleClose,
  selectedItem,
  document,
}: MenuFiltersProps) {
  const classes = useMenuDocumentStyles();
  const history = useHistory();

  /**
   * Redux
   */
  const dispatch = useAppDispatch();
  const { data: tabs } = useAppSelector(state => state.tabs);

  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
  const [modalFieldIsOpen, setModalFieldIsOpen] = useState(false);

  const handleOpenRenameDocument = () => {
    handleClose();
    setModalFieldIsOpen(true);
  };

  const handleCloseRenameDocument = () => {
    handleClose();
    setModalFieldIsOpen(false);
  };

  const handleOpenDeleteDocument = () => {
    handleClose();
    setModalDeleteIsOpen(true);
  };

  const handleCloseDeleteDocument = () => {
    handleClose();
    setModalDeleteIsOpen(false);
  };

  const handleRecoverDocument = async () => {
    await dispatch(thunkRecoverDocument(selectedItem.id));
  };

  const handleVisualizeDocument = async () => {
    const tab = tabs.find(previousTabs => previousTabs.id === selectedItem.id);
    if (tab) {
      dispatch(tabsActions.updateActiveTab(selectedItem.id));
    } else {
      dispatch(
        tabsActions.updateTabs([
          ...tabs,
          { id: selectedItem.id, name: selectedItem.name },
        ]),
      );
      dispatch(tabsActions.updateActiveTab(selectedItem.id));
    }
    history.push(`/editor/${document?._id}`);
    handleClose();
  };

  const anchorOrigin = { horizontal: 0, vertical: 0 };

  return (
    <>
      <Menu
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={handleClose}
        className={classes.container}
        elevation={1}
        anchorOrigin={anchorOrigin}
      >
        {document?.isDeleted ? (
          <MenuItem onClick={handleRecoverDocument}>Restaurar</MenuItem>
        ) : (
          <>
            <MenuItem onClick={handleOpenRenameDocument}>Renomear</MenuItem>
            <MenuItem onClick={handleVisualizeDocument}>Visualizar</MenuItem>
            <MenuItem onClick={handleOpenDeleteDocument}>Excluir</MenuItem>
          </>
        )}
      </Menu>

      <ModalDelete
        open={modalDeleteIsOpen}
        handleClose={handleCloseDeleteDocument}
        selectedItem={selectedItem}
        type={selectedItem.isDocument ? ' documento' : ' pasta'}
        handleOk={() => {
           if (selectedItem.isDocument) {
            dispatch(thunkDeleteDocument(selectedItem.id));
          } else {
            dispatch(thunkDeleteFolder(selectedItem.id));
          }
        }}
      />
      <ModalField
        type="rename"
        open={modalFieldIsOpen}
        handleClose={handleCloseRenameDocument}
        selectedItem={selectedItem}
      />
    </>
  );
}
