/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Typography,
  Box,
  Button,
  IconButton,
  CircularProgress,
  Grid,
  FormControlLabel,
} from '@material-ui/core';
import { RiCloseLine } from 'react-icons/ri';
import { useModalStyles } from 'styles/shared/Modal';

import Checkbox from '@material-ui/core/Checkbox';
import { getPlans } from 'services/api-leeg/modules/plans';
import { Plan } from 'services/api-leeg/modules/plans/types';
import Colors from 'styles/colors';
import { updateDocumentPlans } from 'services/api-leeg/modules/documents';
import { toast } from 'react-toastify';
import { ModalAddPlanToTemplateProps } from './types';

export const ModalAddPlanToTemplate = ({
  open,
  onClose,
  selectedTemplate,
}: ModalAddPlanToTemplateProps) => {
  // Style hooks
  const classes = useModalStyles();

  // States
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState<Array<Plan>>([]);
  const [plansChecks, setPlansCheck] = React.useState<any>({});
  const [selectedPlans, setSelectedPlans] = useState<Array<string>>([]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await updateDocumentPlans(selectedTemplate._id, selectedPlans);
      toast.success('Modelo restringido com sucesso.');
      onClose();
    } catch (error) {
      toast.error('Não foi possível restringir a modelo.');
    } finally {
      setLoading(false);
    }
  };

  const getActualPlans = async () => {
    const plansResponse = await getPlans();

    setPlans(plansResponse);
  };

  const handleChange = (event: any) => {
    delete plansChecks[event.target.value];
    setSelectedPlans(prev => prev.filter(selectedPlanId => selectedPlanId !== event.target.value));
    if (event.target.checked) {
      setPlansCheck({ ...plansChecks, [event.target.value]: event.target.checked });
      setSelectedPlans(prev => [...prev, event.target.value]);
    }
  };

  useEffect(() => {
    getActualPlans();
    setSelectedPlans(selectedTemplate.allowedPlansIds);
  }, [selectedTemplate]);

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Paper className={classes.paperUser}>
          <IconButton
            className={classes.closeButton}
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <RiCloseLine size={16} />
          </IconButton>
          <Typography className={classes.title}>
            Restringir para os modelos
          </Typography>
          <Typography className={classes.description}>
            Selecione os planos para os quais você quer permitir o modelo.
          </Typography>
          {!(selectedTemplate?.allowedPlansIds?.length > 0) && (
          <Typography style={{ color: Colors.LIGHT_BLUE }} className={classes.description}>
            Modelo disponível para todos.
          </Typography>
)}

          <form onSubmit={onSubmit}>
            <Grid container xs={12} style={{ marginTop: 20 }}>
              {plans.map(plan => (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        defaultChecked={!!selectedTemplate?.allowedPlansIds?.find(allowedPlan => allowedPlan === plan._id)}
                        checked={plansChecks[plan._id]}
                        value={plan._id}
                        onChange={handleChange}
                        name={plan.name}
                      />
                   )}
                    label={(
                      <Typography className={classes.checkboxLabel}>
                        {plan.name}
                      </Typography>
                  )}
                  />
                </Grid>
                ))}
            </Grid>
            <Box className={classes.actions}>
              <Button className={classes.buttons} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                className={classes.buttons}
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                Confirmar
                {loading && (
                  <CircularProgress
                    size={14}
                    className={classes.loadingProgress}
                  />
                )}
              </Button>
            </Box>
          </form>
        </Paper>
      </Fade>
    </Modal>
  );
};
