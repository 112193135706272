import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

export const useGridStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      padding: theme.spacing(2, 0),
    },
    sectionHeader: {
      fontSize: commonConstants.font.size.lg,
      fontWeight: commonConstants.font.weight.semiBold,
      color: Colors.GRAY_500,
      paddingBottom: 10,
    },
    gridContainer: {
    [theme.breakpoints.up('md')]: {
      height: '45%',
    },
    [theme.breakpoints.up('lg')]: {
      height: '50%',
    },
    [theme.breakpoints.up('xl')]: {
      height: '65%',
    },
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 6px ${Colors.WHITE}`,
      webkitBoxShadow: `inset 0 0 6px ${Colors.WHITE}`,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius: 2,
      outline: `1px solid ${Colors.WHITE}`,
    },
  },
  }),
);
