import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Button,
  Typography,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  CircularProgress,
} from '@material-ui/core';

import { InitialBackgroundWrapper } from 'components/InitialBackgroundWrapper';

import { ReactComponent as EyeClosed } from 'assets/icons/eye-close.svg';
import { ReactComponent as EyeOpened } from 'assets/icons/open-eye.svg';

// import OAuthSection from 'components/OAuthSection [DEPRECATED]';
import { useCommonStyles } from 'styles/common';
import { useAuthentication } from 'hooks/useAuthentication';
import { SignInCredentials } from 'services/api-leeg/modules/auth/types';
import Colors from 'styles/colors';
import { useHistory } from 'react-router';
import SignUpSection from './components/SignUpSection';
import { useSignInStyles } from './styles';

const schema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
});

export function Login() {
  const history = useHistory();
  const classes = useSignInStyles();
  const commonStyles = useCommonStyles();

  const { loadingSignIn, signIn } = useAuthentication();

  const [showPassword, setShowPassword] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SignInCredentials>({
    resolver: yupResolver(schema),
  });

  const onSubmit = ({ email, password }: SignInCredentials) => {
    signIn({ email, password });
  };

  const handleClickShowPassword = () => {
    setShowPassword(previousShowPassword => !previousShowPassword);
  };

  const handleGoToForgotPassword = () => {
    history.push('/forgot-password');
  };

  const endAdornmentToggleShowPassword = () => (
    <InputAdornment position="end">
      <IconButton onClick={() => handleClickShowPassword()} edge="end">
        {showPassword ? (
          <EyeOpened className={commonStyles.eyeIcon} />
        ) : (
          <EyeClosed className={commonStyles.eyeIcon} />
        )}
      </IconButton>
    </InputAdornment>
  );

  // const checkboxControl = (
  //   <Checkbox
  //     checked={rememberPassword}
  //     style={{ marginRight: -10 }}
  //     color="primary"
  //     onChange={() => setRememberPassword(prev => !prev)}
  //   />
  // );

  return (
    <>
      <Helmet>
        <title>Leeg | Entrar</title>
      </Helmet>

      <InitialBackgroundWrapper>
        <Paper elevation={4} className={classes.paperContainer}>
          <Typography className={classes.title}>Seja bem vindo(a)</Typography>
          <Typography className={classes.subtitle}>
            Faça login para acessar sua conta
          </Typography>

          <form
            className={commonStyles.authForm}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container direction="column">
              <InputLabel className={classes.label} htmlFor="email">
                Email
              </InputLabel>

              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    autoFocus
                    id="email"
                    error={Boolean(errors.email)}
                    className={classes.input}
                    type="email"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                  />
                )}
              />

              <InputLabel className={classes.label} htmlFor="password">
                Senha
              </InputLabel>

              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <OutlinedInput
                    id="password"
                    error={Boolean(errors.password)}
                    type={showPassword ? 'text' : 'password'}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    endAdornment={endAdornmentToggleShowPassword()}
                  />
                )}
              />
              <Grid container direction="row" justifyContent="flex-end">
                {/* <FormControlLabel
                  className={classes.rememberPassword}
                  control={checkboxControl}
                  label={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Typography
                      variant="body1"
                      className={classes.rememberPasswordText}
                    >
                      Lembrar minha senha
                    </Typography>
                  }
                /> */}
                <Typography
                  onClick={handleGoToForgotPassword}
                  className={classes.forgotPassword}
                >
                  Esqueceu a senha?
                </Typography>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loadingSignIn}
                className={classes.loginButton}
                data-sitekey="6Lfh-k0eAAAAAMHlnUGQPPtVwuuJSNTWT54JW6ih"
                data-callback="onSubmit"
                data-action="submit"
              >
                {loadingSignIn ? (
                  <CircularProgress size={15} style={{ color: Colors.WHITE }} />
                ) : (
                  'Login'
                )}

              </Button>
              <SignUpSection />
              {/* <OAuthSection /> */}
            </Grid>
          </form>
        </Paper>
      </InitialBackgroundWrapper>
    </>
  );
}
