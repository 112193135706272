import { toast } from 'react-toastify';

export const loadJSONFile = (file: Blob, store: { loadJSON: (arg0: any) => void; }) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    const text = reader.result || '{}';
    let json;
    try {
      json = JSON.parse(text as any);
    } catch (e) {
      toast.error('Não foi possível carregar o arquivo.');
    }

    if (json) {
      store.loadJSON(json);
    }
  };
  reader.onerror = () => {
    toast.error('Não foi possível carregar o arquivo.');
  };
  reader.readAsText(file);
};

export const loadImageFile = (
  file: Blob,
  store: {
    width: number;
    height: number;
    activePage: {
      addElement: (arg0: {
         type: string; width: number; height: number; src: string | ArrayBuffer | null;
      }) => void;
    }; }) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    const url = reader.result;
    const img = new Image();
    img.src = url as any;
    img.onload = () => {
      const scale = Math.min(
        1,
        store.width / img.width,
        store.height / img.height,
      );
      store.activePage.addElement({
        type: 'image',
        width: img.width * scale,
        height: img.height * scale,
        src: url,
      });
    };
  };
  reader.onerror = () => {
    toast.error('Não foi possivel carregar a imagem.');
  };
  reader.readAsDataURL(file);
};

export const loadFile = (file: Blob, store: any) => {
  if (file.type.indexOf('image') >= 0) {
    loadImageFile(file, store);
  } else {
    loadJSONFile(file, store);
  }
};
