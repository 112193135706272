import { makeStyles, createStyles } from '@material-ui/core';

export const PREVIEW_HEIGHT = '67%';
export const FOOTER_HEIGHT = '33%';

export const commonConstants = {
  font: {
    size: {
      sm: '0.6rem',
      md: '0.7rem',
      lg: '0.8rem',
      xl: '0.9rem',
      xxl: '1rem',
      xxxl: '1.5rem',
    },
    family: {
      title: 'Montserrat',
      body: 'Inter',
    },
    weight: {
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
  },
  borders: {
     radius: {
         sm: 6.5,
         md: 8,
         lg: 10,
         xl: 40,
         xxl: 50,
     },
  },
};

export const useCommonStyles = makeStyles(() =>
  createStyles({
    eyeIcon: {
        height: 20,
    },
    authForm: {
      marginTop: 10,
    },
    masonryImage: {
      width: '100%',
      '&:hover': {
        cursor: 'pointer',
        transform: 'scale(1.06)',
      },
    },
  }));
