import React, { useState } from 'react';
import { Menu } from '@material-ui/core';

import { deleteDocument as thunkDeleteDocument } from 'store/slices/documents/sideEffects';
import { deleteFolder as thunkDeleteFolder } from 'store/slices/folders/sideEffects';

import { ModalDelete } from 'components/ModalDelete';
import { ModalField } from 'components/ModalField [DEPRECATED]';
import { MenuItem, useMenuStyles } from 'styles/shared/Menu';
import { useAppDispatch } from 'hooks/store/useAppDispatch';
import { MenuFolderProps } from './types';

export function MenuFolder({
  selectedItem,
  anchor,
  handleClose,
}: MenuFolderProps) {
  // Styles
  const classes = useMenuStyles();

  // States
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
  const [modalFieldIsOpen, setModalFieldIsOpen] = useState(false);

  // Redux
  const dispatch = useAppDispatch();

  // Callbacks
  const handleOpenDeleteDocument = () => {
    handleClose();
    setModalDeleteIsOpen(true);
  };
  const handleCloseDeleteDocument = () => {
    handleClose();
    setModalDeleteIsOpen(false);
  };

  const handleOpenRenameDocument = () => {
    handleClose();
    setModalFieldIsOpen(true);
  };
  const handleCloseRenameDocument = () => {
    handleClose();
    setModalFieldIsOpen(false);
  };

  const anchorOrigin = { horizontal: 0, vertical: 0 };

  return (
    <>
      <Menu
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={handleClose}
        className={classes.container}
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={anchorOrigin}
      >
        <MenuItem onClick={handleOpenRenameDocument}>Renomear</MenuItem>
        <MenuItem onClick={handleOpenDeleteDocument}>Excluir</MenuItem>
      </Menu>
      <ModalDelete
        open={modalDeleteIsOpen}
        handleClose={handleCloseDeleteDocument}
        selectedItem={selectedItem}
        type={selectedItem.isDocument ? ' documento' : ' pasta'}
        handleOk={() => {
           if (selectedItem.isDocument) {
            dispatch(thunkDeleteDocument(selectedItem.id));
          } else {
            dispatch(thunkDeleteFolder(selectedItem.id));
          }
        }}
      />
      <ModalField
        type="rename"
        open={modalFieldIsOpen}
        handleClose={handleCloseRenameDocument}
        selectedItem={selectedItem}
      />
    </>
  );
}
