import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Login } from 'pages/SignIn/Login';
import { SignUp } from 'pages/SignIn/SignUp';
import { ForgotPassword } from 'pages/SignIn/ForgotPassword';
import { RecoveryPassword } from 'pages/SignIn/RecoveryPassword';

export default function SignIn() {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route
        exact
        path="/recover-password/:token"
        component={RecoveryPassword}
      />
      <Route path="*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );
}
