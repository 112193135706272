import React from 'react';
import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';
import {
  Container,
  HeaderResultSection,
  ItemResultSection,
  ResultSection,
  Content,
} from './styles';

export const ResultsSkelleton = () => (
  <Container open>
    <Content open>
      <ResultSection>
        <HeaderResultSection>
          <Skeleton width="100px" />
        </HeaderResultSection>
        <ItemResultSection>
          <Skeleton width="300px" />
        </ItemResultSection>
        <ItemResultSection>
          <Skeleton width="300px" />
        </ItemResultSection>
      </ResultSection>
    </Content>
  </Container>
);
