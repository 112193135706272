import { Box, Divider, Grid, IconButton } from '@material-ui/core';
import { ModalDelete } from 'components/ModalDelete';
import { useAppDispatch } from 'hooks/store/useAppDispatch';
import { useAppSelector } from 'hooks/store/useAppSelector';
import { useAuthentication } from 'hooks/useAuthentication';
import React, { useEffect, useState } from 'react';
import { RiDeleteBinLine, RiEdit2Line } from 'react-icons/ri';
import { UserAdminData } from 'services/api-leeg/modules/adminUsers/types';

import { getAllAdminUsers as thunkGetAllAdminUsers, deleteAdmin as thunkDeleteAdmin } from 'store/adminSlices/adminUsers/sideEffects';

import { ModalUserAdmin } from '../../ModalUserAdmin';
import { CategoriesSkelleton } from './Skelleton';

import { Container, Header, Content, Row, HeaderText, RowText } from './styles';

export const AdminUsers = () => {
  // States
  const [modalUpdateUserAdminIsOpen, setModalUpdateUserAdminIsOpen] = useState(false);
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
  const [selectedUserAdmin, setSelectedUserAdmin] = useState<UserAdminData>();

  // Context
  const { user: loggedUser } = useAuthentication();

  // Redux
  const dispatch = useAppDispatch();

  const { data: users, isLoading } = useAppSelector(state => state.adminUsers);

  const handleOpenUpdateUserAdminModal = (user: UserAdminData) => {
    setSelectedUserAdmin(user);
    setModalUpdateUserAdminIsOpen(true);
  };

  const handleCloseUpdateUserAdminModal = () => {
    setModalUpdateUserAdminIsOpen(false);
  };

  const handleOpenDeleteUserAdminModal = (user: UserAdminData) => {
    setSelectedUserAdmin(user);
    setModalDeleteIsOpen(true);
  };

  const handleDeleteAdmin = async () => {
    await dispatch(thunkDeleteAdmin(selectedUserAdmin?._id as string));
  };

  // Effects
  useEffect(() => {
      dispatch(thunkGetAllAdminUsers());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Header>
        <Grid
          container
          direction="row"
          xs={12}
        >
          <Grid item xs={3}>
            <HeaderText>Nome</HeaderText>
          </Grid>
          <Grid item xs={7}>
            <HeaderText>E-mail</HeaderText>
          </Grid>
          <Grid item xs={1} style={{ paddingLeft: 40 }}>
            <HeaderText>Ações</HeaderText>
          </Grid>
        </Grid>
      </Header>
      <Divider />
      {isLoading && users.length === 0 && <CategoriesSkelleton />}
      <Content>
        {
          users?.map(user => (
            <Row key={user._id}>
              <Grid
                container
                direction="row"
                xs={12}
              >
                <Grid item xs={3} style={{ paddingLeft: 30 }}>
                  <RowText>{user.name}</RowText>
                </Grid>
                <Grid item xs={7} style={{ paddingLeft: 10 }}>
                  <RowText>{user.email}</RowText>
                </Grid>
                <Grid item xs={1}>
                  <Box display="grid" gridAutoFlow="column" gridColumnGap="20px">
                    <IconButton
                      color="inherit"
                      size="small"
                      onClick={() => handleOpenUpdateUserAdminModal(user)}
                      disabled={user._id === loggedUser?._id}
                    >
                      <RiEdit2Line size={24} />
                    </IconButton>
                    <IconButton
                      color="inherit"
                      size="small"
                      onClick={() => handleOpenDeleteUserAdminModal(user)}
                      disabled={user._id === loggedUser?._id}
                    >
                      <RiDeleteBinLine size={24} />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Row>
          ))
        }
      </Content>

      <ModalUserAdmin
        open={modalUpdateUserAdminIsOpen}
        handleClose={handleCloseUpdateUserAdminModal}
        selectedUserAdmin={selectedUserAdmin}
        type="rename"
      />

      {selectedUserAdmin && (
      <ModalDelete
        open={modalDeleteIsOpen}
        handleClose={() => setModalDeleteIsOpen(false)}
        selectedItem={selectedUserAdmin}
        type=" administrador"
        handleOk={handleDeleteAdmin}
      />
      )}

    </Container>
);
};
