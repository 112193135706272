import { Avatar, Box, ButtonBase, Theme, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

export const Container = styled(Box)<Theme>(({ theme }) => ({
  width: '100%',
  height: '80vh',
  backgroundColor: Colors.GRAY_100,
  padding: theme.spacing(6.5, 2.4),
  overflow: 'scroll',
}));

export const SectionTitle = styled(Typography)<Theme>(() => ({
  fontSize: 26,
  fontWeight: commonConstants.font.weight.bold,
  fontFamily: commonConstants.font.family.title,
}));

export const SectionSubtitle = styled(Typography)<Theme>(({ theme }) => ({
  fontSize: commonConstants.font.size.xl,
  fontFamily: commonConstants.font.family.body,
  fontWeight: commonConstants.font.weight.semiBold,
  paddingTop: theme.spacing(1.5),
  paddingLeft: theme.spacing(0.2),
}));

export const UserImageContainer = styled(Box)<Theme>(() => ({
  alignItems: 'flex-end',
  width: 'min-content',
  position: 'relative',
}));

export const UserAvatar = styled(Avatar)<Theme>(({ theme }) => ({
  height: 130,
  width: 130,
  marginTop: theme.spacing(2.5),
}));

export const UserAvatarEditButton = styled(ButtonBase)<Theme>(() => ({
  height: 40,
  width: 40,
  borderRadius: '50%',
  backgroundColor: Colors.DARK_BLUE,
  position: 'absolute',
  bottom: 0,
  right: 5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const ItemBox = styled(Box)<Theme>(() => ({
}));

export const ItemName = styled(Typography)<Theme>(({ theme }) => ({
  fontSize: commonConstants.font.size.xl,
  paddingBottom: theme.spacing(1),
  color: Colors.GRAY_800,
}));

export const ItemDescription = styled(Typography)<Theme>(() => ({
  fontSize: commonConstants.font.size.xl,
  color: Colors.GRAY_500,
}));

export const ItemButton = styled(Typography)<Theme>(({ theme }) => ({
  fontSize: commonConstants.font.size.xl,
  fontWeight: commonConstants.font.weight.bold,
  color: Colors.ORANGE,
  marginTop: theme.spacing(2),
  '&:hover': {
    cursor: 'pointer',
    opacity: 0.8,
  },
}));
