import React from 'react';
import { Helmet } from 'react-helmet';

import { Header } from '../../../components/Header';

import { Content } from './components/Content';
import { NavBar } from './components/NavBar';
import { SearchHeader } from './components/SearchHeader';
import { Sidebar } from './components/Sidebar';
import * as MaterialUIStyles from './styles';

export function Home() {
  return (
    <>
      <Helmet>
        <title>Leeg | Dashboard</title>
      </Helmet>
      <Header />
      <SearchHeader />
      <MaterialUIStyles.Content>
        <NavBar />
        <Content />
        <Sidebar />
      </MaterialUIStyles.Content>
    </>
  );
}
