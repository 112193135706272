import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { Folder } from 'services/api-leeg/modules/folders/types';
import { StoreState } from 'store';
import * as foldersService from 'services/api-leeg/modules/folders';
import { foldersActions } from '.';
import { actualContentActions } from '../actualContent';

// Async thunks
export const getFolders = createAsyncThunk(
  'folders/getFolders',
  async (userRootFolderId: string, thunk) => {
    thunk.dispatch(foldersActions.updateLoading(true));
    try {
      const { results, count } = await foldersService.getFolders(userRootFolderId);
      const sortedFolders = results.sort((a, b) => a.name.localeCompare(b.name));

      thunk.dispatch(foldersActions.updateFolders(sortedFolders));
      thunk.dispatch(foldersActions.updateCount(count));
    } catch (error) {
      toast.error('Não foi possível carregar suas pastas');
    } finally {
      thunk.dispatch(foldersActions.updateLoading(false));
    }
  },
);

export const createFolder = createAsyncThunk(
  'folders/createFolder',
  async (newFolder: Pick<Folder, 'name' | 'parent'>, thunk) => {
    try {
      const { folders: { data: folders } } = thunk.getState() as StoreState;

      const folder = await foldersService.createFolder(
        newFolder.name,
        newFolder.parent as string,
      );

      thunk.dispatch(foldersActions.updateFolders([...folders, folder]));
    } catch (error) {
      toast.error('Não foi possível criar a pasta.');
    }
  },
);

export const renameFolder = createAsyncThunk(
  'folders/renameFolder',
  async (folderToUpdate: Pick<Folder, '_id' | 'name'>, thunk) => {
    try {
      const { folders: { data: folders } } = thunk.getState() as StoreState;

      await foldersService.renameFolder(folderToUpdate._id, folderToUpdate.name);

      const newFolders = folders.map(folder => {
        if (folder._id === folderToUpdate._id) {
          return { ...folder, name: folderToUpdate.name };
        }
        return folder;
      });

      thunk.dispatch(foldersActions.updateFolders(newFolders));

      toast.success('Pasta renomeada com sucesso.');
    } catch (error) {
      toast.error('Não foi possível renomear a pasta');
    }
  },
);

export const deleteFolder = createAsyncThunk(
  'folders/deleteFolder',
  async (id: string, thunk) => {
       try {
        const { folders: { data: folders } } = thunk.getState() as StoreState;

        await foldersService.deleteFolder(id);

        const newFolders = folders.filter(previousFolders => previousFolders._id !== id);

        thunk.dispatch(foldersActions.updateFolders(newFolders));

        thunk.dispatch(actualContentActions.changeToDeletedContent());
        toast.success('Pasta deletada com sucesso.');
      } catch (error) {
        toast.error('Não foi possível deletar a pasta');
      }
  },
);
