import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { RiCloseCircleLine } from 'react-icons/ri';
import Colors from 'styles/colors';
import { FilterButton, useModalTemplatesStyles } from '../styles';
import { FiltersProps } from '../types';

export const Filters = ({
  documentCategories,
  toggleSelectFilters,
  selectedFilters,
}: FiltersProps) => {
  const templateClasses = useModalTemplatesStyles();

  return (
    <Box className={templateClasses.filtersContainer}>
      <Box className={templateClasses.filtersContent}>
        {[{ name: 'Todos', _id: 'all' }, ...documentCategories].map(filter => {
          const active = !!selectedFilters.find(
            prevFilter => prevFilter._id === filter._id,
          );
          return (
            <FilterButton
              active={active}
              endIcon={
                active && (
                  <RiCloseCircleLine
                    size={20}
                    style={{ color: Colors.WHITE }}
                  />
                )
              }
              onClick={() => toggleSelectFilters(filter)}
            >
              <Typography className={templateClasses.buttonText}>
                {filter.name}
              </Typography>
            </FilterButton>
          );
        })}
      </Box>
    </Box>
  );
};
