import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Colors from 'styles/colors';
import { commonConstants, FOOTER_HEIGHT, PREVIEW_HEIGHT } from 'styles/common';

export const useGridItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      marginBottom: theme.spacing(2),
      '&:hover': {
        cursor: 'pointer',
      },
    },
    container: {
      width: 182,
      height: 145,
      backgroundColor: Colors.LIGHT_GRAY,
      borderRadius: commonConstants.borders.radius.md,
    },
    previewContainer: {
      height: PREVIEW_HEIGHT,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    footer: {
      backgroundColor: Colors.LIGHT_BLUE,
      width: '100%',
      height: FOOTER_HEIGHT,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    infoContainer: {
      paddingLeft: 10,
      width: '70%',
    },
    name: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: commonConstants.font.size.lg,
      fontWeight: commonConstants.font.weight.bold,
      color: Colors.WHITE,
    },
    folder: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: commonConstants.font.size.md,
      fontWeight: commonConstants.font.weight.regular,
      color: Colors.GRAY_600,
    },
    icon: { height: 'fit-content' },

}));
