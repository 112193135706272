import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Document } from 'services/api-leeg/modules/documents/types';
import { DocumentsStates } from './types';

// Initial states
const initialState: DocumentsStates = {
  data: [],
  count: 0,
  isLoading: false,
};

// Slicer
const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    clearState: () => initialState,
    updateDocuments: (state, action: PayloadAction<Array<Document>>) => {
      state.data = action.payload;
      return state;
    },
    updateCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
      return state;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      return state;
    },
  },
});

// Reduces & Actions
export const documentsReducer = documentsSlice.reducer;
export const documentsActions = documentsSlice.actions;
