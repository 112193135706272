import 'config/reactotron';
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux';

import { ToastContainer } from 'react-toastify';

import { AuthenticationProvider } from 'context/Authentication';
import { store } from 'store';
import Routes from './routes';
import { theme } from './styles/theme';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AuthenticationProvider>
            <Routes />
          </AuthenticationProvider>
        </BrowserRouter>
        <ToastContainer
          limit={3}
          style={{ width: '30vw' }}
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
