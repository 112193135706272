import { makeStyles, createStyles, Theme, Button } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

type StyledTab = { active?: boolean }

export const useHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      background: Colors.DARK_BLUE,
      height: 43,
      padding: theme.spacing(0, 10),
      flexDirection: 'row',
      position: 'relative',
    },
    logo: {
      height: 28,
      width: 28,
    },
    tabLogo: {
      display: 'flex',
      alignItems: 'center',
      height: 43,
      width: 'fit-content',
      marginRight: theme.spacing(7),
    },
    tabRoot: {
      fontWeight: commonConstants.font.weight.medium,
      height: 43,
      padding: theme.spacing(0, 2),
      color: Colors.WHITE,
      borderRadius: 0,
      fontSize: commonConstants.font.size.lg,
    },
    userSection: { position: 'absolute', right: theme.spacing(10) },
    tabText: {
      maxWidth: 120,
      fontSize: commonConstants.font.size.lg,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    tabsContainer: {
      overflowX: 'scroll',
      display: 'flex',
      '&::-webkit-scrollbar': { display: 'none' },
      scrollbarWidth: 'none',
      maxWidth: '70%',
    },
}));

export const TabButton = styled(Button)<Theme, StyledTab>(({ active, theme }) => ({
  height: 43,
  paddingLeft: theme.spacing(1.5),
  padding: theme.spacing(0, 1.5),
  borderRadius: 0,
  fontSize: commonConstants.font.size.lg,
  fontWeight: commonConstants.font.weight.medium,
  color: Colors.WHITE,
  backgroundColor: active ? Colors.GRAY_200 : 'transparent',
  '&:hover': {
    backgroundColor: Colors.GRAY_500,
  },
}));
