import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { ReactComponent as DeskIllustration } from 'assets/illustrations/emptyFolders.svg';
import { useEmptyStyles } from './styles';

export function EmptyFolders() {
  const classes = useEmptyStyles();

  return (
    <Box className={classes.container}>
      <DeskIllustration className={classes.illustration} />
      <Typography className={classes.title}>
        Você não tem pastas criadas. Que tal criar uma?
      </Typography>
    </Box>
  );
}
