import React, { useMemo, useState } from 'react';

import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  IconButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import {
  RiTimeLine,
  RiDeleteBinLine,
  RiArrowDownSLine,
  RiMore2Fill,
  RiAddLine,
} from 'react-icons/ri';
import { Anchor, ModalSelectedItem } from 'types/shared';
import { ModalField } from 'components/ModalField [DEPRECATED]';
import { Folder } from 'services/api-leeg/modules/folders/types';
import { useAppSelector } from 'hooks/store/useAppSelector';
import { useAppDispatch } from 'hooks/store/useAppDispatch';
import { actualContentActions } from 'store/slices/actualContent';
import { useAuthentication } from 'hooks/useAuthentication';
import { ContentButton, FolderButton, useNavBarStyles } from './styles';
import { MenuFilters } from './MenuFilters';
import { Contents, FolderFilters } from './types';
import { MenuFolder } from './MenuFolder';
import { EmptyFolders } from './EmptyFolders';

export function NavBar() {
  // Styles
  const classes = useNavBarStyles();

  // Contexts
  const { user } = useAuthentication();

  // Redux
  const actualContent = useAppSelector(state => state.actualContent);
  const { data: folders, isLoading } = useAppSelector(state => state.folders);
  const dispatch = useAppDispatch();

  // States
  const [selectedItem, setSelectedItem] = useState<ModalSelectedItem>(
    {} as ModalSelectedItem,
  );
  const [anchorMenuFilter, setAnchorMenuFilter] = useState<Anchor>(null);
  const [anchorMenuFolder, setAnchorMenuFolder] = useState<Anchor>(null);
  const [modalFieldIsOpen, setModalFieldIsOpen] = useState(false);
  const [folderFilter, setFolderFilter] = useState<FolderFilters>('alphabetic');

  // Callback
  const handleOpenMenuFilter = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorMenuFilter(event.currentTarget);

  const handleCloseMenuFilter = () => setAnchorMenuFilter(null);

  const handleOpenRenameDocument = () => setModalFieldIsOpen(true);
  const handleCloseRenameDocument = () => setModalFieldIsOpen(false);

  const handleOpenMenuFolder = (
    event: React.MouseEvent<HTMLButtonElement>,
    folder: Folder,
  ) => {
    setSelectedItem({ name: folder.name, id: folder._id, isDocument: false });
    setAnchorMenuFolder(event.currentTarget);
  };

  const handleCloseMenuFolder = () => setAnchorMenuFolder(null);

  const changeContent = (content: Contents) => {
    dispatch(
      actualContentActions.changeActualContent({
        id: content,
        name:
          content === 'recent-documents'
            ? 'Documentos recentes'
            : 'Documentos deletados',
        folderId: null,
      }),
    );
  };

  const changeFolderFilter = (filter: FolderFilters) => {
    setFolderFilter(filter);
    handleCloseMenuFilter();
  };

  const handleSelectFolder = (folder: Folder) => {
    if (folder._id === actualContent.folderId) return;

    dispatch(
      actualContentActions.changeActualContent({
        id: 'folder',
        name: folder.name,
        folderId: folder._id,
      }),
    );
  };

  const handleGetRecentDocuments = () => {
    if (actualContent.id === 'recent-documents') return;
    changeContent('recent-documents');
  };
  const handleGetDeleteDocuments = () => {
    if (actualContent.id === 'trash') return;
    changeContent('trash');
  };

  const memoParseSortFolders = useMemo(() => {
    const newFolders = folders;
    if (folderFilter === 'alphabetic') {
      return newFolders.slice().sort((a, b) => a.name.localeCompare(b.name));
    }
    if (folderFilter === 'recent') {
      return newFolders
        .slice()
        .sort((a, b) => b.createdAt.localeCompare(a.updatedAt));
    }
    return newFolders;
  }, [folderFilter, folders]);

  return (
    <Box className={classes.container} boxShadow={2}>
      <ContentButton
        active={
          actualContent.id === 'recent-documents' && !actualContent.folderId
        }
        startIcon={<RiTimeLine className={classes.contentIcon} />}
        onClick={handleGetRecentDocuments}
      >
        Documentos recentes
      </ContentButton>
      <ContentButton
        active={actualContent.id === 'trash' && !actualContent.folderId}
        startIcon={<RiDeleteBinLine className={classes.contentIcon} />}
        onClick={handleGetDeleteDocuments}
      >
        Documentos deletados
      </ContentButton>
      <Divider className={classes.divider} />
      <Box className={classes.foldersHeader}>
        <Typography className={classes.folderHeaderTitle}>Pastas</Typography>
        <Button
          id="menu-target"
          disabled={isLoading}
          className={classes.foldersFiltersButton}
          endIcon={
            isLoading ? (
              <CircularProgress size={15} color="primary" />
            ) : (
              <RiArrowDownSLine />
            )
          }
          onClick={handleOpenMenuFilter}
        >
          {!isLoading && (folderFilter === 'alphabetic' ? 'A - Z' : 'Recentes')}
        </Button>
        <MenuFilters
          changeFolderFilter={changeFolderFilter}
          anchor={anchorMenuFilter}
          handleClose={handleCloseMenuFilter}
        />
      </Box>

      <List className={classes.folderList}>
        {[{ ...user?.rootFolder, name: 'Meus documentos' }, ...memoParseSortFolders]?.map(folder => (
          <ListItem
            className={classes.folderItem}
            key={folder?._id}
            selected={folder?._id === actualContent.folderId}
          >
            <FolderButton
              disableRipple
              variant="text"
              active={folder?._id === actualContent.folderId}
              onClick={() => handleSelectFolder(folder as Folder)}
            >
              <ListItemText primary={folder?.name} />
            </FolderButton>
            {!!folder.parent && (
            <ListItemSecondaryAction>
              <IconButton
                color="inherit"
                size="small"
                onClick={event => handleOpenMenuFolder(event, folder as Folder)}
              >
                <RiMore2Fill />
              </IconButton>
            </ListItemSecondaryAction>
)}
          </ListItem>
        ))}
        {folders?.length === 0 && <EmptyFolders />}

        <MenuFolder
          anchor={anchorMenuFolder}
          handleClose={handleCloseMenuFolder}
          selectedItem={selectedItem}
        />
      </List>

      <Button
        onClick={handleOpenRenameDocument}
        className={classes.foldersFooter}
        startIcon={<RiAddLine size={14} />}
      >
        Adicionar pasta
      </Button>

      <ModalField
        type="create"
        open={modalFieldIsOpen}
        handleClose={handleCloseRenameDocument}
      />
    </Box>
  );
}
