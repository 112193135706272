/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@blueprintjs/core';

import { SectionTab } from 'polotno/side-panel';
import { MdPhotoLibrary } from 'react-icons/md';
import { createMedia, deleteMedia, getUserMedia } from 'services/api-leeg/modules/media';
import { useAuthentication } from 'hooks/useAuthentication';
import { toast } from 'react-toastify';
import { Box } from '@material-ui/core';

import { useDropzone } from 'react-dropzone';
import { Image } from './types';
import { Masonry } from './components/Mansony';

const ImagesPanel = observer(({ store }: { store: any }) => {
  // Refs
  const inputFile = useRef<HTMLInputElement>(null);

  // Context
  const { user } = useAuthentication();

  // States
  const [images, setImages] = useState<Array<Image>>([]);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const onDrop = useCallback(async acceptedFiles => {
    const image: File | undefined = acceptedFiles[0];

    if (!image) return;

    try {
      setLoadingUpload(true);
      const { url, _id } = await createMedia({
        image,
        label: image.name,
        owner: user?.rootFolder._id as string,
      });

      setImages(previousImages => [...previousImages, { url, _id }]);
    } catch (error) {
      toast.error('Não foi possível enviar sua imagem.');
    } finally {
      setLoadingUpload(false);
    }
  }, [user?.rootFolder._id]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  // Callbacks
  async function loadImages() {
    try {
      setLoadingUpload(true);
      const { results } = await getUserMedia({ skip: 0, limit: 100 });

      setImages(results);
    } catch (error) {
      toast.error('Não foi possível carregar suas imagens.');
    } finally {
      setLoadingUpload(false);
    }
  }

  const onChangeMedia = async (
    event: React.ChangeEvent<HTMLInputElement> | undefined,
  ) => {
    const image: File | undefined = event?.target.files?.[0];

    if (!image) return;

    try {
      setLoadingUpload(true);
      const { url, _id } = await createMedia({
        image,
        label: image.name,
        owner: user?.rootFolder._id as string,
      });

      setImages(previousImages => [...previousImages, { url, _id }]);
    } catch (error) {
      toast.error('Não foi possível enviar sua imagem.');
    } finally {
      setLoadingUpload(false);
    }
  };

  const onDeleteMedia = async (id: string) => {
    await deleteMedia(id);
    setImages(previousImages => previousImages.filter(image => image._id !== id));
  };

  const onUploadButtonClick = () => inputFile.current?.click();

  // Effects
  useEffect(() => {
    loadImages();
  }, []);

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      {...getRootProps()}
    >
      <Button
        style={{ marginBottom: '20px' }}
        icon="upload"
        onClick={onUploadButtonClick}
      >
        Adicionar imagem
      </Button>
      <input
        id="file"
        type="file"
        accept="image/*"
        ref={inputFile}
        style={{ display: 'none' }}
        {...getInputProps()}
        onChange={onChangeMedia}
      />
      <Masonry
        images={images}
        store={store}
        onDeleteMedia={onDeleteMedia}
        loading={loadingUpload}
      />
    </Box>
  );
});

export const CustomImages = {
  name: 'images',
  Tab: (props: any) => (
    <SectionTab name="Upload" {...props}>
      <MdPhotoLibrary />
    </SectionTab>
  ),
  Panel: ImagesPanel,
};
