import { createTheme } from '@material-ui/core/styles';
import { Colors } from './colors';
import { commonConstants } from './common';

export const theme = createTheme({
  palette: {
    primary: {
      main: Colors.ORANGE,
    },
    secondary: {
      main: Colors.DARK_BLUE,
    },
  },
  typography: {
    fontSize: 10,
    htmlFontSize: 10,
    fontFamily: ['Inter', 'sans-serif'].join(','),
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        height: 35,
        fontSize: commonConstants.font.size.lg,
        '& $notchedOutline': {
          borderColor: Colors.LIGHT_GRAY,
          borderWidth: 1,
        },
        '&$focused $notchedOutline': {
          borderColor: Colors.ORANGE,
          borderWidth: 1,
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: Colors.ORANGE,
        },
      },
      input: {
        padding: '10.5px 14px !important',
      },
    },

    MuiSwitch: {
      root: {
        height: 10,
      },
      input: {
        padding: 0,
      },

    },
    MuiSvgIcon: {
      root: {
        height: '1.1rem',
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: commonConstants.font.size.lg,
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
      },
      sizeSmall: {
        padding: '5px 0',
      },
    },
  },
  spacing: 10,
});
