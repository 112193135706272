import React from 'react';
import { Helmet } from 'react-helmet';

import { Header } from '../../../components/Header';
import { PolotnoEditor } from './components/Polotno';

export function Editor() {
  return (
    <>
      <Helmet>
        <title>Leeg | Editor</title>
      </Helmet>
      <Header />
      <PolotnoEditor />
    </>
  );
}
