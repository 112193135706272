import React, { useState } from 'react';

import * as yup from 'yup';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
} from '@material-ui/core';

// import OAuthSection from 'components/OAuthSection';
import { ReactComponent as EyeClosed } from 'assets/icons/eye-close.svg';
import { ReactComponent as EyeOpened } from 'assets/icons/open-eye.svg';

import { InitialBackgroundWrapper } from 'components/InitialBackgroundWrapper';

import { useCommonStyles } from 'styles/common';
import { useAuthentication } from 'hooks/useAuthentication';
import Colors from 'styles/colors';
import { useForgotPasswordStyles } from './styles';
import { ToggleVisibilityAdornment } from '../SignUp/types';
import { FormInputs, RouteParams } from './types';

const schema = yup.object().shape({
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], 'As senhas devem coincidir'),
});

export function RecoveryPassword() {
  const history = useHistory();
  const params: RouteParams = useParams();
  const classes = useForgotPasswordStyles();
  const commonStyles = useCommonStyles();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const { recoverPassword, loadingForgotPassword } = useAuthentication();

  const handleGoToLogin = () => {
    history.push('/login');
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  function onSubmit({ password }: FormInputs) {
    recoverPassword(params.token, password);
  }

  const handleClickShowPassword = () => {
    setShowPassword(previousShowPassword => !previousShowPassword);
  };

  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm(previousShowPassword => !previousShowPassword);
  };

  // TODO: GENERIC COMPONENT TO IT
  const EndAdornmentToggleShowPassword = ({
    setVisibility,
    visible,
  }: ToggleVisibilityAdornment) => (
    <InputAdornment position="end">
      <IconButton onClick={() => setVisibility()} edge="end">
        {visible ? (
          <EyeOpened className={commonStyles.eyeIcon} />
        ) : (
          <EyeClosed className={commonStyles.eyeIcon} />
        )}
      </IconButton>
    </InputAdornment>
  );

  return (
    <>
      <Helmet>
        <title>Leeg | Recuperar senha</title>
      </Helmet>

      <InitialBackgroundWrapper>
        <Paper elevation={4} className={classes.paperContainer}>
          <Typography className={classes.title}>Recuperar Senha</Typography>
          <Typography className={classes.backToLogin}>
            Já possui conta?
            <Typography
              color="primary"
              className={classes.backToLoginButton}
              onClick={handleGoToLogin}
            >
              Login
            </Typography>
          </Typography>

          <form
            className={commonStyles.authForm}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container direction="column">
              <InputLabel className={classes.label} htmlFor="password">
                Senha
              </InputLabel>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    id="password"
                    error={Boolean(errors.password)}
                    type={showPassword ? 'text' : 'password'}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    endAdornment={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <EndAdornmentToggleShowPassword
                        setVisibility={handleClickShowPassword}
                        visible={showPassword}
                      />
                    }
                  />
                )}
              />

              <InputLabel className={classes.label} htmlFor="confirmPassword">
                Confirmar senha
              </InputLabel>
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    id="confirmPassword"
                    error={Boolean(errors.confirmPassword)}
                    type={showPasswordConfirm ? 'text' : 'password'}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    endAdornment={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <EndAdornmentToggleShowPassword
                        setVisibility={handleClickShowPasswordConfirm}
                        visible={showPasswordConfirm}
                      />
                    }
                  />
                )}
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loadingForgotPassword}
                className={classes.recoveryButton}
              >
                {loadingForgotPassword ? (
                  <CircularProgress size={15} style={{ color: Colors.WHITE }} />
                ) : (
                  'Recuperar senha'
                )}
              </Button>
            </Grid>
          </form>
        </Paper>
      </InitialBackgroundWrapper>
    </>
  );
}
