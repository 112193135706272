import React, { useEffect, useState } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Typography,
  IconButton,
} from '@material-ui/core';
import { RiCloseLine } from 'react-icons/ri';
import { useModalStyles } from 'styles/shared/Modal';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'hooks/store/useAppDispatch';
import {
  getDocumentCategories as thunkGetDocumentCategories,
  getDocumentByCategory as thunkGetDocumentByCategory,
} from 'store/slices/documentCategories/sideEffects';
import { getDocument } from 'store/slices/actualDocument/sideEffects';

import { useAppSelector } from 'hooks/store/useAppSelector';
import { DocumentCategory } from 'services/api-leeg/modules/documentCategories/types';
import { ModalPreviewDocument } from 'components/ModalPreviewDocument';
import { Document } from 'services/api-leeg/modules/documents/types';
import { createDocument } from 'services/api-leeg/modules/documents';
import { documentsActions } from 'store/slices/documents';
import { tabsActions } from 'store/slices/tabs';
import { ModalFolders } from 'components/ModalFolders';
import { Folder } from 'services/api-leeg/modules/folders/types';
import { useAuthentication } from 'hooks/useAuthentication';
import { ModalFieldProps } from './types';
import { GridContent } from './Grid';
import { Filters } from './Filters';
import { FiltersSkelleton } from './Filters/Skelleton';
import { Title } from './Title';
import { Footer } from './Footer';
import { GridContentSkelleton } from './Grid/Skelleton';

export function ModalTemplate({ open, handleClose }: ModalFieldProps) {
  // Styles hooks
  const classes = useModalStyles();

  // Contexts
  const { user } = useAuthentication();

  // Redux
  const dispatch = useAppDispatch();
  const { data: folders } = useAppSelector(state => state.folders);
  const { data: tabs } = useAppSelector(state => state.tabs);
  const {
    data: documentCategories,
    templates,
    isLoading,
    isLoadingTemplates,
  } = useAppSelector(state => state.documentCategories);
  const swapFolderId = useAppSelector(state => state.swapFolderId);
  const { data: documents } = useAppSelector(state => state.documents);

  // States
  const [selectedItem, setSelectedItem] = useState<string | undefined>();
  const [modalPreviewIsOpen, setModalPreviewIsOpen] = useState(false);
  const [modalFoldersOpen, setModalFoldersOpen] = useState(false);
  const [templateToPreview, setTemplateToPreview] = useState<Document>(
    {} as Document,
  );
  const [selectedFilters, setSelectedFilters] = useState<
    Array<Partial<DocumentCategory>>
  >([{ name: 'Todos', _id: 'all' }]);
  const [loadingCreateDocument, setLoadingCreateDocument] = useState(false);

  // Callbacks
  const handleOpenModalFolders = () => setModalFoldersOpen(true);
  const handleCloseModalFolders = () => setModalFoldersOpen(false);
  const toggleSelectFilters = (selectedFilter: Partial<DocumentCategory>) => {
    setSelectedItem(undefined);

    const filterAlreadySelected = selectedFilters.find(
      previousSelectedFilters =>
        previousSelectedFilters._id === selectedFilter._id,
    );

    if (selectedFilter._id === 'all' && !filterAlreadySelected) {
      setSelectedFilters([selectedFilter]);
    } else if (!filterAlreadySelected) {
      const newFilters = selectedFilters.filter(
        previousSelectedFilter => previousSelectedFilter._id !== 'all',
      );

      setSelectedFilters([...newFilters, selectedFilter]);
    } else if (filterAlreadySelected) {
      const newFilters = selectedFilters.filter(
        previousSelectedFilters =>
          previousSelectedFilters._id !== filterAlreadySelected._id &&
          previousSelectedFilters._id !== 'all',
      );
      if (newFilters.length === 0) {
        setSelectedFilters([{ name: 'Todos', _id: 'all' }]);
      } else {
        setSelectedFilters([...newFilters]);
      }
    }
  };
  const handleSelectTemplate = (templateId: string) => {
    if (selectedItem === templateId) {
      setSelectedItem(undefined);
      return;
    }
    setSelectedItem(templateId);
  };
  const openPreview = (template: Document) => {
    setTemplateToPreview(template);
    setModalPreviewIsOpen(true);
  };
  const closePreview = () => setModalPreviewIsOpen(false);

  const handleUseTemplate = async () => {
    setLoadingCreateDocument(true);
    const selectedTemplate = templates.find(
      template => template._id === (selectedItem as string),
    ) as Document;

    try {
      const document = await createDocument(swapFolderId, selectedTemplate, true);
      dispatch(documentsActions.updateDocuments([...documents, document]));
      handleClose();

      dispatch(
        tabsActions.updateTabs([
          ...tabs,
          { id: document._id, name: document.name },
        ]),
      );

      dispatch(tabsActions.updateActiveTab(document._id));
      await dispatch(getDocument(document._id));

      toast.success('Documento criado com sucesso.');
    } catch (error) {
      toast.error('Erro ao tentar criar documento a partir do modelo.');
    } finally {
      setLoadingCreateDocument(false);
    }
  };

  // Effects
  useEffect(() => {
    if (open) {
      dispatch(thunkGetDocumentCategories());
    }
  }, [dispatch, open]);

  useEffect(() => {
    if (open) {
      const parsedFilters =
        selectedFilters[0]._id === 'all' ? documentCategories : selectedFilters;

      dispatch(thunkGetDocumentByCategory(parsedFilters));
    }
  }, [dispatch, documentCategories, open, selectedFilters]);

  return (
    <>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <Paper className={clsx(classes.paper, classes.templates)}>
            <IconButton
              className={classes.closeButton}
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <RiCloseLine size={16} />
            </IconButton>
            <Typography className={classes.title}>Modelos</Typography>
            {isLoading ? (
              <FiltersSkelleton />
            ) : (
              <Filters
                documentCategories={documentCategories}
                selectedFilters={selectedFilters}
                toggleSelectFilters={toggleSelectFilters}
              />
            )}
            <Title
              title={selectedFilters
                .map(selectedFilter => selectedFilter.name)
                .join(' & ')}
            />
            {isLoadingTemplates ? (
              <GridContentSkelleton />
            ) : (
              <GridContent
                handleSelect={handleSelectTemplate}
                openPreview={openPreview}
                selectedItem={selectedItem}
              />
            )}
            <Footer
              handleClose={handleClose}
              handleUseTemplate={handleOpenModalFolders}
              selectedItem={selectedItem}
              loading={loadingCreateDocument}
            />
          </Paper>
        </Fade>
      </Modal>
      <ModalPreviewDocument
        document={templateToPreview}
        open={modalPreviewIsOpen}
        handleClose={closePreview}
      />
      <ModalFolders
        open={modalFoldersOpen}
        handleClose={handleCloseModalFolders}
        folders={[{ ...user?.rootFolder as Folder, name: 'Meus documentos' }, ...folders]}
        handleSave={handleUseTemplate}
      />
    </>
  );
}
