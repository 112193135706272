import React from 'react';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { useMiniPreviewContentStyles } from './styles';

export const MiniPreviewContentSkelleton = () => {
  const classes = useMiniPreviewContentStyles();

  const skelletonWidths = Array.from({ length: 10 }, () =>
    Math.floor(Math.random() * (100 - 40) + 40),
  );

  return (
    <div
      className={classes.previewSheet}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <div className={classes.previewContentSkelleton}>
        {skelletonWidths.map(skelletonLineWidth => (
          <Skeleton
            key={skelletonLineWidth}
            width={`${skelletonLineWidth}%`}
            height="10px"
          />
        ))}
      </div>
    </div>
  );
};
