import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import * as documentsServices from 'services/api-leeg/modules/documents';
import { actualDocumentActions } from '.';

export const getDocument = createAsyncThunk(
  'actualDocument/getDocument',
  async (id: string, thunk) => {
    thunk.dispatch(actualDocumentActions.updateLoading(true));
    try {
      const doc = await documentsServices.getDocument(id);

      thunk.dispatch(actualDocumentActions.changeActualDocument(doc));

      return doc;
    } catch (error) {
      toast.error('Não foi possível carregar o corpo do documento');
      throw Error;
    } finally {
      thunk.dispatch(actualDocumentActions.updateLoading(false));
    }
  },
);
