import { makeStyles, createStyles, styled, Button, Theme } from '@material-ui/core';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

type StyledFilter = { active: boolean }

export const useModalTemplatesStyles = makeStyles((theme: Theme) =>
  createStyles({
    filtersContainer: {
      width: '100%',
      marginTop: theme.spacing(4),
    },
    filtersContent: {
      width: '100%',
      display: 'flex',
      overflowX: 'scroll',
      height: 45,
      paddingBottom: theme.spacing(1),
      '&::-webkit-scrollbar': {
        width: 0,
        height: '0.3rem',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: `inset 0 0 6px ${Colors.WHITE}`,
        webkitBoxShadow: `inset 0 0 6px ${Colors.WHITE}`,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        borderRadius: 2,
        outline: `1px solid ${Colors.WHITE}`,
      },
    },
    buttonText: {
      fontWeight: 300,
      fontSize: commonConstants.font.size.xl,
      alignSelf: 'right',
    },
    actionsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(0.5),
      paddingTop: theme.spacing(1),
      [theme.breakpoints.up('xl')]: {
        marginTop: theme.spacing(1),
      },
      borderTop: '1px solid rgba(156, 156, 156, 0.4)',
    },
    buttons: {
      borderRadius: commonConstants.borders.radius.md,
      fontSize: commonConstants.font.size.xl,
      fontWeight: commonConstants.font.weight.semiBold,
      padding: theme.spacing(1, 5),
      '& + button': {
        marginLeft: theme.spacing(1),
      },
    },
}));

export const FilterButton = styled(Button)<Theme, StyledFilter>(({ active, theme }) => ({
  backgroundColor: active ? Colors.ORANGE : Colors.WHITE,
  minWidth: 160,
  justifyContent: active ? 'space-between' : 'center',
  padding: theme.spacing(1.5, 2),
  border: active ? 'none' : `1px solid ${Colors.GRAY_200}`,
  borderRadius: commonConstants.borders.radius.xxl,
  marginRight: theme.spacing(2),
  color: active ? Colors.WHITE : Colors.GRAY_500,
  '&:hover': {
    backgroundColor: active ? Colors.ORANGE : Colors.WHITE,
    filter: 'brightness(0.9)',
    cursor: 'pointer',
  },
}));
