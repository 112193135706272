import { setLocalStorageValue } from 'helpers/LocalStorage';
import { storageKeyAccessToken, storageKeyRefreshToken, storageKeyUser, UserDto } from 'types/dtos/user.dto';

export const storeUserData = (userData: UserDto) => {
  setLocalStorageValue({
    key: storageKeyUser,
    value: userData,
  });
  setLocalStorageValue({ key: storageKeyAccessToken, value: userData.accessToken });
  setLocalStorageValue({ key: storageKeyRefreshToken, value: userData.refreshToken });
};
