import { Folder } from 'services/api-leeg/modules/folders/types';
import { Signature } from 'services/api-leeg/modules/user/types';

export interface UserDto {
  _id: string;
  name: string;
  email: string;
  cpf?: string;
  accessToken: string;
  isPremium: boolean;
  phone?: string;
  roles: Array<string>,
  address: {
    street: string;
    district: string;
    zipCode: string;
    cityName: string;
    stateInitials: string;
    number: string;
    countryName: 'Brazil';
    ibge?: string;
  },
  createdAt: string;
  refreshToken: string;
  updatedAt: string;
  urlAvatar: string;
  signature?: Signature;
  rootFolder: Folder;
}

export const storageKeyUser = 'leeg::user';
export const storageKeyAccessToken = 'leeg::accessToken';
export const storageKeyRefreshToken = 'leeg::refreshToken';
