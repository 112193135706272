import { createAsyncThunk } from '@reduxjs/toolkit';
import * as adminUsersService from 'services/api-leeg/modules/adminUsers';
import { toast } from 'react-toastify';
import { StoreState } from 'store';
import { UserAdminCreateData, UserAdminData, UserAdminUpdateData } from 'services/api-leeg/modules/adminUsers/types';
import { adminUsersActions } from '.';

// Async thunks
export const getAllAdminUsers = createAsyncThunk(
  'adminUsers/getAllAdminUsers',
  async (_, thunk) => {
    thunk.dispatch(adminUsersActions.updateLoading(true));
    try {
      const adminUsers = await adminUsersService.getAllAdminUsers();

      thunk.dispatch(adminUsersActions.updateUsers(adminUsers));
    } catch (error) {
      toast.error('Não foi possível carregar os usuários.');
    } finally {
      thunk.dispatch(adminUsersActions.updateLoading(false));
    }
  },
);

export const createAdminUser = createAsyncThunk(
  'adminUsers/createAdminUser',
  async (userData: UserAdminCreateData, thunk) => {
    try {
      const {
        adminUsers: { data: adminUsers },
      } = thunk.getState() as StoreState;

      const adminUser = await adminUsersService.createAdminUser(userData);

      thunk.dispatch(adminUsersActions.updateUsers([...adminUsers, adminUser]));
      toast.success('Admin criado com sucesso.');
    } catch (error: any) {
      toast.error(`Falha ao tentar criar admin. ${error.response.status === 409 && ' E-mail já registrado por um usuário.'}`);
    }
  },
);

export const updateAdminUser = createAsyncThunk(
  'adminUsers/updateAdminUser',
  async (adminUserToUpdate: Partial<UserAdminUpdateData>, thunk) => {
    try {
      const {
        adminUsers: { data: adminUsers },
      } = thunk.getState() as StoreState;

      await adminUsersService.updateAdminUser(adminUserToUpdate);

      const newAdminUsers = adminUsers.map(adminUser => {
        if (adminUser._id === adminUserToUpdate.id) {
          return {
            ...adminUser, name: adminUserToUpdate.name, email: adminUserToUpdate.email,
          } as UserAdminData;
        }
        return adminUser;
      });

      thunk.dispatch(adminUsersActions.updateUsers(newAdminUsers));

      toast.success('Admin atualizado com sucesso.');
    } catch (error) {
      toast.error('Não foi possível atualizar o admin.');
    }
  },
);

export const deleteAdmin = createAsyncThunk(
  'adminUsers/deleteAdmin',
  async (id: string, thunk) => {
    try {
      const {
        adminUsers: { data: adminUsers },
      } = thunk.getState() as StoreState;

      await adminUsersService.updateAdminUser({ id, roles: ['user'] });

      const newUsers = adminUsers.filter(adminUser => adminUser._id !== id);

      thunk.dispatch(adminUsersActions.updateUsers(newUsers));

      toast.success('Admin removido com sucesso.');
    } catch (error) {
      toast.error('Não foi possível remover o admin');
    }
  },
);
