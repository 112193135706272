import React, { useState } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Typography,
  Box,
  Button,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { RiCloseLine } from 'react-icons/ri';
import { useModalStyles } from 'styles/shared/Modal';

import { ModalDeleteProps } from './types';

export function ModalDelete({
  open,
  handleClose,
  selectedItem,
  type,
  handleOk,
}: ModalDeleteProps) {
  const classes = useModalStyles();

  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    handleOk();
    handleClose();
    setLoading(false);
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <IconButton
            className={classes.closeButton}
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <RiCloseLine size={16} />
          </IconButton>
          <Typography className={classes.title}>
            Excluir
            {type}
          </Typography>
          <Typography className={classes.description}>
            Você tem certeza que deseja excluir
            <b>{` ”${selectedItem.name}”?`}</b>
          </Typography>
          <Box className={classes.actions}>
            <Button className={classes.buttons} onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              className={classes.buttons}
              disableElevation
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={handleDelete}
            >
              Excluir
              {loading && (
                <CircularProgress
                  size={14}
                  className={classes.loadingProgress}
                />
              )}
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
}
