import { Box, Button, OutlinedInput, Theme, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

export const Container = styled(Box)<Theme>(({ theme }) => ({
  width: '70%',
  marginTop: theme.spacing(3),
}));

export const Content = styled(Box)<Theme>(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  rowGap: 20,
  columnGap: 23,
}));

export const Label = styled(Typography)<Theme>(() => ({
  fontSize: commonConstants.font.size.lg,
  fontFamily: commonConstants.font.family.body,
  fontWeight: commonConstants.font.weight.regular,
}));

export const Input = styled(OutlinedInput)<Theme>(() => ({
  backgroundColor: Colors.WHITE,
  height: 50,
  width: '100%',
  borderRadius: commonConstants.borders.radius.md,
  fontWeight: commonConstants.font.weight.bold,
  '& .MuiInputBase-input': {
    padding: '16.5px 14px !important',
  },
}));

export const ActionsContainer = styled(Box)<Theme>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  marginTop: theme.spacing(2),
}));

export const Actions = styled(Button)<Theme>(({ theme }) => ({
  fontSize: commonConstants.font.size.xl,
  fontWeight: commonConstants.font.weight.semiBold,
  height: 40,
  padding: theme.spacing(0, 3),
  '& + button': {
    marginLeft: theme.spacing(2),
  },
}));
