import { CircularProgress } from '@material-ui/core';
import { ModalPreviewDocument } from 'components/ModalPreviewDocument';
import { useAppDispatch } from 'hooks/store/useAppDispatch';
import { useAppSelector } from 'hooks/store/useAppSelector';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { getTemplateDocument } from 'services/api-leeg/modules/documents';
import { Document } from 'services/api-leeg/modules/documents/types';
import { getDocument as thunkGetDocument } from 'store/slices/actualDocument/sideEffects';
import { tabsActions } from 'store/slices/tabs';
import { formatDetailDate } from 'utils/parseDate';
import { ResultsSkelleton } from './Skelleton';
import {
  Container,
  Content,
  HeaderResultSection,
  HeaderTextResultSection,
  ItemResultSection,
  ItemTextResultSection,
  ResultSection,
} from './styles';
import { ResultsProps } from './types';

const Results = ({ open, loading, results }: ResultsProps) => {
  // Redux
  const dispatch = useAppDispatch();
  const { data: tabs } = useAppSelector(state => state.tabs);

  // States
  const [idSwap, setIdSwap] = useState('');
  const [templatePreview, setTemplatePreview] = useState<Document>();
  const [modalPreviewIsOpen, setModalPreviewIsOpen] = useState(false);
  const [loadingTemplate, setLoadingTemplate] = useState(false);

  // Callbacks
  const handleClosePreview = () => setModalPreviewIsOpen(false);
  const handleOpenDocument = async (id: string, name: string) => {
    dispatch(tabsActions.updateTabs([...tabs, { id, name }]));
    await dispatch(thunkGetDocument(id));
    dispatch(tabsActions.updateActiveTab(id));
  };

  const handleGetTemplate = async (id: string) => {
    setIdSwap(id);
    try {
      setLoadingTemplate(true);
      const template = await getTemplateDocument(id);
      setTemplatePreview(template);
      setModalPreviewIsOpen(true);
    } catch (error) {
      toast.error('Não foi possivel abrir modelo.');
    } finally {
      setLoadingTemplate(false);
    }
  };

  if (loading) {
    return <ResultsSkelleton />;
  }

  return (
    <>
      <Container open={open}>
        <Content open={open}>
          <ResultSection>
            <HeaderResultSection>
              <HeaderTextResultSection>Documentos</HeaderTextResultSection>
            </HeaderResultSection>
            {results?.documents?.length ? (
              results?.documents?.map(document => (
                <ItemResultSection
                  key={document._id}
                  onClick={
                    () => handleOpenDocument(document._id, document.name)
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                >
                  <ItemTextResultSection>{document.name}</ItemTextResultSection>
                  <ItemTextResultSection description>
                    {`Criado há ${formatDetailDate(document.createdAt)}`}
                  </ItemTextResultSection>
                </ItemResultSection>
              ))
            ) : (
              <ItemResultSection disabled>
                <ItemTextResultSection description>
                  Sem resultados
                </ItemTextResultSection>
              </ItemResultSection>
            )}
          </ResultSection>
          <ResultSection>
            <HeaderResultSection>
              <HeaderTextResultSection>Templates</HeaderTextResultSection>
            </HeaderResultSection>
            {results?.templates?.length > 0 ? (
              results?.templates?.map(template => (
                <ItemResultSection
                  key={template._id}
                  onClick={
                    () => handleGetTemplate(template._id)
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                >
                  <ItemTextResultSection>{template.name}</ItemTextResultSection>
                  {loadingTemplate && template._id === idSwap && (
                    <CircularProgress size={12} />
                  )}
                </ItemResultSection>
              ))
            ) : (
              <ItemResultSection disabled>
                <ItemTextResultSection>Sem resultados</ItemTextResultSection>
              </ItemResultSection>
            )}
          </ResultSection>
        </Content>
      </Container>
      <ModalPreviewDocument
        document={templatePreview as Document}
        open={modalPreviewIsOpen}
        handleClose={handleClosePreview}
      />
    </>
  );
};

export default Results;
