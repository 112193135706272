import React, { useState } from 'react';
import { AppBar, Box, InputBase } from '@material-ui/core';

import { RiSearchLine } from 'react-icons/ri';
import Colors from 'styles/colors';
import { searchDocumentsAndTemplates } from 'services/api-leeg/modules/documents';
import { SearchResponse } from 'services/api-leeg/modules/documents/types';
import { toast } from 'react-toastify';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useSearchHeaderStyles } from './styles';
import Results from './Results';

export function SearchHeader() {
  const classes = useSearchHeaderStyles();

  const [loading, setLoading] = useState(false);
  const [resultsAreOpen, setResultsAreOpen] = useState(false);
  const [results, setResults] = useState({} as SearchResponse);

  const handleSearch = useDebouncedCallback(async (value: string) => {
    try {
      const resultsResponse = await searchDocumentsAndTemplates(value);
      setResults(resultsResponse);
    } catch (error) {
      toast.error('Não foi possível realizar a pesquisa.');
    } finally {
      setLoading(false);
    }
  }, 400);

  const onChange = async (value: string) => {
    if (value.length > 2) {
      setLoading(true);
      setResultsAreOpen(true);
      handleSearch(value);
    } else {
      setResultsAreOpen(false);
    }
  };

  return (
    <AppBar position="static" className={classes.container}>
      <Box className={classes.contentSearch}>
        <RiSearchLine color={Colors.GRAY_800} size={22} />
        <InputBase
          placeholder="Buscar pastas, modelos e documentos"
          className={classes.input}
          onChange={({ target }) => onChange(target.value)}
        />
      </Box>
      <Results open={resultsAreOpen} results={results} loading={loading} />
    </AppBar>
  );
}
