import React, { useState } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Typography,
  Box,
  Button,
  IconButton,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import { RiCloseLine } from 'react-icons/ri';
import { useModalStyles } from 'styles/shared/Modal';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { mask } from 'remask';
import { createSubscription } from 'services/api-leeg/modules/plans';
import { useAuthentication } from 'hooks/useAuthentication';
import { toast } from 'react-toastify';
import { Label, Input, ModalDivider, TotalLabel, TotalAmount } from './styles';
import { ModalPaymentProps } from './types';

const schema = yup.object().shape({
  number: yup.string().required(),
  cardholder: yup.string().required(),
  cpf: yup.string().required(),
  duedate: yup.string().required(),
  code: yup.string().required(),
});

export const ModalPayment = ({
  open,
  onClose,
  selectedPlan,
}: ModalPaymentProps) => {
  // Style hooks
  const classes = useModalStyles();

  const { user, getUser } = useAuthentication();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  // States
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      await createSubscription({
        IdUser: user?._id as string,
        cpf: values.cpf.match(/\d/g).join(''),
        Plan: selectedPlan.idPlanSafe2Pay,
        planId: selectedPlan._id,
        PaymentMethod: '2',
        CreditCard: {
            CardNumber: values.number,
            ExpirationDate: values.duedate,
            Holder: values.cardholder,
            SecurityCode: values.code,
          },
      });

      await getUser();
      toast.success('Assinatura efetuada com sucesso');
      onClose();
    } catch (error) {
      toast.error('Não foi possível efetuar a assinatura.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Paper className={classes.paperPayments}>
          <IconButton
            className={classes.closeButton}
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <RiCloseLine size={16} />
          </IconButton>
          <Typography className={classes.title}>
            Detalhes do pagamento
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container xs={12} spacing={2} style={{ marginTop: 30 }}>

              <Grid item xs={12}>
                <Label>Número do cartão</Label>
                <Controller
                  name="number"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="number"
                      placeholder="0000 0000 0000 0000"
                      error={Boolean(errors.number)}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={
                        ({ target }) =>
                          field.onChange(mask(target.value, '9999 9999 9999 9999'))
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                    />
                )}
                />
              </Grid>
              <Grid item xs={6}>
                <Label>Titular do cartão</Label>
                <Controller
                  name="cardholder"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="cardholder"
                      placeholder="Sem nome"
                      error={Boolean(errors.cardholder)}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                    />
                )}
                />
              </Grid>
              <Grid item xs={6}>
                <Label>CPF</Label>
                <Controller
                  name="cpf"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="cpf"
                      placeholder="000.000.000-00"
                      error={Boolean(errors.cpf)}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={
                        ({ target }) =>
                          field.onChange(mask(target.value, '999.999.999-99'))
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                    />
                )}
                />
              </Grid>
              <Grid item xs={3}>
                <Label>Vencimento</Label>
                <Controller
                  name="duedate"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="duedate"
                      placeholder="00/0000"
                      error={Boolean(errors.duedate)}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={
                        ({ target }) =>
                          field.onChange(mask(target.value, '99/9999'))
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                    />
                )}
                />
              </Grid>
              <Grid item xs={3}>
                <Label>Código de segurança</Label>
                <Controller
                  name="code"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="code"
                      placeholder="0000"
                      error={Boolean(errors.code)}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={
                        ({ target }) =>
                          field.onChange(mask(target.value, ['999', '9999']))
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                    />
                )}
                />
              </Grid>
            </Grid>
            <ModalDivider />
            <Box display="flex" width="100%" justifyContent="space-between" paddingTop={1}>
              <TotalLabel>Total da assinatura</TotalLabel>
              <TotalAmount>
                R$
                {' '}
                {selectedPlan.amount ? selectedPlan?.amount.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
              </TotalAmount>
            </Box>
            <Box className={classes.actions}>
              <Button className={classes.buttons} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                className={classes.buttons}
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                Assinar
                {loading && (
                  <CircularProgress
                    size={14}
                    className={classes.loadingProgress}
                  />
                )}
              </Button>
            </Box>
          </form>
        </Paper>
      </Fade>
    </Modal>
  );
};
