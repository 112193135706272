import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
} from '@material-ui/core';
import {
  RiHome2Line,
  RiAddLine,
} from 'react-icons/ri';

import { ReactComponent as LeegLogo } from 'assets/icons/leeg.svg';

import Colors from 'styles/colors';
import { useAuthentication } from 'hooks/useAuthentication';
import { createDocument } from 'services/api-leeg/modules/documents';
import { toast } from 'react-toastify';
import { useAppSelector } from 'hooks/store/useAppSelector';
import { useAppDispatch } from 'hooks/store/useAppDispatch';
import { documentsActions } from 'store/slices/documents';
import { tabsActions } from 'store/slices/tabs';
import { getDocument as thunkGetDocument } from 'store/slices/actualDocument/sideEffects';
import { ModalFolders } from 'components/ModalFolders';
import { useHistory } from 'react-router-dom';
import { useTabs } from 'hooks/useTabs';
import { Folder } from 'services/api-leeg/modules/folders/types';
import { UserSection } from './UserSection';
import { TabButton, useHeaderStyles } from './styles';
import { Tab } from './Tab';

export function Header() {
  // Style hooks
  const classes = useHeaderStyles();

  // Hooks
  const history = useHistory();
  const { goTo, openDocument } = useTabs();

  // Context
  const { user } = useAuthentication();

  // Redux
  const dispatch = useAppDispatch();
  const swapFolderId = useAppSelector(state => state.swapFolderId);
  const { data: documents } = useAppSelector(state => state.documents);
  const { isLoading } = useAppSelector(state => state.actualDocument);
  const { data: tabs, activeTab } = useAppSelector(state => state.tabs);
  const { data: folders } = useAppSelector(state => state.folders);
  const { isUpdating } = useAppSelector(state => state.editor);

  // States
  const [modalFoldersOpen, setModalFoldersOpen] = useState(false);
  const [loadingCreateDocument, setLoadingCreateDocument] = useState(false);
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);

  // Callbacks
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleOpenModalFolders = () => setModalFoldersOpen(true);

  const handleCloseModalFolders = () => setModalFoldersOpen(false);

  const handleCloseMenu = () => setAnchorMenu(null);

  const handleGoToHome = () => {
    if (isUpdating) {
      toast.info('Salvando documento...');
      return;
    }

    if (isLoading) {
      toast.info('Carregando document...');
      return;
    }

    dispatch(tabsActions.updateActiveTab('home'));
    history.push('/dashboard');
  };

  const handleSelectTab = async (id: string) => {
    if (isUpdating) {
      toast.info('Salvando documento...');
      return;
    }

    if (isLoading) {
      toast.info('Carregando document...');
      return;
    }

    if (id === activeTab) return;

    dispatch(tabsActions.updateActiveTab(id));
    if (['admin', 'profile', 'plans'].includes(id)) {
      history.push(`/${id}`);
    } else {
      openDocument(id, '');
    }
  };

  const createNewDocument = async () => {
    try {
      setLoadingCreateDocument(true);
      const document = await createDocument(swapFolderId);

      setLoadingCreateDocument(false);

      dispatch(
        tabsActions.updateTabs([
          ...tabs,
          { id: document._id, name: document.name },
        ]),
      );
      await dispatch(thunkGetDocument(document._id));
      dispatch(tabsActions.updateActiveTab(document._id));

      return dispatch(
        documentsActions.updateDocuments([...documents, document]),
      );
    } catch (error: any) {
      setLoadingCreateDocument(false);
      return toast.error(error.response.data.error.message as string, { onClick: () => goTo('plans', 'Planos') });
    }
  };

  const closeTab = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
  ) => {
    if (isUpdating) {
      toast.info('Salvando documento...');
      return;
    }

    if (isLoading) {
      toast.info('Carregando document...');
      return;
    }

    event.stopPropagation();
    if (activeTab === id) {
      handleGoToHome();
    }
    const newTabs = tabs.filter(tab => tab.id !== id);
    dispatch(tabsActions.updateTabs(newTabs));
  };

  useEffect(() => {
    const storagedTabs = localStorage.getItem('leeg:tabs');
    const storagedActiveTab = localStorage.getItem('leeg:activeTab');

    if (storagedTabs) {
      dispatch(tabsActions.updateTabs(JSON.parse(storagedTabs)));
      dispatch(tabsActions.updateActiveTab(storagedActiveTab || 'home'));
    }
  }, [dispatch]);

  const userNameFirstCharacter = user?.name.charAt(0) ?? '';

  return (
    <AppBar position="static" className={classes.container}>
      <Box className={classes.tabLogo}>
        <LeegLogo className={classes.logo} />
      </Box>

      <TabButton
        active={activeTab === 'home'}
        startIcon={<RiHome2Line size={16} />}
        onClick={handleGoToHome}
      >
        Início
      </TabButton>

      <div className={classes.tabsContainer}>
        {tabs?.map(tab => (
          <Tab
            tab={tab}
            activeTab={activeTab}
            handleCloseTab={closeTab}
            handleSelectTab={handleSelectTab}
          />
        ))}
      </div>

      <Button className={classes.tabRoot} onClick={handleOpenModalFolders}>
        {loadingCreateDocument ? (
          <CircularProgress size={18} style={{ color: Colors.WHITE }} />
        ) : (
          <RiAddLine size={18} />
        )}
      </Button>

      <Box className={classes.userSection}>
        <UserSection
          handleOpenMenu={handleOpenMenu}
          handleCloseMenu={handleCloseMenu}
          anchorMenu={anchorMenu}
          avatar={user?.urlAvatar}
          isAdmin={!!user?.roles.find(role => role === 'admin')}
          userNameFirstCharacter={userNameFirstCharacter}
        />
      </Box>

      <ModalFolders
        open={modalFoldersOpen}
        handleClose={handleCloseModalFolders}
        folders={[{ ...user?.rootFolder as Folder, name: 'Meus documentos' }, ...folders]}
        handleSave={createNewDocument}
      />
    </AppBar>
  );
}
