import clientApi from '../../../client';
import { Folder, FoldersResponse } from './types';

export const getFolders = async (parent: string) : Promise<FoldersResponse> => {
  const { data: folders } = await clientApi.get<FoldersResponse>(`/folders?filter[parent]=${parent}&filter[isDeleted]=false`);

  return folders;
};

export const createFolder = async (name: string, parent: string) : Promise<Folder> => {
  const { data: folder } = await clientApi.post<Folder>('/folders/subfolders', {
    name,
    parent,
 });

  return folder;
};

export const renameFolder = async (id: string, name: string) => {
    await clientApi.patch(`/folders/subfolders/${id}`, {
        name,
    });
};

export const deleteFolder = async (id: string) => {
    await clientApi.patch(`/folders/subfolders/${id}`, {
        isDeleted: true,
    });
};
