import React from 'react';

import {
  Box,
} from '@material-ui/core';

import {
  RiTimeLine,
  RiDeleteBinLine,
  RiUserLine,
  RiMoneyDollarCircleLine,
  RiListSettingsLine,
} from 'react-icons/ri';
import { useAppSelector } from 'hooks/store/useAppSelector';
import { useAppDispatch } from 'hooks/store/useAppDispatch';
import { actualAdminContentActions } from 'store/adminSlices/adminActualContent';
import { AdminActualContent } from 'store/adminSlices/adminActualContent/types';
import { ContentButton, useNavBarStyles } from './styles';

export function NavBar() {
  // Styles
  const classes = useNavBarStyles();

  // Redux
  const adminActualContent = useAppSelector(state => state.adminActualContent);
  const dispatch = useAppDispatch();

  // Callbacks
  const handleChangeContent = (content: AdminActualContent) => {
    dispatch(
      actualAdminContentActions.changeActualContent(content),
    );
  };

  return (
    <Box className={classes.container} boxShadow={2}>
      <ContentButton
        active={
          adminActualContent.id === 'recent-templates'
        }
        startIcon={<RiTimeLine className={classes.contentIcon} />}
        onClick={() => handleChangeContent({ id: 'recent-templates', name: 'Modelos recentes' })}
      >
        Modelos recentes
      </ContentButton>
      <ContentButton
        active={adminActualContent.id === 'deleted-templates'}
        startIcon={<RiDeleteBinLine className={classes.contentIcon} />}
        onClick={() => handleChangeContent({ id: 'deleted-templates', name: 'Modelos deletados' })}

      >
        Modelos deletados
      </ContentButton>
      <ContentButton
        active={adminActualContent.id === 'admin-users'}
        startIcon={<RiUserLine className={classes.contentIcon} />}
        onClick={() => handleChangeContent({ id: 'admin-users', name: 'Usuários admin' })}
      >
        Usuários Admin
      </ContentButton>
      <ContentButton
        active={adminActualContent.id === 'plans'}
        startIcon={<RiMoneyDollarCircleLine className={classes.contentIcon} />}
        onClick={() => handleChangeContent({ id: 'plans', name: 'Planos' })}
      >
        Planos
      </ContentButton>
      <ContentButton
        active={adminActualContent.id === 'categories'}
        startIcon={<RiListSettingsLine className={classes.contentIcon} />}
        onClick={() => handleChangeContent({ id: 'categories', name: 'Categorias' })}
      >
        Categorias
      </ContentButton>
    </Box>
  );
}
