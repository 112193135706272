import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Document } from 'services/api-leeg/modules/documents/types';
import { DeletedTemplatesStates } from './types';

// Initial states
const initialState: DeletedTemplatesStates = {
  data: [],
  count: 0,
  isLoading: false,
};

// Slicer
const adminDeletedDocumentsTemplatesSlice = createSlice({
  name: 'adminDocumentsTemplates',
  initialState,
  reducers: {
    clearState: () => initialState,
    updateDeletedTemplates: (state, action: PayloadAction<Array<Document>>) => {
      state.data = action.payload;
      return state;
    },
    updateCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
      return state;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      return state;
    },
  },
});

// Reduces & Actions
export const adminDeletedDocumentsTemplatesReducer = adminDeletedDocumentsTemplatesSlice.reducer;
export const adminDeletedDocumentsTemplatesActions = adminDeletedDocumentsTemplatesSlice.actions;
