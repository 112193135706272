/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';

import MasonryLayout from 'react-responsive-masonry';
import { getImageSize } from 'polotno/utils/image';
import { RiDeleteBinLine } from 'react-icons/ri';
import { CircularProgress, IconButton } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useMasonryStyles } from './styles';
import { MasonryProps } from './types';
// eslint-disable-next-line import/order
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// eslint-disable-next-line import/order
import { unstable_registerNextDomDrop } from 'polotno/config';

export const Masonry = ({ images, store, onDeleteMedia }: MasonryProps) => {
  const classes = useMasonryStyles();

  const [loadingDelete, setLoadingDelete] = useState({ id: '', loading: false });

  // Callbacks
  const onDragStart = async (url: string) => {
    const { width, height } = await getImageSize(url);
    unstable_registerNextDomDrop((pos, element) => {
      if (element && element.type === 'image') {
        element.set({ src: url });
        return;
      }
      store.activePage?.addElement({
        type: 'image',
        src: url,
        x: pos.x,
        y: pos.y,
        width,
        height,
      });
    });
  };

  const onHandleDelete = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
  ) => {
    event.stopPropagation();
    try {
      setLoadingDelete({ id, loading: true });
      await onDeleteMedia(id);
    } catch (error) {
      toast.error('Não foi possível excluir a mídia');
    } finally {
      setLoadingDelete({ id, loading: false });
    }
  };

  return (
    <MasonryLayout columnsCount={2} gutter="10px">
      {images.map(({ url, _id }) => (
        <div
          className={classes.imageContainer}
          onClick={(e) => e.stopPropagation()}
        >
          <img
            draggable
            src={url}
            className={classes.image}
            onDragStart={() => onDragStart(url)}
            onDragEnd={() => {
              unstable_registerNextDomDrop(null);
            }}
          />
          <IconButton
            id="trash"
            type="button"
            color="inherit"
            size="small"
            className={classes.deleteButton}
            onClick={event => onHandleDelete(event, _id)}
          >
            {
              (loadingDelete.id === _id && loadingDelete.loading) ? <CircularProgress size={14} /> : <RiDeleteBinLine color="red" />
            }
          </IconButton>
        </div>
      ))}
    </MasonryLayout>
  );
};
