import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Colors from 'styles/colors';
import { commonConstants, FOOTER_HEIGHT, PREVIEW_HEIGHT } from 'styles/common';

export const useSidebarStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: 320,
      overflowY: 'scroll',
      padding: theme.spacing(3.5),
      backgroundColor: Colors.GRAY_100,
      borderLeft: `1px solid ${Colors.LIGHT_GRAY}`,
      '&::-webkit-scrollbar': {
        width: '0.4em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: `inset 0 0 6px ${Colors.WHITE}`,
        webkitBoxShadow: `inset 0 0 6px ${Colors.WHITE}`,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        borderRadius: 2,
        outline: `1px solid ${Colors.WHITE}`,
      },
    },
    headerDocuments: {
      fontSize: commonConstants.font.size.xxl,
      fontFamily: commonConstants.font.family.title,
      fontWeight: commonConstants.font.weight.semiBold,
    },
    headerDocumentsAction: {
      fontSize: commonConstants.font.size.md,
      fontWeight: commonConstants.font.weight.regular,
    },
    buttonText: {
      fontSize: commonConstants.font.size.lg,
      whiteSpace: 'nowrap',
      fontWeight: commonConstants.font.weight.bold,
      paddingTop: theme.spacing(1),
    },
    buttonBase: {
      marginTop: theme.spacing(2),
      borderRadius: commonConstants.borders.radius.md,
      width: '100%',
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: theme.spacing(2),
      width: '100%',
      borderRadius: commonConstants.borders.radius.md,
    },
    containerTemplate: {
      height: '50vh',
      width: '100%',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    previewContainer: {
      height: PREVIEW_HEIGHT,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    templateFooter: {
      backgroundColor: Colors.LIGHT_BLUE,
      width: '100%',
      height: FOOTER_HEIGHT,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: `0 0 ${commonConstants.borders.radius.md}px ${commonConstants.borders.radius.md}px`,
    },
    templateName: {
      paddingLeft: 10,
      fontSize: commonConstants.font.size.lg,
      color: Colors.WHITE,
    },
}));
