import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

export const useSearchHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: Colors.LIGHT_GRAY,
      height: 43,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      position: 'relative',
    },
    contentSearch: {
      display: 'flex',
      padding: theme.spacing(0, 2),
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
    },
    input: {
      color: Colors.GRAY_50,
      paddingLeft: 25,
      width: '100%',
      fontSize: commonConstants.font.size.lg,
    },
  }),
);
