import React from 'react';

import { Box } from '@material-ui/core';
import { RiCheckboxCircleLine, RiUserLine } from 'react-icons/ri';
import Skeleton from 'react-loading-skeleton';
import Colors from 'styles/colors';

import {
  Container,
  ValueBox,
  Description,
  ItemBox,
  ItemText,
  ItemsDivider,
  HandlePlanButton,
} from './styles';

export const PlanSkelleton = () => (
  <Container selected={false}>
    {/* <Title>{plan.name}</Title> */}
    <Skeleton width={150} height={25} />
    <ValueBox>
      <Skeleton width={100} height={25} />
      <Box marginLeft={1}>
        <Skeleton width={40} height={25} />
      </Box>
    </ValueBox>
    <Description>
      <Skeleton width={250} height={10} />
      <Skeleton width={100} height={10} />
    </Description>
    {
          [1, 2, 3, 4].map(benefit => (
            <ItemBox>
              <RiCheckboxCircleLine color={Colors.LIGHT_BLUE} size={20} />
              <ItemText>
                <Skeleton width={210} height={10} />
                <Skeleton width={40 * benefit} height={10} />
              </ItemText>
            </ItemBox>
            ))
        }
    <ItemBox>
      <RiUserLine size={20} />
      <ItemText>
        <Skeleton width={20} height={10} />
      </ItemText>
    </ItemBox>
    <ItemsDivider />
    <HandlePlanButton disabled color="primary" variant="contained">Quero esse</HandlePlanButton>
  </Container>
);
