import clientApi from '../../../client';
import { PlansResponse, Safe2PayPlan, SubscriptionResponse, SubscriptionRequest, Plan } from './types';

export const createPlan = async (plan: Partial<Plan>): Promise<void> => {
   await clientApi.post('/plans', plan);
};

export const updatePlan = async (plan: Partial<Plan>): Promise<void> => {
   await clientApi.patch(`/plans/${plan._id}`, plan);
};

export const deletePlan = async (id: string): Promise<void> => {
   await clientApi.delete(`/plans/${id}`);
};

export const getPlans = async (): Promise<Array<Plan>> => {
  const { data } = await clientApi.get<Array<Plan>>('/plans');

  return data;
};

export const getSafe2PayPlans = async (): Promise<Array<Safe2PayPlan>> => {
  const { data: { ResponseDetail } } = await clientApi.get<PlansResponse>('/safe2pay/plans');

  return ResponseDetail.Objects;
};

export const createSubscription = async (subscription: SubscriptionRequest): Promise<void> => {
 await clientApi.post<SubscriptionResponse>('/safe2pay/signatures', subscription);
};

export const deleteSubscription = async (userId: string): Promise<void> => {
 await clientApi.delete(`/safe2pay/signatures/${userId}`);
};
