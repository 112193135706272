import { makeStyles, MenuItem as MUIMenuItem, Theme } from '@material-ui/core';
import { createStyles, styled } from '@material-ui/styles';
import { commonConstants } from 'styles/common';

export const useMenuStyles = makeStyles(() =>
  createStyles({
    container: { top: '45px !important', borderRadius: commonConstants.borders.radius.md },
}));

export const MenuItem = styled(MUIMenuItem)<Theme>(({ theme }) => ({
  fontSize: commonConstants.font.size.lg,
  padding: theme.spacing(1, 2),
}));
