import { makeStyles, createStyles, Theme, Box, styled } from '@material-ui/core';
import Colors from 'styles/colors';
import { commonConstants, FOOTER_HEIGHT, PREVIEW_HEIGHT } from 'styles/common';

type StyledBox = { selected: boolean }

export const useGridItemStyles = makeStyles(() =>
  createStyles({
    container: {
      width: 182,
      height: 145,
      backgroundColor: Colors.LIGHT_GRAY,
      borderRadius: commonConstants.borders.radius.md,
    },
    footer: {
      backgroundColor: Colors.WHITE,
      width: '100%',
      height: FOOTER_HEIGHT,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    previewContainer: {
      height: PREVIEW_HEIGHT,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    templateFooter: {
      backgroundColor: Colors.LIGHT_BLUE,
      minWidth: '100%',
      height: FOOTER_HEIGHT,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: `0 0 ${commonConstants.borders.radius.md}px ${commonConstants.borders.radius.md}px`,
      '&:hover': {
        justifyContent: 'center',
      },
      '& .MuiButton-root': {
        display: 'none',
      },
      '&:hover .MuiTypography-root': {
        display: 'none',
      },
      '&:hover .MuiButton-root': {
        display: 'block',
      },
    },
    previewButton: {
        width: 'fit-content',
        padding: '3px 25px',
        borderRadius: commonConstants.borders.radius.xl,
        border: '1px solid #fff',
        fontSize: 14,
        color: '#fff',
      },
    templateName: {
      paddingLeft: 10,
      fontSize: commonConstants.font.size.lg,
      color: Colors.WHITE,
    },

}));

export const ContainerTemplate = styled(Box)<Theme, StyledBox>(({ selected, theme }) => ({
      height: 140,
      backgroundColor: Colors.LIGHT_GRAY,
      borderRadius: commonConstants.borders.radius.md,
      marginBottom: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      border: selected ? `1px solid ${Colors.LIGHT_BLUE}` : 'none',
      '&:hover': {
        cursor: 'pointer',
      },
}));
