import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useModalStyles } from 'styles/shared/Modal';
import { TitleProps } from './types';

// import {Container} from './styles'

export const Title = ({ title }: TitleProps) => {
  const classes = useModalStyles();

  return (
    <Box marginTop={3}>
      <Typography className={classes.title}>{title}</Typography>
    </Box>
  );
};
