import React, { useState } from 'react';

import { ModalCancelPlan } from 'components/ModalCancelPlan';
import { ModalPayment } from 'components/ModalPayment';
import { useAuthentication } from 'hooks/useAuthentication';
import { useTabs } from 'hooks/useTabs';
import { RiCheckboxCircleLine, RiFile2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import type { Plan as IPlan } from 'services/api-leeg/modules/plans/types';
import Colors from 'styles/colors';

import {
  Container,
  Title,
  ValueBox,
  Value,
  MonthText,
  Description,
  ItemBox,
  ItemText,
  ItemsDivider,
  HandlePlanButton,
} from './styles';
import { ButtonActionSwitcherProps, PlanProps } from './types';

const ButtonActionSwitcher = (
  { selected, actualPlan, planName, openCancel, openPayment }: ButtonActionSwitcherProps,
  ) => {
  if (actualPlan === planName) {
    return <HandlePlanButton disabled color="primary" variant="contained">Plano atual</HandlePlanButton>;
  }

  if (planName === 'Leeg Grátis' && !actualPlan) {
    return (
      <HandlePlanButton disabled color="primary" variant="contained">Plano atual</HandlePlanButton>
    );
  }

  if (planName === 'Leeg Grátis' && actualPlan) {
    return (
      <HandlePlanButton disabled={!selected} onClick={openCancel} color="primary" variant="contained">Quero esse</HandlePlanButton>
    );
  }
  return (
    <HandlePlanButton disabled={!selected} onClick={openPayment} color="primary" variant="contained">Quero esse</HandlePlanButton>
  );
};

export const Plan = ({ plan, selected, handleSelectPlan, actualPlan }: PlanProps) => {
  const { user } = useAuthentication();
  const tabs = useTabs();

  // States
  const [modalPaymentIsOpen, setModalPaymentIsOpen] = useState(false);
  const [modalCancelPlanIsOpen, setModalCancelPlanIsOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({} as IPlan);

  // Callbacks
  const handleOpenModalPayment = () => {
    if (
      !(
      !!user?.address
      &&
      !!user?.address.cityName
      &&
      !!user?.address.district
      &&
      !!user?.address.number
      &&
      !!user?.address.street
      &&
      !!user?.address.zipCode
      &&
      !!user?.address.stateInitials
      )
     ) {
       toast.info('Você precisa completar seu perfil. Clique aqui para atualizar.', { onClick: () => tabs.goTo('profile', 'Perfil') });
       return;
     }

    setModalPaymentIsOpen(true);
    setSelectedPlan(plan);
  };

  const handleOpenModalCancel = () => {
    setModalCancelPlanIsOpen(true);
    setSelectedPlan(plan);
  };

  return (
    <>
      <Container selected={selected} onClick={() => handleSelectPlan(plan.name)}>
        <Title>{plan.name}</Title>
        <ValueBox>
          <Value>
            R$
            {' '}
            {plan.amount.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
          </Value>
          <MonthText>/mês</MonthText>
        </ValueBox>
        <Description>
          A conta
          {' '}
          <b>{plan.name}</b>
          {' '}
          garante a você acesso a:
        </Description>
        {
          plan.benefits.map(benefit => (
            <ItemBox>
              <RiCheckboxCircleLine color={Colors.LIGHT_BLUE} size={20} />
              <ItemText>
                {benefit}
              </ItemText>
            </ItemBox>
            ))
        }
        {/* <ItemBox>
          <RiUserLine size={20} />
          <ItemText>
            {plan.usersLimit}
            {' '}
            usuário
          </ItemText>
        </ItemBox> */}
        <ItemBox>
          <RiFile2Line size={20} />
          <ItemText>
            {plan.documentQuantityLimit}
            {' '}
            documentos
          </ItemText>
        </ItemBox>
        <ItemsDivider />
        <ButtonActionSwitcher
          actualPlan={actualPlan}
          selected={selected}
          planName={plan.name}
          openPayment={handleOpenModalPayment}
          openCancel={handleOpenModalCancel}
        />
      </Container>
      <ModalPayment
        open={modalPaymentIsOpen}
        onClose={() => setModalPaymentIsOpen(false)}
        selectedPlan={selectedPlan}
      />
      <ModalCancelPlan
        open={modalCancelPlanIsOpen}
        handleClose={() => setModalCancelPlanIsOpen(false)}
      />
    </>
);
};
