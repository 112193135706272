import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { useAppSelector } from 'hooks/store/useAppSelector';
import { useGridStyles } from './styles';
import GridItem from './components/GridItem';
import { GridContentProps } from './types';

export function GridContent({
  handleSelect,
  selectedItem,
  openPreview,
}: GridContentProps) {
  const classes = useGridStyles();
  const { templates } = useAppSelector(state => state.documentCategories);

  return (
    <Box className={classes.gridContainer}>
      <Box className={classes.section}>
        <Grid container xs={12} spacing={3}>
          {templates.map(template => (
            <GridItem
              onClick={() => handleSelect(template._id)}
              openPreview={openPreview}
              key={template._id}
              template={template}
              selected={selectedItem === template._id}
            />
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
