import React from 'react';

import { ReactComponent as Logo } from 'assets/logos/logo.svg';
import amico from 'assets/illustrations/amico.svg';

import { useHistory } from 'react-router';
import { ButtonLogo, Container, Illustration } from './styles';
import { InitialBackgroundWrapperProps } from './types';

export function InitialBackgroundWrapper({
  children,
}: InitialBackgroundWrapperProps) {
  // Hooks
  const history = useHistory();

  // Callbacks
  const handleGoToLogin = () => {
    history.push('/login');
  };

  return (
    <Container>
      <ButtonLogo
        disableTouchRipple
        onClick={handleGoToLogin}
      >
        <Logo />
      </ButtonLogo>
      {children}
      <Illustration src={amico} />
    </Container>
  );
}
