import { styled, Box } from '@material-ui/core';

export const Container = styled(Box)(() => ({
  height: '80%',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  marginTop: 20,
  '& .konvajs-content': {
    pointerEvents: 'none',
  },
}));
