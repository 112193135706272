import React from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Typography,
  IconButton,
  Button,
  Box,
  Grid,
} from '@material-ui/core';
import { useModalStyles } from 'styles/shared/Modal';
import { RiCloseLine } from 'react-icons/ri';
import clsx from 'clsx';
import { useAppSelector } from 'hooks/store/useAppSelector';
import { ModalFoldersProps } from './types';
import { FolderItem } from './FolderItem';

export const ModalFolders = ({
  open,
  handleClose,
  folders,
  handleSave,
}: ModalFoldersProps) => {
  // Hook styles
  const classes = useModalStyles();

  // Redux
  const swapFolderId = useAppSelector(state => state.swapFolderId);

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Paper className={clsx(classes.paper, classes.folders)}>
          <IconButton
            className={classes.closeButton}
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <RiCloseLine size={16} />
          </IconButton>
          <Typography className={classes.title}>
            Escolha a pasta que deseja salvar
          </Typography>
          <Grid container>
            {folders.map(folder => (
              <FolderItem key={folder._id} folder={folder} />
            ))}
          </Grid>
          <Box className={classes.actions}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={!swapFolderId}
              onClick={() => {
                handleSave();
                handleClose();
              }}
            >
              Salvar
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
