import { createAsyncThunk } from '@reduxjs/toolkit';
import * as documentsService from 'services/api-leeg/modules/documents';
import { toast } from 'react-toastify';
import { StoreState } from 'store';
import { adminDeletedDocumentsTemplatesActions } from '.';

// Async thunks
export const getAllDeletedTemplates = createAsyncThunk(
  'adminDeletedDocumentsTemplates/getAllDeletedTemplates',
  async (_, thunk) => {
    thunk.dispatch(adminDeletedDocumentsTemplatesActions.updateLoading(true));
    try {
      const templates = await documentsService.getAllDeletedTemplatesDocuments();

      thunk.dispatch(adminDeletedDocumentsTemplatesActions.updateDeletedTemplates(templates));
    } catch (error) {
      toast.error('Não foi possível carregar os documentos');
    } finally {
      thunk.dispatch(adminDeletedDocumentsTemplatesActions.updateLoading(false));
    }
  },
);

export const recoverTemplate = createAsyncThunk(
  'adminDeletedDocumentsTemplates/recoverTemplate',
  async (id: string, thunk) => {
    try {
      const {
        adminDocumentsTemplates: { data: templates },
      } = thunk.getState() as StoreState;

      await documentsService.recoverDocument(id);
      const newTemplates = templates.filter(
        previousTemplate => previousTemplate._id !== id,
      );

      thunk.dispatch(adminDeletedDocumentsTemplatesActions.updateDeletedTemplates(newTemplates));
      toast.success('Modelo recuperado com sucesso.');
    } catch (error) {
      toast.error('Não foi possível recuperar o modelo');
    }
  },
);
