import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { getPlans } from 'services/api-leeg/modules/plans';
import type { Plan } from 'services/api-leeg/modules/plans/types';
import { useAuthentication } from 'hooks/useAuthentication';
import { Header } from '../../../components/Header';
import {
  Container,
  Title,
  Description,
  SubDescription,
  TitleContainer,
  Content,
} from './styles';
import { Plan as PlanCard } from './components/Plan';
import { PlanSkelleton } from './components/Plan/Skelleton';

export function Plans() {
  // Context
  const { getUser, user } = useAuthentication();

  // States
  const [selectedPlan, setSelectedPlan] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState<Array<Plan>>([]);

  const handleSelectPlan = (name: string) => {
    setSelectedPlan(name);
  };

  const getAllPlans = async () => {
    setLoading(true);
    const plansResponse = await getPlans();
    await getUser();
    setPlans([
      {
        _id: '0',
        idPlanSafe2Pay: 0,
        name: 'Leeg Grátis',
        amount: 0,
        usersLimit: 1,
        documentQuantityLimit: 2,
        benefits: [
          'Baixe até 5 modelos de documentos em PDF',
          'Acesso a templates de documentos',
          'Acesso a fotos e elementos visuais gráficos (ícones, infográficos, fluxogramas e ilustrações)',
          '5GB de armazenamento em núvem',
        ],
        planFrequence: 'Mensal',
      },
      ...plansResponse,
    ]);
    setLoading(false);
  };

  useEffect(() => {
    getAllPlans();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedPlan(user?.signature?.Plan.Name as string);
  }, [user?.signature?.Plan.Name]);

  return (
    <>
      <Helmet>
        <title>Leeg | Planos</title>
      </Helmet>
      <Header />
      <Container>

        <TitleContainer>
          <Title>Planos</Title>
          <Description>Escolha o plano da leeg ideal para você</Description>
          <SubDescription>
            Planos de assinatura a partir de R$ 39,90 / mês
          </SubDescription>
        </TitleContainer>
        <Content>
          {loading ? (
            [1, 2, 3].map(plan => (
              <PlanSkelleton key={plan} />
            ))
              ) : (
                plans.map(plan => (
                  <PlanCard
                    plan={plan}
                    actualPlan={user?.signature?.Plan.Name as string}
                    selected={selectedPlan === plan.name}
                    handleSelectPlan={handleSelectPlan}
                  />
                ))
              )}
        </Content>

      </Container>
    </>
  );
}
