import { Box, Grid, IconButton } from '@material-ui/core';
import React from 'react';
import { RiDeleteBinLine, RiEdit2Line } from 'react-icons/ri';
import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';
import { Content, Row } from './styles';

export const CategoriesSkelleton = () => (
  <Content>
    {
    [2, 1, 3].map(category => (
      <Row key={category}>
        <Grid
          container
          direction="row"
          xs={12}
        >
          <Grid item xs={3} style={{ paddingLeft: 30 }}>
            <Skeleton width={category * 50} />
          </Grid>
          <Grid item xs={7} style={{ paddingLeft: 10 }}>
            <Skeleton width={category * 50} />
          </Grid>
          <Grid item xs={1}>
            <Box display="grid" gridAutoFlow="column" gridColumnGap="20px">
              <IconButton
                color="inherit"
                size="small"
              >
                <RiEdit2Line size={24} />
              </IconButton>
              <IconButton
                color="inherit"
                size="small"
                disabled
              >
                <RiDeleteBinLine size={24} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Row>
    ))
}
  </Content>
);
