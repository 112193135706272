import React, { useState } from 'react';
import { Box, Divider, IconButton } from '@material-ui/core';
import { useAppSelector } from 'hooks/store/useAppSelector';
import { RiDeleteBinLine, RiEdit2Line } from 'react-icons/ri';
import { DocumentCategory } from 'services/api-leeg/modules/documentCategories/types';
import { ModalCategory } from '../../ModalCategory';
import { CategoriesSkelleton } from './Skelleton';

import { Container, Header, Content, Row, HeaderText, RowText } from './styles';

export const Categories = () => {
  // States
  const [modalCreateCategoryIsOpen, setModalCreateCategoryIsOpen] = useState(false);
  const [, setModalDeleteIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<DocumentCategory>();

  const { data: categories, isLoading } = useAppSelector(state => state.adminDocumentCategories);

  const handleOpenCreateCategoryModal = (category: DocumentCategory) => {
    setSelectedCategory(category);
    setModalCreateCategoryIsOpen(true);
  };

  const handleCloseCreateCategoryModal = () => {
    setModalCreateCategoryIsOpen(false);
  };

  const handleOpenDeleteCategoryModal = (category: DocumentCategory) => {
    setSelectedCategory(category);
    setModalDeleteIsOpen(true);
  };

  return (
    <Container>
      <Header>
        <HeaderText>Nome</HeaderText>
        <HeaderText>Ações</HeaderText>
      </Header>
      <Divider />
      {isLoading && categories.length === 0 && <CategoriesSkelleton />}
      <Content>
        {
          categories?.map(category => (
            <Row key={category._id}>
              <RowText>{category.name}</RowText>
              <Box display="grid" gridAutoFlow="column" gridColumnGap="20px">
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => handleOpenCreateCategoryModal(category)}
                >
                  <RiEdit2Line size={24} />
                </IconButton>
                <IconButton
                  color="inherit"
                  size="small"
                  disabled
                  onClick={() => handleOpenDeleteCategoryModal(category)}
                >
                  <RiDeleteBinLine size={24} />
                </IconButton>
              </Box>
            </Row>
          ))
        }
      </Content>
      <ModalCategory
        open={modalCreateCategoryIsOpen}
        handleClose={handleCloseCreateCategoryModal}
        selectedCategory={selectedCategory}
        type="rename"
      />
    </Container>
);
};
