import Typography from '@material-ui/core/Typography';
import { Theme } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { styled } from '@material-ui/styles';
import Colors from 'styles/colors';
import { commonConstants } from 'styles/common';

export const Label = styled(Typography)<Theme>(() => ({
  fontSize: commonConstants.font.size.lg,
  fontWeight: commonConstants.font.weight.regular,
}));

export const Input = styled(OutlinedInput)<Theme>(() => ({
  backgroundColor: Colors.WHITE,
  height: 50,
  width: '100%',
  borderRadius: commonConstants.borders.radius.md,
  fontWeight: commonConstants.font.weight.bold,
  '& .MuiInputBase-input': {
    padding: '16.5px 14px !important',
  },
}));
