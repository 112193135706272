import { useAppDispatch } from 'hooks/store/useAppDispatch';
import { useAppSelector } from 'hooks/store/useAppSelector';
import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { getAdminDocumentCategories as thunkGetAdminDocumentCategories } from 'store/adminSlices/adminDocumentCategories/sideEffects';

import { Header } from '../../../components/Header';

import { Content } from './components/Content';
import { NavBar } from './components/NavBar';
import { SearchHeader } from './components/SearchHeader';
import { Sidebar } from './components/Sidebar';
import * as MaterialUIStyles from './styles';

export function Admin() {
  // Redux
  const dispatch = useAppDispatch();
  const adminActualContent = useAppSelector(state => state.adminActualContent);

  // Callbacks
  const getCategories = useCallback(async () => {
    await dispatch(thunkGetAdminDocumentCategories());
  }, [dispatch]);

  // Effects
  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <>
      <Helmet>
        <title>Leeg | Admin</title>
      </Helmet>
      <Header />
      <SearchHeader />
      <MaterialUIStyles.Content>
        <NavBar />
        <Content />
        {adminActualContent.id !== 'deleted-templates' && <Sidebar /> }
      </MaterialUIStyles.Content>
    </>
  );
}
