import { Theme, Box, ButtonBase } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import Colors from 'styles/colors';

export const Container = styled(Box)<Theme>(() => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
  background: Colors.BACKGROUND_BLUE_GRADIENT,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ButtonLogo = styled(ButtonBase)<Theme>(() => ({
  position: 'absolute',
  top: 20,
  left: 41,
  width: 100,
}));

export const Illustration = styled('img')<Theme>(() => ({
  position: 'absolute',
  width: 200,
  height: 150,
  bottom: 0,
  right: 0,
}));
