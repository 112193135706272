import { useAppDispatch } from 'hooks/store/useAppDispatch';
import { useAppSelector } from 'hooks/store/useAppSelector';
import { useHistory } from 'react-router-dom';
import { getDocument as thunkGetDocument } from 'store/slices/actualDocument/sideEffects';
import { tabsActions } from 'store/slices/tabs';
import { Tabs } from 'store/slices/tabs/types';

export const useTabs = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const { data: actualTabs } = useAppSelector(state => state.tabs);

    // Methods
    const goTo = (id: string, name: string) => {
      const storagedTabs = localStorage.getItem('leeg:tabs');

      if (storagedTabs) {
        const tabs: Array<Tabs> = JSON.parse(storagedTabs);
        const plansIsAlreadyOpen = tabs.find(tab => tab.id === id);
        if (!plansIsAlreadyOpen) {
          dispatch(tabsActions.updateTabs([{ id, name }, ...JSON.parse(storagedTabs)]));
          dispatch(tabsActions.updateActiveTab(id));
        } else {
          dispatch(tabsActions.updateActiveTab(id));
        }
      } else {
          dispatch(tabsActions.updateTabs([{ id, name }]));
          dispatch(tabsActions.updateActiveTab(id));
      }
      history.push(`/${id}`);
    };

    const openDocument = async (id: string, name: string) => {
     const tab = actualTabs.find(previousTabs => previousTabs.id === id);
      if (tab) {
        await dispatch(thunkGetDocument(id));
        history.push(`/editor/${id}`);
        dispatch(tabsActions.updateActiveTab(id));
      } else {
        dispatch(
          tabsActions.updateTabs([
            ...actualTabs,
            { id, name },
          ]),
        );
        dispatch(tabsActions.updateActiveTab(id));
      }
      await dispatch(thunkGetDocument(id));
      history.push(`/editor/${id}`);
    };

    return { goTo, openDocument };
};
