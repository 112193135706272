import React, { useState } from 'react';
import { Menu } from '@material-ui/core';

import { ModalDelete } from 'components/ModalDelete';
import { MenuItem } from 'styles/shared/Menu';
import { useAppDispatch } from 'hooks/store/useAppDispatch';
import {
  recoverTemplate as thunkRecoverTemplate,
 deleteTemplate as thunkDeleteTemplate, renameTemplate as thunkRenameTemplate,
} from 'store/adminSlices/adminDocumentsTemplates/sideEffects';
import { useAppSelector } from 'hooks/store/useAppSelector';
import { tabsActions } from 'store/slices/tabs';

import { useHistory } from 'react-router-dom';
import { ModalFieldNew } from 'components/ModalFieldNew';

import { Document } from 'services/api-leeg/modules/documents/types';
import { MenuFiltersProps } from './types';
import { useMenuDocumentStyles } from './styles';
import { ModalAddPlanToTemplate } from './ModalAddPlanToTemplate';

export function MenuDocument({
  anchor,
  handleClose,
  selectedItem,
  document,
}: MenuFiltersProps) {
  const classes = useMenuDocumentStyles();
  const history = useHistory();

  /**
   * Redux
   */
  const dispatch = useAppDispatch();
  const { data: tabs } = useAppSelector(state => state.tabs);

  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
  const [modalFieldIsOpen, setModalFieldIsOpen] = useState(false);
  const [modalPlanToTemplateIsOpen, setModalPlanToTemplateIsOpen] = useState(false);

  // Modals
  const handleOpenRenameDocument = () => {
    handleClose();
    setModalFieldIsOpen(true);
  };

  const handleCloseRenameDocument = () => {
    handleClose();
    setModalFieldIsOpen(false);
  };

  const handleOpenDeleteDocument = () => {
    handleClose();
    setModalDeleteIsOpen(true);
  };

  const handleCloseDeleteDocument = () => {
    handleClose();
    setModalDeleteIsOpen(false);
  };

  // const handleClosePlanToTemplateModal = () => setModalPlanToTemplateIsOpen(false)

  // Callbacks
  const handleRecoverDocument = async () => {
    await dispatch(thunkRecoverTemplate(selectedItem.id));
  };

  const handleVisualizeTemplate = async () => {
    // await dispatch(thunkGetDocument(selectedItem.id as string));

    const tab = tabs.find(previousTabs => previousTabs.id === selectedItem.id);

    if (tab) {
      dispatch(tabsActions.updateActiveTab(selectedItem.id));
    } else {
      dispatch(
        tabsActions.updateTabs([
          ...tabs,
          { id: selectedItem.id, name: selectedItem.name },
        ]),
      );
      dispatch(tabsActions.updateActiveTab(selectedItem.id));
    }

    history.push(`editor/${selectedItem.id}`);
  };

  const handleRenameTemplate = async (name: string) => {
    await dispatch(
      thunkRenameTemplate({ id: selectedItem?.id as string, name }),
    );
  };

  const handleDeleteTemplate = async () => {
    await dispatch(
      thunkDeleteTemplate(selectedItem.id),
    );
  };

  const anchorOrigin = { horizontal: 0, vertical: 0 };

  return (
    <>
      <Menu
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={handleClose}
        className={classes.container}
        elevation={1}
        anchorOrigin={anchorOrigin}
      >
        {document?.isDeleted ? (
          <MenuItem onClick={handleRecoverDocument}>Restaurar</MenuItem>
        ) : (
          <>
            <MenuItem onClick={handleOpenRenameDocument}>Renomear</MenuItem>
            <MenuItem
              onClick={() => setModalPlanToTemplateIsOpen(true)}
            >
              Restringir para planos
            </MenuItem>
            <MenuItem onClick={handleVisualizeTemplate}>Visualizar</MenuItem>
            <MenuItem onClick={handleOpenDeleteDocument}>Excluir</MenuItem>
          </>
        )}
      </Menu>

      <ModalDelete
        open={modalDeleteIsOpen}
        handleClose={handleCloseDeleteDocument}
        selectedItem={selectedItem}
        type="template"
        handleOk={handleDeleteTemplate}
      />

      <ModalAddPlanToTemplate
        open={modalPlanToTemplateIsOpen}
        onClose={() => setModalPlanToTemplateIsOpen(false)}
        selectedTemplate={document as Document}
      />

      <ModalFieldNew
        title="Renomear modelo"
        label="Nome do modelo"
        open={modalFieldIsOpen}
        selectedItem={selectedItem}
        okButtonText="Salvar"
        onOk={handleRenameTemplate}
        onClose={handleCloseRenameDocument}
      />
    </>
  );
}
