/* eslint-disable max-len */
import { PaginationMongo } from 'types/shared';
import clientApi from '../../../client';
import {
  CreateMedia, UserMedias,
} from './types';

export const getUserMedia = async ({ skip, limit }: PaginationMongo) : Promise<UserMedias> => {
  const { data: { results, count } } = await clientApi.get<UserMedias>(
    `/media?filter[isTemplate]=false&limit=${limit}&skip=${skip}&fields[url]=1`,
  );

  return { results, count };
};

export const createMedia = async ({
  owner,
  label,
  image,
}: CreateMedia): Promise<any> => {
  const formData = new FormData();

  formData.append('type', 'image');
  formData.append('isTemplate', 'false');
  formData.append('owner', owner);
  formData.append('label', label);
  formData.append('image', image);

  const { data: media } = await clientApi.post<any>('/media', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return media;
};

export const createMediaPolotno = async (formData: any): Promise<any> => {
  const { data: media } = await clientApi.post<Array<any>>('/media', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return media;
};

export const deleteMedia = async (id: string): Promise<any> => {
  await clientApi.delete<Array<any>>(`/media/${id}`);
};
