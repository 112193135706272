import React, { useState } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Typography,
  Box,
  Button,
  IconButton,
  OutlinedInput,
  CircularProgress,
} from '@material-ui/core';
import { RiCloseLine } from 'react-icons/ri';
import { useModalStyles } from 'styles/shared/Modal';
import { useAppDispatch } from 'hooks/store/useAppDispatch';
import { renameDocument as thunkRenameDocument } from 'store/slices/documents/sideEffects';
import {
  createFolder as thunkCreateFolder,
  renameFolder as thunkRenameFolder,
} from 'store/slices/folders/sideEffects';
import { useAuthentication } from 'hooks/useAuthentication';
import { ModalFieldProps } from './types';

export function ModalField({
  type,
  open,
  handleClose,
  selectedItem,
}: ModalFieldProps) {
  // Style hooks
  const classes = useModalStyles();

  // Context hooks
  const { user } = useAuthentication();

  // Redux
  const dispatch = useAppDispatch();

  // States
  const [name, setName] = useState(selectedItem?.name ?? '');
  const [loading, setLoading] = useState(false);

  // Callbacks
  const handleCreateFolder = async () => {
    if (!name) {
      handleClose();
      return;
    }
    setLoading(true);
    await dispatch(
      thunkCreateFolder({ name, parent: user?.rootFolder?._id as string }),
    );
    setLoading(false);
    handleClose();
  };

  const handleRenameDocument = async () => {
    if (selectedItem?.name === name || !name) {
      handleClose();
      return;
    }

    setLoading(true);
    await dispatch(
      thunkRenameDocument({ id: selectedItem?.id as string, name }),
    );
    setLoading(false);
    handleClose();
  };

  const handleRenameFolder = async () => {
    if (selectedItem?.name === name || !name) {
      handleClose();
      return;
    }
    setLoading(true);
    await dispatch(
      thunkRenameFolder({ name, _id: selectedItem?.id as string }),
    );
    setLoading(false);
    handleClose();
  };

  const handleOk = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loading) return;

    if (type === 'rename') {
      if (selectedItem?.isDocument) {
        await handleRenameDocument();
      } else {
        await handleRenameFolder();
      }
    } else if (type === 'create') {
      await handleCreateFolder();
    }
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <IconButton
            className={classes.closeButton}
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <RiCloseLine size={16} />
          </IconButton>
          <Typography className={classes.title}>
            {type === 'rename'
              ? `Renomear ${selectedItem?.isDocument ? ' documento' : ' pasta'}`
              : 'Nova pasta'}
          </Typography>

          <Typography className={classes.label}>
            {type === 'rename'
              ? `Nome ${selectedItem?.isDocument ? 'do documento' : 'da pasta'}`
              : 'Nome da pasta'}
          </Typography>
          <form onSubmit={handleOk}>
            <OutlinedInput
              autoFocus
              defaultValue={selectedItem?.name ? selectedItem?.name : ''}
              className={classes.input}
              id="name"
              type="text"
              placeholder="Sem nome"
              onChange={({ target }) => setName(target.value)}
            />

            <Box className={classes.actions}>
              <Button className={classes.buttons} onClick={handleClose}>
                Cancelar
              </Button>
              <Button
                className={classes.buttons}
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {type === 'rename' ? 'Salvar' : 'Criar'}
                {loading && (
                  <CircularProgress
                    size={14}
                    className={classes.loadingProgress}
                  />
                )}
              </Button>
            </Box>
          </form>
        </Paper>
      </Fade>
    </Modal>
  );
}
